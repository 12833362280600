import { Divider } from "@mui/material";

import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkEmptyState from "../../WishlinkComponents/WishlinkEmptyState";
import OrderLedgerItem from "./OrderLedgerItem";
import Spinner from "../../WishlinkComponents/Spinner";
import {
  useFetchGetDateWiseOrderSummary,
  useFetchGetOrderHistoryForDate,
} from "../../apiLayer/OrderHistory";
import { useFetchCreatorData } from "../../apiLayer/Home";
import { useEffect, useState } from "react";
import { triggerEvent } from "../../utils/honasa-events";

const OrderLedger = () => {
  const endDate = new Date().toISOString().split("T")[0];
  const startDate = "2022-01-01";

  const { data: orderSummaryData, isLoading: orderSummaryLoading } =
    useFetchGetDateWiseOrderSummary({
      startDate,
      endDate,
      page: 1,
      enabled: true,
    });
  const { order_summary_list } = orderSummaryData?.data || {};
  const { data: creatorData } = useFetchCreatorData({ enabled: true });

  const [expanded, setExpanded] = useState(0);
  const [entryPage, setEntryPage] = useState(1);
  const [currentSaleDate, setCurrentSaleDate] = useState(
    order_summary_list?.[0]?.sale_date,
  );

  const handleChange = (id, order) => (_, isExpanded) => {
    setExpanded(isExpanded ? id : null);
    triggerEvent({
      eventName: "accordion_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Rewards",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: isExpanded
          ? "order_history_accordion_open"
          : "order_history_accordion_close",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    if (isExpanded) {
      setEntryPage(1);
      setCurrentSaleDate(order.sale_date);
    }
  };

  const onChangePageNo = (e, page, order, successfulOrders, returnedOrders) => {
    setEntryPage(page);
    getOrderHistoryForDate();
  };

  const {
    data: orderHistoryForDate,
    isLoading: orderHistoryForDateLoading,
    refetch: getOrderHistoryForDate,
  } = useFetchGetOrderHistoryForDate({
    fetchDate: currentSaleDate,
    onlyShowReturnedOrders: false,
    page: entryPage,
    enabled: false,
  });

  useEffect(() => {
    if (order_summary_list?.[0]?.sale_date) {
      setCurrentSaleDate(order_summary_list?.[0]?.sale_date);
    }
  }, [order_summary_list]);

  useEffect(() => {
    getOrderHistoryForDate();
  }, [currentSaleDate, entryPage]);

  return (
    <>
      {!order_summary_list?.length && !orderSummaryLoading && (
        <div className="pt-5 mt-5 pb-5 mb-5">
          <WishlinkEmptyState
            headingText="No order history to show"
            emptyStateExists={false}
            graphicUrl={IMAGE_PREFIX + `order-history-empty-state-graphic.svg`}
          />
        </div>
      )}
      {order_summary_list?.map((order, idx) => {
        return (
          <div key={idx}>
            <OrderLedgerItem
              order={order}
              idx={idx}
              showReturnedOrders={false}
              handleChange={handleChange}
              expanded={expanded}
              entryPage={entryPage}
              orderHistoryForDate={orderHistoryForDate}
              orderHistoryForDateLoading={orderHistoryForDateLoading}
              onChangePageNo={onChangePageNo}
            />
            <div className="mt-2 mb-2">
              <Divider />
            </div>
          </div>
        );
      })}
      {orderSummaryLoading && <Spinner />}
    </>
  );
};

export default OrderLedger;
