export const triggerEvent = (eventDetails) => {
  window.pureOriginNativeBridge?.nativeCallback({
    action: "recordAnalyticsEvent",
    payload: eventDetails,
  });
};

export const triggerLoginEvent = (eventDetails) => {
  window.pureOriginNativeBridge?.nativeCallback({
    action: "setUserAttribute",
    payload: eventDetails,
  });
};

export const triggerShareEvent = (eventDetails) => {
  window.pureOriginNativeBridge?.nativeCallback({
    action: "share",
    payload: eventDetails,
  });
};
