import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import PhoneNumberInput from "./PhoneNumberInput";

const PhoneInputComponent = (props) => {
  const {
    handleSendOtp,
    phoneNumber,
    countryCode,
    sendingOtp,
    setPhoneNumber,
    setCountryCode,
    setSendingOtp,
    setDrawerStage,
  } = props;

  return (
    <div
      className="text-left"
      style={{
        background: "#fff",
      }}
    >
      <div className="p-4">
        <img src={`${IMAGE_PREFIX}wishlink-orange.svg`} alt="" />
        <WishlinkTypography
          fontSize="1.375rem"
          fontWeight={600}
          color={"#000000"}
          className="pt-5"
        >
          Hello, welcome to your
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="1.375rem"
          fontWeight={600}
          color={"#000000"}
        >
          Wishlink x Pure Origin Reseller account
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.875rem"
          fontWeight={400}
          color={"#000000"}
          className="pt-3"
        >
          Please enter your mobile number to continue
        </WishlinkTypography>
      </div>
      <PhoneNumberInput
        sendOtp={handleSendOtp}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        sendingOtp={sendingOtp}
        setPhoneNumber={setPhoneNumber}
        setCountryCode={setCountryCode}
        setSendingOtp={setSendingOtp}
        setDrawerStage={setDrawerStage}
      />
    </div>
  );
};

export default PhoneInputComponent;
