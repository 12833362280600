import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { PAGE_URLS } from "./utils/constants";
import { useEffect } from "react";

const RenderRoutes = (props) => {
  const { routes } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const referee = searchParams?.get("ref");
    if (referee) {
      localStorage.setItem("referee", referee);
      searchParams?.delete("ref");
      setSearchParams(searchParams);
    }
    const route = routes.find(
      (route) =>
        `/${route.path.split("/")[1]}` ===
        `/${location.pathname.split("/")[1]}`,
    );
    if (!route) return;
    if (route.path === "/") {
      if (localStorage.firebaseToken) navigate(PAGE_URLS.HOME);
      else navigate(PAGE_URLS.LOGIN);
      return;
    }
    if (route?.needFirebaseAuthentication && !localStorage.firebaseToken) {
      navigate(PAGE_URLS.WELCOME);
    } else if (location.pathname === PAGE_URLS.WELCOME) {
      if (localStorage.firebaseToken) navigate(PAGE_URLS.HOME);
    }
  }, [location]);

  return routes ? (
    <>
      <Routes>
        {routes.map((route, idx) => {
          return (
            <Route key={idx} path={route.path} element={route.component} />
          );
        })}
      </Routes>
    </>
  ) : null;
};

export default RenderRoutes;
