import AnalyticsCard from "./AnalyticsCard";
import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { useFetchResellerSalesData } from "../../apiLayer/Analytics";

const AnalyticsCardContainer = (props) => {
  const { showRedirectIcon } = props;
  const today = new Date();
  const formattedEndDate = today.toLocaleDateString("en-CA");
  const { data: salesData, isLoading: isSalesDataLoading } =
    useFetchResellerSalesData({
      startDate: "2024-02-01",
      endDate: formattedEndDate,
      enabled: true,
    });

  const analyticsCardIconsAndThemes = [
    {
      icon: IMAGE_PREFIX + "totalearnings.png",
      title: "Net Commissions",
      textColor: "#333333",
      value: salesData?.data?.total_commissions,
      shippedValue: salesData?.data?.commissions_gross,
      returnedValue: salesData?.data?.commissions_returns,
      isNegative: salesData?.data?.total_commissions_are_negative || false,
      isLoading: isSalesDataLoading,
      showRupee: true,
      redirectIcon: null,
      redirectUrl: null,
      showRedirectIcon: showRedirectIcon,
    },
    {
      icon: IMAGE_PREFIX + "total-sale.png",
      title: "Net Sales",
      textColor: "#333333",
      value: salesData?.data?.total_sales,
      shippedValue: salesData?.data?.sale_gross,
      returnedValue: salesData?.data?.sale_returns,
      isNegative: salesData?.data?.total_sales_are_negative || false,
      isLoading: isSalesDataLoading,
      showRupee: true,
      redirectIcon: null,
    },
  ];
  return (
    <div className="p-4">
      <div className="flex align-center mb-4 right-transition">
        <img
          src={`${IMAGE_PREFIX}analytics.svg`}
          className="mr-2"
          alt="analytics-icon"
        />
        <WishlinkTypography
          fontWeight={600}
          fontSize="1rem"
          lineHeight="1.5rem"
          color={"#333333"}
          textAlign={"left"}
        >
          Sales & Commissions
        </WishlinkTypography>
      </div>
      <div
        className="flex overflow-x-auto w-100 white-nowrap p-1"
        style={{ gap: "1rem" }}
      >
        {analyticsCardIconsAndThemes?.map((card, idx) => {
          return (
            <div
              key={idx}
              style={{ flex: 1 }}
              className={idx % 2 === 0 ? `right-transition` : `left-transition`}
            >
              <AnalyticsCard
                titleIcon={card.icon}
                redirectIcon={card.redirectIcon}
                title={card.title}
                analyticsFigure={card.value}
                analyticsShippedFigure={card.shippedValue}
                analyticsReturnedFigure={card.returnedValue}
                showRupeePrefix={card.showRupee}
                analyticsFigureFontColor={card.textColor}
                redirectUrl={card.redirectUrl}
                showRedirectIcon={card.showRedirectIcon}
                isLoading={card.isLoading}
                isNegative={card.isNegative}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnalyticsCardContainer;
