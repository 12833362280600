import { useNavigate } from "react-router-dom";

import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX } from "../../utils/constants";

const AboutDrawers = (props) => {
  const navigate = useNavigate();
  const { profileDrawer, coverDrawer, onProfilePicChoose, onCoverPicChoose } =
    props;

  return (
    <>
      <WishlinkDrawer
        drawerOpen={profileDrawer}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
        className="p-4"
      >
        <div className="p-4">
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={"#141414"}
            className="text-center pt-4"
          >
            Profile Photo
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.875rem"
            fontWeight={400}
            lineHeight="1.312rem"
            color={"#666666"}
            className="text-center pl-4 pr-4"
          >
            A photo helps your audience recognise you instantly. Add a profile
            photo and slay!
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            lineHeight="1.312rem"
            color={"#666666"}
            className="text-center pl-4 pr-4"
          >
            (Recommended size: 512 x 512 px)
          </WishlinkTypography>
          <div
            className="flex justify-start align-center p-5"
            onClick={() => onProfilePicChoose()}
          >
            <img src={`${IMAGE_PREFIX}gallery.svg`} alt="" />
            <WishlinkTypography
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.312rem"
              color={"#666666"}
              className="pl-4"
            >
              Choose from gallery
            </WishlinkTypography>
          </div>
        </div>
      </WishlinkDrawer>
      <WishlinkDrawer
        drawerOpen={coverDrawer}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
        className="p-4"
      >
        <div className="p-4">
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={"#141414"}
            className="text-center pt-4"
          >
            Cover Photo
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.875rem"
            fontWeight={400}
            lineHeight="1.312rem"
            color={"#666666"}
            className="text-center pl-4 pr-4"
          >
            Truly personalize your Wishlink Shop by adding a cover photo and
            stand out
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            lineHeight="1.312rem"
            color={"#666666"}
            className="text-center pl-4 pr-4"
          >
            (Recommended size: 1200 x 400 px)
          </WishlinkTypography>
          <div
            className="flex justify-start align-center p-5"
            onClick={() => onCoverPicChoose()}
          >
            <img src={`${IMAGE_PREFIX}gallery.svg`} alt="" />
            <WishlinkTypography
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.312rem"
              color={"#666666"}
              className="pl-4"
            >
              Choose from gallery
            </WishlinkTypography>
          </div>
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default AboutDrawers;
