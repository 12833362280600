import { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";

const WishlinkLinearProgress = (props) => {
  const orangeGradient = `linear-gradient(90.31deg, #E38125 0.25%, rgba(227, 129, 37, 0.6) 113.83%)`;
  const { percentComplete, style, barColor, filledBarColor } = props;
  const [percentValue, setPercentValue] = useState(0);
  const [barColorValue, setBarColorValue] = useState("");

  useEffect(() => {
    if (percentComplete > 100) {
      setPercentValue(10000 / percentComplete);
      setBarColorValue(orangeGradient);
    } else {
      setPercentValue(percentComplete);
      setBarColorValue(barColor);
    }
  }, [percentComplete]);

  return (
    <>
      <LinearProgress
        variant="determinate"
        sx={{
          background: barColorValue,
          ...style,
          "& .MuiLinearProgress-bar": {
            background: filledBarColor,
          },
        }}
        value={percentValue}
      />
    </>
  );
};

export default WishlinkLinearProgress;
