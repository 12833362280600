import { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import { LOGIN_DRAWER_STAGES } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkPhoneInput from "../../WishlinkComponents/WishlinkPhoneInput";
import WishlinkTextField from "../../WishlinkComponents/WishlinkTextField";

const PhoneNumberInput = (props) => {
  const {
    sendOtp,
    phoneNumber,
    countryCode,
    sendingOtp,
    setPhoneNumber,
    setCountryCode,
    setSendingOtp,
    setDrawerStage,
  } = props;
  const [phoneNumberValidated, setPhoneNumberValidated] = useState(false);
  const [proceedButtonDisabled, setProceedButtonDisabled] = useState(false);
  const [height, setHeight] = useState(window.innerHeight - 218.5);

  useEffect(() => {
    const handleResize = (e) => {
      setHeight(e.target.height - 200);
    };
    window.visualViewport.addEventListener("resize", handleResize);
    return () =>
      window.visualViewport.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      phoneNumber?.length &&
      countryCode?.length &&
      countryCode?.length + phoneNumber?.length > 8
    ) {
      if (isPossiblePhoneNumber(countryCode + phoneNumber)) {
        setPhoneNumberValidated(true);
      } else {
        setPhoneNumberValidated(false);
      }
    } else {
      setPhoneNumberValidated(false);
    }
  }, [phoneNumber, countryCode]);

  const handleEnterButtonClick = (event) => {
    if (event.key === "Enter" && !proceedButtonDisabled) {
      handleValidatePhoneNumber();
    }
  };

  useEffect(() => {
    setProceedButtonDisabled(!phoneNumberValidated);
  }, [phoneNumberValidated]);

  useEffect(() => {
    window.addEventListener("keypress", handleEnterButtonClick);
    return () => {
      window.removeEventListener("keypress", handleEnterButtonClick);
    };
  }, [proceedButtonDisabled, handleEnterButtonClick]);

  const handleValidatePhoneNumber = async () => {
    localStorage.setItem("phoneNumber", phoneNumber);
    setSendingOtp(true);
    setDrawerStage(LOGIN_DRAWER_STAGES.ENTER_OTP);
    await sendOtp(countryCode, phoneNumber, false);
    setSendingOtp(false);
  };

  return (
    <div
      className="flex flex-column justify-between"
      style={{ height: height + "px" }}
    >
      <div className="flex justify-left align-center border-grey border-radius pl-4 mt-3 ml-4 mr-4">
        <WishlinkPhoneInput
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          international={true}
          countryCallingCodeEditable={false}
          defaultCountry={"IN"}
          smartCaret={false}
          height="100%"
        />
        <WishlinkTextField
          type="number"
          style={{ border: "none", "& fieldset": { border: "none" } }}
          value={phoneNumber}
          fullWidth={true}
          placeholder="00000 00000"
          onInputValueChange={(val) => setPhoneNumber(val)}
          showIcon={false}
          helperText="Please enter a valid phone number"
          autoFocus={true}
        />
      </div>
      <div className="w-100" style={{ bottom: 0 }}>
        <WishlinkButton
          background={"#e91e63"}
          opacity={proceedButtonDisabled ? 0.4 : 1}
          disabled={proceedButtonDisabled}
          buttonClassName="w-100"
          className="m-4 mt-1"
          onClick={handleValidatePhoneNumber}
          height="2.5rem"
          style={{
            borderRadius: "1.25rem",
            ":hover": {
              bgcolor: "#e91e63",
            },
          }}
        >
          <div className="flex p-2">
            <WishlinkTypography
              fontWeight={600}
              fontSize="1rem"
              lineHeight="1.5rem"
              color={"#ffffff"}
              className="ml-3 mr-3"
            >
              {!sendingOtp ? "Get OTP" : "Sending OTP..."}
            </WishlinkTypography>
          </div>
        </WishlinkButton>
      </div>
    </div>
  );
};

export default PhoneNumberInput;
