import { useEffect } from "react";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import WishlinkSlider from "../../WishlinkComponents/WishlinkSlider";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { useFetchResellerSalesData } from "../../apiLayer/Analytics";
import CreatorEarning from "./CreatorEarning";
import { useFetchGetResellerCurrentPayout } from "../../apiLayer/Payouts";

const Statistics = (props) => {
  const { creatorData } = props;
  const today = new Date();
  const formattedEndDate = today.toLocaleDateString("en-CA");
  const {
    data: grossSalesData,
    isLoading: isGrossSalesDataLoading,
    refetch: refetchGrossSalesData,
  } = useFetchResellerSalesData({
    startDate: "2024-02-01",
    endDate: formattedEndDate,
    enabled: true,
  });

  const { data: earningsThisPayoutData, isLoading: earningsDataIsLoading } =
    useFetchGetResellerCurrentPayout({
      enabled: true,
    });

  const earnings = [
    {
      title: `Earnings this payout`,
      background: `linear-gradient(90.31deg, #E38125 0.25%, rgba(227, 129, 37, 0.6) 113.83%)`,
      amount: earningsThisPayoutData?.data?.total_payout_this_month_formatted,
      isLoading: earningsDataIsLoading,
      isNegative:
        earningsThisPayoutData?.data?.is_earnings_this_payout_negative,
    },
    {
      title: "Lifetime Commissions",
      background: "#1B1B1B",
      amount: grossSalesData?.data?.total_commissions,
      isLoading: isGrossSalesDataLoading,
      isNegative: grossSalesData?.data?.total_commissions_are_negative,
    },
  ];

  useEffect(() => {
    refetchGrossSalesData();
  }, []);

  return (
    <div className="pl-4 pr-4">
      <WishlinkTypography
        fontSize="1rem"
        fontWeight={600}
        color={"#333333"}
        lineHeight="1.5rem"
        className="text-left right-transition"
      >
        Welcome to Wishlink x Pure Origin
      </WishlinkTypography>
      <WishlinkTypography
        fontSize="0.75rem"
        fontWeight={400}
        color={"#666666"}
        lineHeight="1.5rem"
        className="text-left right-transition mb-2"
      >
        Here's an overview of your activity 🌟
      </WishlinkTypography>
      {/* <div className="flex text-left">
        <div
          style={{ width: "50%", border: "1px solid #ccc" }}
          className="p-2 mr-1 mb-1 border-radius"
        >
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            color={"#666666"}
            lineHeight="1.5rem"
          >
            Pending Sales
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={"#000"}
            lineHeight="1.5rem"
          >
            ₹ 1000
          </WishlinkTypography>
        </div>
        <div
          style={{ width: "50%", border: "1px solid #ccc" }}
          className="p-2 ml-1 mb-1 border-radius"
        >
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            color={"#666666"}
            lineHeight="1.5rem"
          >
            Confirmed Sales
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={"#000"}
            lineHeight="1.5rem"
          >
            ₹ 1000
          </WishlinkTypography>
        </div>
      </div>
      <div className="flex text-left mb-2">
        <div
          style={{ width: "50%", border: "1px solid #ccc" }}
          className="p-2 mr-1 mt-1 border-radius"
        >
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            color={"#666666"}
            lineHeight="1.5rem"
          >
            Cancelled Sales
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={"#000"}
            lineHeight="1.5rem"
          >
            ₹ 1000
          </WishlinkTypography>
        </div>
        <div
          style={{ width: "50%", border: "1px solid #ccc" }}
          className="p-2 ml-1 mt-1 border-radius"
        >
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            color={"#666666"}
            lineHeight="1.5rem"
          >
            Net Commissions
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={"#000"}
            lineHeight="1.5rem"
          >
            ₹ 1000
          </WishlinkTypography>
        </div>
      </div> */}
      <div
        className="flex justify-between mt-3 mb-2"
        style={{ height: "8rem" }}
      >
        <div
          className="flex flex-column justify-between align-stretch right-transition"
          style={{ width: "50%" }}
        >
          <div
            className="flex flex-column align-start justify-start border-radius p-4"
            style={{
              boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.12)",
              border: `0.06rem solid ${"#E5E5E5"}`,
              flex: 1,
            }}
          >
            <div className="flex align-center justify-start">
              <div>
                <WishlinkTypography
                  lineHeight="1.125rem"
                  fontSize="0.75rem"
                  fontWeight={500}
                  color={"#333333"}
                  className="text-left"
                >
                  {"Net Sales"}
                </WishlinkTypography>
              </div>
            </div>
            <WishlinkTypography
              fontSize="1rem"
              fontWeight={700}
              color={"#333333"}
              className="text-right"
            >
              <ValueSkeleton
                isLoading={isGrossSalesDataLoading}
                value={`₹${grossSalesData?.data?.all_time_sales}`}
                width="1rem"
              />
            </WishlinkTypography>
            <div className="flex align-center justify-start pt-3">
              <div>
                <WishlinkTypography
                  lineHeight="1.125rem"
                  fontSize="0.75rem"
                  fontWeight={500}
                  color={"#333333"}
                  className="text-left"
                >
                  {"Confirmed Sales"}
                </WishlinkTypography>
              </div>
            </div>
            <WishlinkTypography
              fontSize="1rem"
              fontWeight={700}
              color={"#333333"}
              className="text-right"
            >
              <ValueSkeleton
                isLoading={isGrossSalesDataLoading}
                value={`₹${grossSalesData?.data?.all_time_confirmed_sales}`}
                width="1rem"
              />
            </WishlinkTypography>
          </div>
        </div>
        <div
          className="ml-1 left-transition creator-earnings"
          style={{ width: "50%", height: "8rem" }}
        >
          <WishlinkSlider autoplay={true} showDots={false}>
            {earnings?.map((earning, idx) => {
              return (
                <div key={idx}>
                  <CreatorEarning
                    earning={earning}
                    idx={idx}
                    isLoading={earning.isLoading}
                    creatorData={creatorData}
                  />
                </div>
              );
            })}
          </WishlinkSlider>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
