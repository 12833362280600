import OtpDigit from "./OtpDigit";

const OtpInputLoop = (props) => {
  const {
    otpDigits,
    otpIndexWithFocus,
    handleOtpChange,
    handleOtpFocusChange,
    handleKeyboardNextClickOtpVerification,
    otpValidated,
    inputRef,
  } = props;
  return (
    <>
      <OtpDigit
        value={otpDigits[0]}
        active={otpIndexWithFocus === 0}
        inputRef={inputRef}
        inputProps={{
          min: "0",
          onInput: (e) => {
            handleOtpChange(e);
          },
        }}
        autoFocus={true}
      />
      <OtpDigit
        value={otpDigits[1]}
        active={otpIndexWithFocus === 1}
        onClick={() => {
          handleOtpFocusChange();
        }}
        onFocus={() => {
          handleKeyboardNextClickOtpVerification(otpValidated);
        }}
      />
      <OtpDigit
        value={otpDigits[2]}
        active={otpIndexWithFocus === 2}
        onClick={() => {
          handleOtpFocusChange();
        }}
      />
      <OtpDigit
        value={otpDigits[3]}
        active={otpIndexWithFocus === 3}
        onClick={() => {
          handleOtpFocusChange();
        }}
      />
      <OtpDigit
        value={otpDigits[4]}
        active={otpIndexWithFocus === 4}
        onClick={() => {
          handleOtpFocusChange();
        }}
      />
      <OtpDigit
        value={otpDigits[5]}
        active={otpIndexWithFocus === 5}
        onClick={() => {
          handleOtpFocusChange();
        }}
      />
    </>
  );
};

export default OtpInputLoop;
