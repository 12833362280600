import WishlinkModal from "../../WishlinkComponents/WishlinkModal";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

const BankDetailsSubmittedDrawer = (props) => {
  const { submittedDrawerState, setSubmittedDrawerState } = props;

  return (
    <>
      <WishlinkModal
        showModal={submittedDrawerState}
        setShowModal={setSubmittedDrawerState}
        showCloseIcon={false}
        width="40%"
      >
        {/* <WishlinkLottie
          play={true}
          loop={true}
          path={`${IMAGE_PREFIX}success-confetti.json`}
          style={{
            width: "50%",
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        /> */}
        <WishlinkTypography
          color={"#141414"}
          fontSize="1rem"
          fontWeight={500}
          className="text-center"
        >
          Thank you!
        </WishlinkTypography>
        <WishlinkTypography
          color={"#666666"}
          fontSize="0.75rem"
          fontWeight={400}
          className="text-center pt-2"
        >
          Your bank details has been saved successfully. Happy earnings 😊
        </WishlinkTypography>
      </WishlinkModal>
    </>
  );
};

export default BankDetailsSubmittedDrawer;
