import { useQuery } from "@tanstack/react-query";
import { makeAPICall } from "../../utils/common";
import { BASE_URL } from "../../utils/constants";

export const useFetchResellerSalesData = ({ startDate, endDate, enabled }) => {
  return useQuery({
    queryKey: [`resellerSalesData`, startDate, endDate],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getSalesData?startDate=${startDate}&endDate=${endDate}`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useFetchResellerAnalyticsChartData = ({
  startDatePrevious,
  endDatePrevious,
  endDateToAchieve,
  chartType,
  showNextPage,
  showPreviousPage,
  enabled,
}) => {
  return useQuery({
    queryKey: ["resellerChartData"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerAnalyticsChartData?startDatePrevious=${startDatePrevious}&endDatePrevious=${endDatePrevious}&endDateToAchieve=${endDateToAchieve}&chartType=${chartType}&showNextPage=${showNextPage}&showPreviousPage=${showPreviousPage}`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useFetchResellerProductsData = ({ enabled }) => {
  return useQuery({
    queryKey: ["resellerShopProductsData"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getShopProducts`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useFetchResellerEarningsThisPayoutData = ({ enabled }) => {
  return useQuery({
    queryKey: ["resellerEarnings"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerEarningsThisPayout`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useFetchResellerAnalyticsProductsData = ({
  startDate,
  endDate,
  page,
  enabled,
}) => {
  return useQuery({
    queryKey: ["resellerAnalyticsProductsData"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerAnalyticsProducts?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=100`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};
