import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkRadioGroup from "../../WishlinkComponents/WishlinkRadioGroup";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import { gender_values, PAGE_URLS } from "../../utils/constants";
import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import { useUpdateCreatorProfileData } from "../../apiLayer/Profile";
import { useFetchCreatorData } from "../../apiLayer/Home/index";
import { triggerEvent } from "../../utils/honasa-events";
import { showToast } from "../../features/Notification";
import { useDispatch } from "react-redux";

const AdditionalInfoGender = (props) => {
  const dispatch = useDispatch();
  const {
    data: creatorData,
    refetch: getCreatorData,
    isLoading: creatorDataIsLoading,
  } = useFetchCreatorData({ enabled: false });

  function refetchCreatorDataAndShowToast() {
    getCreatorData();
    dispatch(
      showToast({
        variant: "success",
        message: "Gender updated successfully",
      }),
    );
  }

  const { mutate } = useUpdateCreatorProfileData({
    onSuccess: refetchCreatorDataAndShowToast,
  });

  const { gender = "" } = creatorData?.data || {};
  const navigate = useNavigate();
  const location = useLocation();

  const [drawerState, setDrawerState] = useState(false);
  const [creatorGender, setCreatorGender] = useState(null);

  useEffect(() => {
    if (location.hash === "#gender") {
      setDrawerState(true);
    } else setDrawerState(false);
  }, [location]);

  useEffect(() => {
    if (gender) setCreatorGender(gender);
  }, [gender]);

  async function onClickRadioBtn(e) {
    setCreatorGender(e.target.value);
    triggerEvent({
      eventName: "gender_update_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Profile",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: `profile_gender_update_${e.target.value}`,
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  }

  const onClickUpdateGender = async () => {
    const body = {
      gender: creatorGender,
    };
    mutate(body);
    navigate(PAGE_URLS.PROFILE, { replace: true });
    triggerEvent({
      eventName: "gender_update_cancel_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Profile",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: `profile_gender_update_cancel`,
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  };

  return (
    <>
      <div
        className="flex align-center justify-between p-4"
        style={{ marginTop: "1rem" }}
        onClick={() => {
          navigate("#gender");
        }}
      >
        <div className="flex align-start">
          <img src={`${IMAGE_PREFIX}gender.svg`} className="pt-1" alt="" />
          <div>
            <WishlinkTypography
              color={"#666666"}
              fontSize="0.875rem"
              fontWeight={500}
              lineHeight="1.5rem"
              className="text-left ml-3"
            >
              Gender
            </WishlinkTypography>
            {!gender && !creatorDataIsLoading && (
              <WishlinkTypography
                color={"#c2c2c2"}
                fontSize="0.75rem"
                fontWeight={400}
                lineHeight="1.5rem"
                className="text-left ml-3"
              >
                Pick what best describes you
              </WishlinkTypography>
            )}
            {gender && (
              <div className="flex align-center">
                <WishlinkTypography
                  color={"#333333"}
                  fontSize="1rem"
                  lineHeight="1.5rem"
                  className="text-left ml-3"
                >
                  <ValueSkeleton
                    isLoading={creatorDataIsLoading}
                    value={
                      gender?.charAt(0) + gender?.substring(1)?.toLowerCase()
                    }
                  />
                </WishlinkTypography>
              </div>
            )}
          </div>
        </div>
        <img src={`${IMAGE_PREFIX}rightIconGrey.svg`} alt="" />
      </div>
      <WishlinkDrawer
        drawerOpen={drawerState}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
        className="p-4"
      >
        <div className="p-4">
          <WishlinkTypography
            color={"#141414"}
            fontSize="1rem"
            fontWeight={500}
            className="text-center pt-4 pb-2"
          >
            Gender
          </WishlinkTypography>
          <WishlinkTypography
            color={"#666666"}
            fontSize="0.875rem"
            fontWeight={400}
            className="text-center pb-4"
          >
            Pick what best describes you for a personalized experience
          </WishlinkTypography>
          <div className="mb-4">
            <WishlinkRadioGroup
              radioGroup={gender_values}
              value={creatorGender}
              row={false}
              size="small"
              labelPlacement="start"
              inverted={true}
              onClickRadioBtn={onClickRadioBtn}
            />
          </div>
          <WishlinkButton
            background={"#e91e63"}
            style={{
              ":hover": {
                bgcolor: "#e91e63",
              },
              ":disabled": {
                bgcolor: "#c2c2c2",
              },
            }}
            borderRadius="0.5rem"
            width="100%"
            height="3rem"
            className="pt-3 mt-3 mb-3"
            onClick={() => onClickUpdateGender()}
            disabled={creatorDataIsLoading}
          >
            <WishlinkTypography
              color={"#f5f5f5"}
              fontWeight={600}
              fontSize="1rem"
              className="p-1"
            >
              Save
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default AdditionalInfoGender;
