import { useNavigate } from "react-router-dom";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography/index";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton/index.js";
import ImageSkeleton from "../../WishlinkComponents/ImageSkeleton/index.jsx";
import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { triggerEvent } from "../../utils/honasa-events.js";

// const presets = [
//   { label: "This Month", value: DATE_PRESETS.THIS_MONTH },
//   { label: "Last Month", value: DATE_PRESETS.LAST_MONTH },
//   { label: "Last 3 Months", value: DATE_PRESETS.LAST_3_MONTHS },
//   { label: "All Time", value: DATE_PRESETS.ALL_TIME },
// ];

const Header = ({
  creatorDataIsLoading,
  creatorName,
  creatorImage,
  showDateRangePill = false,
  showBackButton = false,
  showHamburgerIcon = true,
  showBellIcon = true,
  showAddToShop = false,
  creatorData,
  icon,
  eventPage = "",
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="flex justify-start align-center p-3 sticky"
        id="header"
        style={{
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.12)",
          background: "#fff",
          zIndex: 200,
          top: window.cordova ? "-0.25rem" : 0,
        }}
      >
        {showHamburgerIcon && (
          <img
            src={`${IMAGE_PREFIX}hamburger.svg`}
            onClick={() => {
              navigate("#hamburger");
              triggerEvent({
                eventName: "hamburger_icon_click",
                eventAttributes: {
                  page_url: window.location.href,
                  page_name: "Home",
                  user_id: creatorData?.data?.id,
                  timestamp: new Date().toISOString(),
                  event_type: "click",
                  product_name: "",
                  product_price: "",
                  element: icon,
                  gender: creatorData?.data?.gender,
                  added_on: creatorData?.data?.added_on,
                },
              });
            }}
            alt=""
          />
        )}
        {showBackButton && (
          <img
            src={`${IMAGE_PREFIX}arrow-left.svg`}
            alt="back-arrow"
            className="pl-3"
            onClick={() => {
              navigate(-1);
              triggerEvent({
                eventName: "back_cta_click",
                eventAttributes: {
                  page_url: window.location.href,
                  page_name: eventPage,
                  user_id: creatorData?.data?.id,
                  timestamp: new Date().toISOString(),
                  event_type: "click",
                  product_name: "",
                  product_price: "",
                  element: "back_button_click",
                  gender: creatorData?.data?.gender,
                  added_on: creatorData?.data?.added_on,
                },
              });
            }}
          />
        )}
        <ImageSkeleton
          className="ml-3"
          isLoading={creatorDataIsLoading}
          imageUrl={creatorImage || `${IMAGE_PREFIX}wl-logo-outline-black.svg`}
          style={{ width: "2.5rem", height: "2.5rem", borderRadius: "1.5rem" }}
          alt={"creator-DP"}
        />
        <div className="text-left pl-2">
          <WishlinkTypography color={"#666666"} fontSize="0.8125rem">
            Hello
          </WishlinkTypography>
          <WishlinkTypography
            color={"#333333"}
            fontWeight={500}
            fontSize="0.875rem"
          >
            <ValueSkeleton
              isLoading={creatorDataIsLoading}
              value={creatorName || "Welcome to Wishlink"}
            />
          </WishlinkTypography>
        </div>
        {showAddToShop && (
          <div className="align-center flex justify-center ml-auto overlay-add-to-shop-highlight">
            <WishlinkButton
              background="#DF472C"
              style={{
                ":hover": {
                  bgcolor: "#DF472C",
                },
              }}
              borderRadius="1.375rem"
              width="100%"
              height="1.875rem"
              onClick={() => navigate("#create")}
            >
              <WishlinkTypography
                color={"#f5f5f5"}
                fontWeight={400}
                fontSize="0.75rem"
                className="p-1"
              >
                + Add to Shop
              </WishlinkTypography>
            </WishlinkButton>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
