import Stories from "stories-react";
import "stories-react/dist/index.css";

const WishlinkStories = (props) => {
  const {
    width = "100%",
    height = "100vh",
    stories = [],
    currentIndex = 0,
    onStoryChange,
    onAllStoriesEnd,
    onStoriesStart,
    classNames,
  } = props;

  return (
    <Stories
      width={width}
      height={height}
      stories={stories}
      currentIndex={currentIndex}
      onStoryChange={(idx) => onStoryChange(idx)}
      onAllStoriesEnd={onAllStoriesEnd}
      onStoriesStart={onStoriesStart}
      classNames={classNames}
    />
  );
};

export default WishlinkStories;
