import { useFetchCreatorData } from "../../apiLayer/Home";
import Header from "../../components/Header";
import { LifetimeEarnings } from "./Components/LifetimeEarnings";
import PendingCommissions from "./Components/PendingCommissions";
import ConfirmedCommissions from "./Components/ConfirmedCommissions";
import PayoutThisMonth from "./Components/PayoutThisMonth";
import { PastPayouts } from "./Components/PastPayouts/PastPayouts";
import { useFetchGetResellerCurrentPayout } from "../../apiLayer/Payouts";
import { useEffect, useState } from "react";
import { triggerEvent } from "../../utils/honasa-events";
import AnalyticsOverlay from "../../components/Analytics/AnalyticsOverlay";
import Spinner from "../../WishlinkComponents/Spinner";

export const Payouts = () => {
  const { data: creatorData, isLoading: creatorDataIsLoading } =
    useFetchCreatorData({ enabled: true });
  const { name = "", profile_pic_url = "" } = creatorData?.data || {};
  const { data: payoutsData, isLoading: payoutsDataLoading } =
    useFetchGetResellerCurrentPayout({
      enabled: true,
    });
  const {
    lifetime_payout_formatted,
    pending_commissions_formatted,
    confirmed_commissions_formatted,
    tds_formatted,
    rewards_formatted,
    referral_formatted,
    total_payout_this_month_formatted,
    confirmed_commissions_lifetime,
  } = payoutsData?.data || {};

  useEffect(() => {
    if (confirmed_commissions_lifetime < 500) {
      setShowOverlay(true);
    } else setShowOverlay(false);
  }, [confirmed_commissions_lifetime]);

  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "payouts_pl",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Payouts",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "page_load",
          product_name: "",
          product_price: "",
          element: "",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "lifetime_payouts_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Payouts",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "lifetime_payouts",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "pending_commissions_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Payouts",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "pending_commissions",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "confirmed_commissions_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Payouts",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "confirmed_commissions",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "past_payouts_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Payouts",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "past_payouts",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "confirmed_commissions_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Payouts",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "confirmed_commissions",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  if (payoutsDataLoading) {
    return (
      <div
        className="flex justify-center align-center"
        style={{ minHeight: window.innerHeight }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div style={{ minHeight: window.innerHeight }}>
      <Header
        creatorDataIsLoading={creatorDataIsLoading}
        creatorName={name}
        creatorImage={profile_pic_url}
        showBackButton={true}
        showHamburgerIcon={false}
        eventPage="Payouts"
      />
      <LifetimeEarnings
        lifetime_payout_formatted={lifetime_payout_formatted}
        payoutsDataLoading={payoutsDataLoading}
      />
      <PendingCommissions
        pending_commissions_formatted={pending_commissions_formatted}
        payoutsDataLoading={payoutsDataLoading}
      />
      <ConfirmedCommissions
        confirmed_commissions_formatted={confirmed_commissions_formatted}
        payoutsDataLoading={payoutsDataLoading}
      />
      <PayoutThisMonth
        tds_formatted={tds_formatted}
        rewards_formatted={rewards_formatted}
        referral_formatted={referral_formatted}
        confirmed_commissions_formatted={confirmed_commissions_formatted}
        total_payout_this_month_formatted={total_payout_this_month_formatted}
        payoutsDataLoading={payoutsDataLoading}
      />
      <PastPayouts />
      {showOverlay ? <AnalyticsOverlay /> : <></>}
    </div>
  );
};
export default Payouts;
