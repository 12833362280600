import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";

import "./index.css";

export const Spinner = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { size = "0.7rem", color = colors.red[200] } = props;
  return (
    <div className="bouncing-loader flex justify-center pt-3">
      <div style={{ background: color, width: size, height: size }}></div>
      <div style={{ background: color, width: size, height: size }}></div>
      <div style={{ background: color, width: size, height: size }}></div>
    </div>
  );
};
