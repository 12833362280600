import { Radio, RadioGroup, FormControlLabel } from "@mui/material";

import WishlinkTypography from "../WishlinkTypography";

const WishlinkRadioGroup = (props) => {
  const {
    radioGroup,
    row,
    value,
    size,
    disabled,
    onClickRadioBtn,
    labelPlacement = "end",
    inverted = false,
    color = "#E91E63",
    labelFontSize = "1rem",
  } = props;
  return (
    <>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        row={row}
        value={value}
        onChange={onClickRadioBtn}
        sx={{
          "& .MuiFormControlLabel-root": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginLeft: 0,
          },
        }}
      >
        {radioGroup?.map((radio, idx) => {
          return (
            <div
              className={`${inverted && `w-100`} flex align-center`}
              key={idx}
            >
              {radio.icon_url && (
                <img src={radio.icon_url} className="pr-2" alt="" />
              )}
              <FormControlLabel
                labelPlacement={labelPlacement}
                value={radio.value}
                control={
                  <Radio
                    size={size}
                    disabled={radio.disabled}
                    sx={{
                      color,
                      "&.Mui-checked": {
                        color,
                      },
                    }}
                  />
                }
                label={
                  <WishlinkTypography fontSize={labelFontSize}>
                    {radio.label}
                  </WishlinkTypography>
                }
                disabled={disabled}
              />
            </div>
          );
        })}
      </RadioGroup>
    </>
  );
};
export default WishlinkRadioGroup;
