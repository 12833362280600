import ValueSkeleton from "../../../../WishlinkComponents/ValueSkeleton";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX } from "../../../../utils/constants";

const PendingCommissions = (props) => {
  const { pending_commissions_formatted, payoutsDataLoading } = props;
  return (
    <div className="ml-4 mr-4 mb-4 bs-1">
      <div
        className="p-3"
        style={{
          background: "#F9EDDC",
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
        }}
      >
        <div className="flex justify-between">
          <WishlinkTypography fontSize="0.625rem" color={"#6E717D"}>
            Pending Commissions
          </WishlinkTypography>
          <img
            src={`${IMAGE_PREFIX}pending-commission.png`}
            style={{ width: "3.375rem" }}
            alt=""
          />
        </div>
        <div className="flex align-center justify-left">
          {!payoutsDataLoading && "₹"}
          <WishlinkTypography
            fontWeight={700}
            fontSize="1.25rem"
            color={"#000"}
            className="text-left"
          >
            <ValueSkeleton
              isLoading={payoutsDataLoading}
              value={pending_commissions_formatted}
            />
          </WishlinkTypography>
        </div>
      </div>
      <div
        className="p-2"
        style={{
          background: "#E0D5C6",
          borderBottomLeftRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
        }}
      >
        <WishlinkTypography
          fontSize="0.625rem"
          color={"#424242"}
          className="text-left"
        >
          Commissions on products that were ordered but not delivered yet
        </WishlinkTypography>
      </div>
    </div>
  );
};

export default PendingCommissions;
