import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTextField from "../../WishlinkComponents/WishlinkTextField";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import BankDetailsDrawers from "../../components/BankDetails/BankDetailsDrawers.jsx";
import {
  useFetchCreatorBankDetailsData,
  useUpdateSubmitCreatorBankDetailsData,
} from "../../apiLayer/Profile/index.js";
import { useFetchCreatorData } from "../../apiLayer/Home/index.js";
import { triggerEvent } from "../../utils/honasa-events.js";

const BankDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberCopy, setAccountNumberCopy] = useState("");
  const [name, setName] = useState("");
  const [IfscCode, setIfscCode] = useState("");
  const [panDetails, setPanDetails] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const [invalidAccountNumber, setInvalidAccountNumber] = useState(false);
  const [invalidAccountNumberCopy, setInvalidAccountNumberCopy] =
    useState(false);
  const [invalidIfscCode, setInvalidIfscCode] = useState(false);
  const [invalidPanDetails, setInvalidPanDetails] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [drawerState, setDrawerState] = useState(false);

  const { data: bankDetailsData } = useFetchCreatorBankDetailsData({
    enabled: true,
  });
  const bankDetails = bankDetailsData?.bank_details;
  const { data: creatorData } = useFetchCreatorData({ enabled: true });

  useEffect(() => {
    if (bankDetails && Object.keys(bankDetails)?.length > 0) {
      setAccountNumber(bankDetails.bank_account.account_number);
      setAccountNumberCopy(bankDetails.bank_account.account_number);
      setName(bankDetails.bank_account.name);
      setIfscCode(bankDetails.bank_account.ifsc);
      setPanDetails(bankDetails.pan);
    }
  }, [bankDetails]);

  useEffect(() => {
    if (location.hash === "#bank") {
      setDrawerState(true);
    } else {
      setDrawerState(false);
    }
  }, [location]);

  useEffect(() => {
    if (/^[0-9]+$/.test(accountNumber) || accountNumber?.length === 0)
      setInvalidAccountNumber(false);
    else setInvalidAccountNumber(true);
  }, [accountNumber]);

  useEffect(() => {
    if (
      (/^[0-9]+$/.test(accountNumberCopy) || accountNumberCopy?.length === 0) &&
      accountNumberCopy === accountNumber
    )
      setInvalidAccountNumberCopy(false);
    else setInvalidAccountNumberCopy(true);
  }, [accountNumberCopy]);

  useEffect(() => {
    if (IfscCode?.length === 11 || IfscCode?.length === 0)
      setInvalidIfscCode(false);
    else setInvalidIfscCode(true);
  }, [IfscCode]);

  useEffect(() => {
    if (panDetails?.length === 10 || panDetails?.length === 0)
      setInvalidPanDetails(false);
    else setInvalidPanDetails(true);
  }, [panDetails]);

  useEffect(() => {
    if (
      /^[0-9]+$/.test(accountNumber) &&
      accountNumber?.length > 0 &&
      accountNumberCopy?.length > 0 &&
      accountNumber === accountNumberCopy &&
      name?.length > 0 &&
      IfscCode?.length > 0 &&
      IfscCode.length === 11 &&
      panDetails?.length > 0 &&
      panDetails.length === 10
    )
      setBtnDisable(false);
    else setBtnDisable(true);
  }, [accountNumber, accountNumberCopy, name, IfscCode, panDetails]);

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "bank_details_pl",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Bank Details",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "page_load",
          product_name: "",
          product_price: "",
          element: "",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  const { mutate } = useUpdateSubmitCreatorBankDetailsData({
    onSuccess: {},
  });

  async function onClickPostBankDetails() {
    let body = {
      accountNumber,
      pan: panDetails,
      bankName: name,
      ifsc: IfscCode,
    };
    setBtnLoading(true);
    mutate(body);
    setBtnLoading(false);
    triggerEvent({
      eventName: "save_bank_details_btn_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Bank Details",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "save_bank_details_confirm",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  }

  return (
    <div style={{ minHeight: window.innerHeight }}>
      <div className="p-4 text-left">
        <img
          src={`${IMAGE_PREFIX}leftIcon.svg`}
          onClick={() => {
            navigate(-1);
          }}
          alt=""
        />
      </div>
      <div className="p-4 pt-1">
        <WishlinkTypography
          color={"#333333"}
          fontSize="1rem"
          fontWeight={500}
          lineHeight="1.5rem"
          className="text-left pb-1"
        >
          Edit your account details
        </WishlinkTypography>
        <WishlinkTypography
          color={"#e28025"}
          fontSize="0.75rem"
          fontWeight={600}
          lineHeight="1.125rem"
          className="text-left"
        >
          Please ensure you enter the correct bank details here.
        </WishlinkTypography>
        <WishlinkTypography
          color={"#666666"}
          fontSize="0.75rem"
          fontWeight={400}
          lineHeight="1.125rem"
          className="text-left"
        >
          All your earnings will be settled in this account
        </WishlinkTypography>

        <WishlinkTypography
          color={"#333333"}
          fontSize="0.875rem"
          fontWeight={500}
          className="text-left pt-5"
        >
          Bank Account Number
        </WishlinkTypography>
        <WishlinkTextField
          value={accountNumber}
          helperText="*Please enter a valid account number"
          error={invalidAccountNumber}
          fullWidth={true}
          borderRadius="0.25rem"
          style={{ marginTop: "0.2rem" }}
          placeholder="Please enter bank account number here"
          onInputValueChange={(val) => setAccountNumber(val)}
        />

        <WishlinkTypography
          color={"#333333"}
          fontSize="0.875rem"
          fontWeight={500}
          className="text-left pt-5"
        >
          Re-enter Bank Account Number
        </WishlinkTypography>
        <WishlinkTextField
          value={accountNumberCopy}
          helperText="*Account numbers do not match"
          error={invalidAccountNumberCopy}
          fullWidth={true}
          borderRadius="0.25rem"
          style={{ marginTop: "0.2rem" }}
          placeholder="Please re-enter bank account number here"
          onInputValueChange={(val) => setAccountNumberCopy(val)}
        />

        <WishlinkTypography
          color={"#333333"}
          fontSize="0.875rem"
          fontWeight={500}
          className="text-left pt-5"
        >
          Name
        </WishlinkTypography>
        <WishlinkTextField
          value={name}
          helperText="*Please enter a valid name"
          fullWidth={true}
          borderRadius="0.25rem"
          style={{ marginTop: "0.2rem" }}
          placeholder="Please enter your name here"
          onInputValueChange={(val) => setName(val)}
        />

        <WishlinkTypography
          color={"#333333"}
          fontSize="0.875rem"
          fontWeight={500}
          className="text-left pt-5"
        >
          IFSC Code
        </WishlinkTypography>
        <WishlinkTextField
          value={IfscCode}
          helperText="*Please enter a valid IFSC Code"
          error={invalidIfscCode}
          fullWidth={true}
          borderRadius="0.25rem"
          style={{ marginTop: "0.2rem" }}
          placeholder="Please enter IFSC Code here"
          onInputValueChange={(val) => setIfscCode(val)}
        />

        <WishlinkTypography
          color={"#333333"}
          fontSize="0.875rem"
          fontWeight={500}
          className="text-left pt-5"
        >
          PAN Details
        </WishlinkTypography>
        <WishlinkTextField
          value={panDetails}
          helperText="*Please enter valid Pan Details"
          error={invalidPanDetails}
          fullWidth={true}
          borderRadius="0.25rem"
          style={{ marginTop: "0.2rem" }}
          placeholder="Please enter Pan Details here"
          onInputValueChange={(val) => setPanDetails(val)}
        />
        <WishlinkButton
          background={"#E91E63"}
          style={{
            ":hover": {
              bgcolor: "#E91E63",
            },
            ":disabled": {
              bgcolor: "#c2c2c2",
            },
          }}
          borderRadius="0.5rem"
          width="100%"
          height="3rem"
          className="pt-5"
          disabled={btnDisable}
          onClick={() => navigate("#bank")}
        >
          <WishlinkTypography color={"ffffff"} fontSize="1rem" fontWeight={600}>
            {btnLoading ? `Please wait...` : `Save`}
          </WishlinkTypography>
        </WishlinkButton>
      </div>
      <BankDetailsDrawers
        drawerState={drawerState}
        accountNumber={accountNumber}
        btnLoading={btnLoading}
        onClickPostBankDetails={onClickPostBankDetails}
        creatorData={creatorData}
      />
    </div>
  );
};

export default BankDetails;
