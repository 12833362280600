import WishlinkButton from "../WishlinkButton";
import WishlinkTypography from "../WishlinkTypography";
import { IMAGE_PREFIX } from "../../utils/constants";

const WishlinkEmptyState = (props) => {
  const {
    graphicUrl = IMAGE_PREFIX + "wishlink-empty-state.svg",
    graphicHeight = "7.5rem",
    graphicWidth = "7.5rem",
    headingText,
    headingTextColor = "#333333",
    headingTextFontSize = "1rem",
    headingTextFontWeight = 600,
    headingTextLineHeight = "1.5rem",
    subHeadingText,
    subHeadingTextColor = "#666666",
    subHeadingTextFontSize = "0.75rem",
    subHeadingTextFontWeight = 400,
    subHeadingTextLineHeight = "1.125rem",
    buttonText,
    buttonTextColor = "#f5f5f5",
    buttonTextFontSize = "1rem",
    buttonTextFontWeight = 600,
    buttonTextLineHeight = "1.5rem",
    buttonSuffix = IMAGE_PREFIX + "arrow-right.svg",
    buttonOnClick,
    emptyStateExists,
  } = props;
  return (
    <div className="flex flex-column mt-5" style={{ alignItems: "center" }}>
      <img
        className="mb-3"
        src={graphicUrl}
        style={{ height: graphicHeight, width: graphicWidth }}
        alt="empty-list-graphic"
      />
      <WishlinkTypography
        color={headingTextColor}
        fontSize={headingTextFontSize}
        fontWeight={headingTextFontWeight}
        lineHeight={headingTextLineHeight}
        className="mb-1"
      >
        {headingText}
      </WishlinkTypography>
      <WishlinkTypography
        color={subHeadingTextColor}
        fontSize={subHeadingTextFontSize}
        fontWeight={subHeadingTextFontWeight}
        lineHeight={subHeadingTextLineHeight}
        className="pl-4 pr-4"
        textAlign="center"
      >
        {subHeadingText}
      </WishlinkTypography>
      {emptyStateExists && (
        <WishlinkButton
          borderRadius="0.5rem"
          width="90%"
          height="3rem"
          className="mt-5 w-100"
          background={"#E91E63"}
          onClick={() => buttonOnClick()}
          style={{
            ":hover": {
              bgcolor: "#E91E63",
            },
          }}
        >
          <WishlinkTypography
            color={buttonTextColor}
            fontSize={buttonTextFontSize}
            fontWeight={buttonTextFontWeight}
            lineHeight={buttonTextLineHeight}
          >
            {buttonText}
          </WishlinkTypography>
          <img src={buttonSuffix} className="pl-2" alt="button-suffix" />
        </WishlinkButton>
      )}
    </div>
  );
};

export default WishlinkEmptyState;
