import React, { useEffect, useRef, useState } from "react";
import { Accordion, AccordionSummary, Box } from "@mui/material";
import {
  IMAGE_PREFIX,
  REWARDS_ACHIEVING_METRICS,
  REWARDS_CAMPAIGN_STATUSES,
  REWARDS_EXPLAINER_CARDS,
  REWARDS_EXPLAINER_CARDS_SALES,
} from "../../utils/constants";

import { RewardProgress } from "./Components/RewardProgress";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { useNavigate } from "react-router-dom";
import {
  getAssetUrl,
  getDisplayDateRange,
  getResponsiveWidth,
} from "../../utils/common";
import { RewardsPoweredBy } from "./Components/RewardsPoweredBy";
import { Spinner } from "./Components/Spinner";
import "../../animations.css";
import { bubble } from "../../utils/animations";
import { useFetchCreatorRewardsData } from "../../apiLayer/Rewards";
import { useFetchCreatorData } from "../../apiLayer/Home";
import { triggerEvent } from "../../utils/honasa-events";
const leftArrow = "arrow-left-light.svg";

const TnCArray = [
  "You will receive the highest earned reward of each week and month!",
  "Only applicable to Wishlink Shop Creators (sales only on products from posts & collections)",
  "Only valid for May",
  "Week 1: 1st to 7th May; Week 2: 8th to 14th May; Week 3: 15th to 21st May; Week 4: 22nd to 28th May",
  "Winners to receive prizes by 7th Jun",
  "Wishlink will be sole decider of the winners",
];

const TnCTypography = (props) => {
  return (
    <div className="flex align-start flex-gap-1">
      <div className="mt-1">
        <div
          style={{
            width: "0.5rem",
            height: "0.5rem",
            borderRadius: "0.25rem",
            background: "#FFFFFF",
            boxSizing: "border-box",
          }}
        />
      </div>
      <WishlinkTypography
        fontWeight={400}
        fontSize="0.825rem"
        lineHeight="1.2rem"
        color="#FFFFFF"
        typographyClassName="flex flex-gap-2"
        className="flex flex-gap-2"
      >
        {props.children}
      </WishlinkTypography>
    </div>
  );
};

const getCreatorFullNameText = (creatorName) => {
  return !!creatorName ? `Hi ${creatorName?.split(" ")?.[0]}!` : "Hi";
};

const getRewardCategoryHeadingAndSubheading = (
  heading,
  subheading,
  subHeading2,
) => {
  return (
    <Box>
      <Box
        sx={{
          background:
            "linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%)",
          filter: "drop-shadow(0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.2))",
          boxShadow: "0rem 0.125rem 0.25rem rgba(255, 255, 255, 0.25)",
          backdropFilter: "blur(1.25rem)",
          padding: "0.5rem",
          borderRadius: "0.75rem",
        }}
      >
        <WishlinkTypography
          fontWeight={600}
          fontSize="1.125rem"
          lineHeight="1.6875rem"
          color="#FFFFFF"
        >
          {heading}
        </WishlinkTypography>
      </Box>
      <WishlinkTypography
        fontWeight={400}
        fontSize="0.875rem"
        lineHeight="1.3125rem"
        color="#FFFFFF"
        marginTop="0.75rem"
        padding="0rem 2rem"
      >
        {subheading}
      </WishlinkTypography>
      {!!subHeading2 && (
        <WishlinkTypography
          fontWeight={400}
          fontSize="0.875rem"
          lineHeight="1.3125rem"
          color="#FFFFFF"
        >
          {subHeading2}
        </WishlinkTypography>
      )}
    </Box>
  );
};

const getDashedLine = () => {
  return (
    <Box sx={{ width: "100%", boxSizing: "border-box" }}>
      <img
        src={`${IMAGE_PREFIX}${dashedLine}`}
        style={{ width: "100%" }}
        alt=""
      />
    </Box>
  );
};

const getProgressMetric = (metric, metricDesc) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0.25rem",
      }}
    >
      <WishlinkTypography
        fontWeight={600}
        fontSize="0.875rem"
        lineHeight="1.3125rem"
        color="#E38125"
      >
        {metric}
      </WishlinkTypography>
      <WishlinkTypography
        fontWeight={500}
        fontSize="0.6875rem"
        lineHeight="1rem"
        color="#666666"
      >
        {metricDesc}
      </WishlinkTypography>
    </Box>
  );
};

const explainersHeading = "How does this work?";

const rewardLockedImageUrl = "reward-locked.svg";
const rewardsUnit = "rewards_unit_august.gif";
const backgroundSparkle = "sparkle.svg";
const dashedLine = "dashed-line.svg";

const getCardFromAchieveMetric = (achieveMetric) => {
  return achieveMetric === REWARDS_ACHIEVING_METRICS.SALES
    ? REWARDS_EXPLAINER_CARDS_SALES
    : REWARDS_EXPLAINER_CARDS;
};

const GetExplainerCard = (props) => {
  const card = props.card;
  return (
    <Box
      sx={{
        display: "inline-block",
        whiteSpace: "normal",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#363636",
          boxShadow: "0rem 0.125rem 0.25rem rgba(255, 255, 255, 0.25)",
          borderRadius: "0.5rem",
          height: "7.25rem",
          width: "7.75rem",
          padding: "0.75rem",
          gap: "0.5rem",
          boxSizing: "content-box",
        }}
      >
        <Box
          sx={{
            background: `#1B1B1B`,
            borderRadius: "10rem",
            width: "2rem",
            height: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {card.imageUrl === "reward-helper-lock-main.svg" ? (
            <img
              src={`${IMAGE_PREFIX}${card.imageUrl}`}
              style={{ width: "1.2rem" }}
              alt=""
            />
          ) : (
            <img src={`${IMAGE_PREFIX}${card.imageUrl}`} alt="" />
          )}
        </Box>
        <WishlinkTypography
          fontWeight={400}
          fontSize="0.725rem"
          lineHeight="1.15rem"
          color="#FAFAFA"
          marginTop="0.5rem"
          width="90%"
        >
          {card.text}
        </WishlinkTypography>
      </Box>
    </Box>
  );
};

const Rewards = () => {
  const navigate = useNavigate();
  const leaderboardRef = useRef(null);
  const { data: rewardsData, isLoading: campaignDetailsIsLoading } =
    useFetchCreatorRewardsData();
  // const allSales = rewardsData?.[3]?.all_time_sales;
  // const confirmedSales = rewardsData?.[3]?.all_time_confirmed_sales;
  const { data: creatorData } = useFetchCreatorData({ enabled: true });
  const creatorName = creatorData?.data?.name || "";
  const [responsiveWidth, setResponsiveWidth] = useState(getResponsiveWidth);
  useEffect(() => {
    const handleScreenResize = () => {
      setResponsiveWidth(getResponsiveWidth());
    };

    window.addEventListener("resize", handleScreenResize);
    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  const [accordionState, setAccordionState] = useState([]);

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        page_url: window.location.href,
        page_name: "Rewards",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "page_load",
        product_name: "",
        product_price: "",
        element: "",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      });
      triggerEvent({
        eventName: "reward_stats_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Rewards",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "reward_stats",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  useEffect(() => {
    window.addEventListener("scroll", bubble);
    return () => {
      window.removeEventListener("scroll", bubble);
    };
  });

  useEffect(() => {
    bubble();
  }, []);

  const getHeader = (headerText) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "3.5rem",
          background: "#000000",
          padding: "1rem",
          boxSizing: "border-box",
          gap: "1.5rem",
        }}
      >
        <img
          src={getAssetUrl(leftArrow)}
          alt="left"
          onClick={() => {
            // triggerGoBackGa(pageName, screenName);
            navigate(-1);
          }}
        />
        <WishlinkTypography color="#EEEEEE" fontSize="1rem" fontWeight={500}>
          {headerText}
        </WishlinkTypography>
      </Box>
    );
  };

  const getCampaignSummaryCard = (
    campaignStatus,
    rewardWon,
    campaignName,
    campaignDateRange,
    idx,
    backgroundColor,
    sales,
    campaignSubtitle,
  ) => {
    return (
      <div className="flex flex-column w-100">
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
            marginRight: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "0.5rem",
                }}
              >
                <div>
                  <WishlinkTypography
                    fontWeight={600}
                    fontSize="0.9375rem"
                    lineHeight="1.40625rem"
                    color="#333333"
                    className="text-left"
                  >
                    {campaignName}
                  </WishlinkTypography>
                  {campaignStatus !== REWARDS_CAMPAIGN_STATUSES.LOCKED ? (
                    <WishlinkTypography
                      fontWeight={400}
                      fontSize="0.675rem"
                      lineHeight="1.40625rem"
                      color="#333333"
                      className="text-left"
                    >
                      {campaignSubtitle}
                    </WishlinkTypography>
                  ) : (
                    <></>
                  )}
                </div>
                <WishlinkTypography
                  fontWeight={600}
                  fontSize="0.75rem"
                  lineHeight="1.125rem"
                  color="#333333"
                >
                  {`${campaignDateRange}`}
                </WishlinkTypography>{" "}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginTop: "0.5rem",
                }}
              >
                {campaignStatus === REWARDS_CAMPAIGN_STATUSES.LOCKED ? (
                  <>
                    <img
                      src={`${IMAGE_PREFIX}${rewardLockedImageUrl}`}
                      alt=""
                    />
                    <WishlinkTypography
                      fontWeight={500}
                      fontSize="0.75rem"
                      lineHeight="1.125rem"
                      color="#666666"
                    >
                      {"Locked"}
                    </WishlinkTypography>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "0.125rem 0.5rem",
              gap: "0.125rem",
              background: backgroundColor,
            }}
            className="mr-2"
          >
            <WishlinkTypography
              fontWeight={500}
              fontSize="0.5625rem"
              lineHeight="0.875rem"
              letterSpacing="0.09375rem"
              textTransform="uppercase"
              color={`${
                campaignStatus === REWARDS_CAMPAIGN_STATUSES.PAST
                  ? "#000000"
                  : "#FFFFFF"
              }`}
            >
              {campaignStatus}
            </WishlinkTypography>
          </Box>
        </Box>
      </div>
    );
  };

  const getRewardProgress = (campaign, parentCampaignStatus, idx) => {
    let campaignProgressValue = 0;
    let campaignConfirmedProgressValue = 0;
    const numSlabs = campaign?.targets?.length;
    const campaignSlabs = campaign?.targets;
    const campaignMaxProgressValue =
      numSlabs > 0
        ? parseInt(campaign?.targets[numSlabs - 1]?.target_amount * 1.1)
        : 0;
    if (idx === 0) {
      if (campaign.status === REWARDS_CAMPAIGN_STATUSES.ACHIEVED) {
        campaignProgressValue = 100;
        campaignConfirmedProgressValue = 100;
      } else {
        campaignProgressValue = 0;
        campaignConfirmedProgressValue = 0;
      }
    } else if (idx !== 3) {
      if (campaign.status === REWARDS_CAMPAIGN_STATUSES.LOCKED) {
        campaignProgressValue = 0;
        campaignConfirmedProgressValue = 0;
      } else {
        const maxSales =
          campaign?.sales > campaign?.targets[numSlabs - 1]?.target_amount
            ? campaignMaxProgressValue
            : campaign.sales;
        campaignProgressValue = (maxSales / campaignMaxProgressValue) * 100;
        const maxConfirmedSales =
          campaign?.sales - campaign.pending_sales >
          campaign?.targets[numSlabs - 1]?.target_amount
            ? campaignMaxProgressValue
            : campaign.sales - campaign.pending_sales;
        campaignConfirmedProgressValue =
          (maxConfirmedSales / campaignMaxProgressValue) * 100;
      }
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        <Box>
          <Box sx={{ position: "relative" }}>
            {campaignSlabs?.map((slab, idx) => (
              <RewardProgress
                slab={slab}
                idx={idx}
                campaignMaxProgressValue={campaignMaxProgressValue}
                achieveMetric={slab.reward_amount}
                campaignState={campaign?.status}
              />
            ))}
          </Box>
          <Box
            sx={{
              "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#F2F2F2",
                boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.16)",
                borderRadius: "7.5rem",
              },
              "& .MuiLinearProgress-barColorPrimary": {
                background:
                  "linear-gradient(90.31deg, #E38125 0.25%, rgba(227, 129, 37, 0.6) 113.83%)",
                borderRadius: "0.5rem",
              },
            }}
          >
            <div
              style={{ background: "#f2f2f2", width: "100%", height: "0.5rem" }}
              className="border-radius"
            ></div>
            <div
              style={{
                background: "orange",
                width: campaignProgressValue + "%",
                height: "0.5rem",
                transform: "translateY(-0.5rem)",
              }}
              className="border-radius"
            ></div>
            <div
              style={{
                background: "green",
                width: campaignConfirmedProgressValue + "%",
                height: "0.5rem",
                transform: "translateY(-1rem)",
              }}
              className="border-radius"
            ></div>
          </Box>
        </Box>
        {campaign?.earnings && (
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            {getProgressMetric(`₹ ${campaign?.earnings}`, "Total Earnings")}
          </Box>
        )}
      </Box>
    );
  };

  const handleCampaignAccordionState = (idx, state, campaignState) => {
    let tempAccordionState = accordionState;
    tempAccordionState[idx] = state;
    setAccordionState(tempAccordionState);
  };

  return (
    <Box
      sx={{ backgroundColor: "#FFFFFF", position: "relative", margin: "auto" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {getHeader("Rewards")}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#000000",
          }}
        >
          <img
            src={`${IMAGE_PREFIX}${rewardsUnit}`}
            style={{ width: responsiveWidth }}
            alt=""
          />
          <WishlinkTypography
            fontWeight={600}
            fontSize="1.25rem"
            lineHeight="1.875rem"
            color="#FFFFFF"
          >
            {getCreatorFullNameText(creatorName)}
          </WishlinkTypography>
          <WishlinkTypography
            fontWeight={400}
            fontSize="0.875rem"
            lineHeight="1rem"
            color="#F2F2F2"
            style={{ margin: "0.5rem 1.5rem 0rem 1.5rem", zIndex: 1 }}
            className="right-transition"
          >
            Welcome to Wishlink x Pure Origin Rewards
          </WishlinkTypography>
          <WishlinkTypography
            fontWeight={400}
            fontSize="0.875rem"
            lineHeight="1rem"
            color="#F2F2F2"
            style={{ margin: "0.5rem 1.5rem 0rem 1.5rem", zIndex: 1 }}
            className="right-transition"
          >
            The more you SELL, the more you WIN!
          </WishlinkTypography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <img
                src={`${IMAGE_PREFIX}${backgroundSparkle}`}
                style={{ position: "absolute", right: "1.5rem", zIndex: 0 }}
                alt=""
              />
            </Box>
            {campaignDetailsIsLoading ? (
              <div className="flex align-center justify-center mt-6">
                {/* <Spinner size="2rem" color={"#E38125"} /> */}
              </div>
            ) : (
              <>
                {/* {getMaxRewardsWrappingText(
                  campaignData.name,
                  rewardStatus,
                  totalRewardWon,
                  totalRewardWonFormatted,
                  "3rem",
                  achieveMetric,
                  totalTargetFormatted,
                )} */}
                {/* <ValueSkeleton
                  isLoading={campaignDetailsIsLoading}
                  value={getMaxRewardAmountText(
                    rewardStatus,
                    maxRewards,
                    totalRewardWon,
                    totalRewardWonFormatted,
                  )}
                /> */}
              </>
            )}
            {/* {rewardStatus === rewardParentStatuses.PAST &&
              !!nextCampaignName &&
              getMaxRewardsWrappingSubText(nextCampaignName)} */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={`${IMAGE_PREFIX}${backgroundSparkle}`}
                style={{ position: "absolute", left: "1.5rem", zIndex: 0 }}
                alt=""
              />
            </Box>
          </Box>
          <Box sx={{ marginBottom: "2rem", marginTop: "2rem" }}>
            <WishlinkTypography
              fontWeight={600}
              fontSize="1.125rem"
              lineHeight="1.6875rem"
              color="#FFFFFF"
            >
              {explainersHeading}
            </WishlinkTypography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: "0.75rem",
                padding: "1rem",
                boxSizing: "border-box",
                whiteSpace: "nowrap",
              }}
            >
              {Object.values(getCardFromAchieveMetric()).map((cardObject) => (
                <GetExplainerCard card={cardObject} />
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#E38125",
            padding: "1.5rem",
            boxSizing: "border-box",
          }}
          ref={leaderboardRef}
        >
          <Box sx={{ marginTop: "0.35rem" }}>
            <WishlinkTypography
              fontWeight={600}
              fontSize="1rem"
              lineHeight="1.3125rem"
              color="#FFFFFF"
              className="text-left"
            >
              Your exclusive rewards
            </WishlinkTypography>
          </Box>
          <Box sx={{ marginTop: "1.5rem" }}>
            <>
              {campaignDetailsIsLoading ? (
                <div>
                  <Spinner color={"#FFFFFF"} size="2rem" />
                </div>
              ) : (
                <>
                  {Array.isArray(rewardsData) &&
                    rewardsData?.slice(0, 3)?.map((campaign, idx) => {
                      return (
                        <Box
                          sx={{ marginBottom: "1rem" }}
                          className="bubble-animation"
                        >
                          <Accordion
                            defaultExpanded={false}
                            onChange={(event, expanded) => {
                              handleCampaignAccordionState(
                                idx,
                                expanded,
                                campaign?.state?.toLowerCase(),
                              );
                              triggerEvent({
                                eventName: "accordion_click",
                                eventAttributes: {
                                  page_url: window.location.href,
                                  page_name: "Rewards",
                                  user_id: creatorData?.data?.id,
                                  timestamp: new Date().toISOString(),
                                  event_type: "click",
                                  product_name: "",
                                  product_price: "",
                                  element: expanded
                                    ? "rewards_accordion_open"
                                    : "rewards_accordion_close",
                                  gender: creatorData?.data?.gender,
                                  added_on: creatorData?.data?.added_on,
                                },
                              });
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <img
                                  src={`${IMAGE_PREFIX}down-arrow.svg`}
                                  alt=""
                                />
                              }
                            >
                              {getCampaignSummaryCard(
                                campaign?.status,
                                campaign?.reward_amount,
                                campaign.name,
                                `${getDisplayDateRange(campaign?.start_date, campaign?.end_date)}`,
                                idx,
                                campaign.status_color,
                                campaign.sales,
                                campaign.subtitle,
                              )}
                            </AccordionSummary>

                            <div className="pl-4 pr-4">
                              {getRewardProgress(
                                campaign,
                                campaign.status,
                                idx,
                              )}
                            </div>
                            {idx !== 0 ? (
                              <Box
                                sx={{ display: "flex flex-column" }}
                                className="pl-4"
                              >
                                <div className="flex">
                                  <WishlinkTypography
                                    fontWeight={500}
                                    fontSize="0.625rem"
                                    lineHeight="1.125rem"
                                    color="#E38125"
                                  >
                                    {"Pending Sales: "}
                                  </WishlinkTypography>
                                  <WishlinkTypography
                                    fontWeight={700}
                                    fontSize="0.625rem"
                                    lineHeight="1.125rem"
                                    color="#E38125"
                                  >
                                    &nbsp;
                                    {`₹ ${campaign.pending_sales}`}
                                  </WishlinkTypography>
                                </div>
                                <div className="flex">
                                  <WishlinkTypography
                                    fontWeight={500}
                                    fontSize="0.625rem"
                                    lineHeight="1.125rem"
                                    color="#008000"
                                  >
                                    {"Confirmed Sales: "}
                                  </WishlinkTypography>
                                  <WishlinkTypography
                                    fontWeight={700}
                                    fontSize="0.625rem"
                                    lineHeight="1.125rem"
                                    color="#008000"
                                  >
                                    &nbsp;
                                    {`₹ ${campaign.sales - campaign.pending_sales}`}
                                  </WishlinkTypography>
                                </div>
                              </Box>
                            ) : (
                              <></>
                            )}
                            <div className="flex justify-center">
                              {campaign?.rewards_earned ? (
                                <div className="mr-2">
                                  <WishlinkTypography
                                    fontSize="0.875rem"
                                    fontWeight={600}
                                    color="rgb(227, 129, 37)"
                                  >
                                    ₹ {campaign?.rewards_earned}
                                  </WishlinkTypography>
                                  <WishlinkTypography
                                    fontSize="0.6875rem"
                                    fontWeight={500}
                                    color="rgb(102, 102, 102)"
                                    className="pb-2"
                                  >
                                    Rewards Earned
                                  </WishlinkTypography>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="">
                                <WishlinkTypography
                                  fontSize="0.875rem"
                                  fontWeight={600}
                                  color="rgb(227, 129, 37)"
                                >
                                  ₹ {campaign?.reward_amount}
                                </WishlinkTypography>
                                <WishlinkTypography
                                  fontSize="0.6875rem"
                                  fontWeight={500}
                                  color="rgb(102, 102, 102)"
                                  className="pb-2"
                                >
                                  Max Rewards
                                </WishlinkTypography>
                              </div>
                            </div>
                          </Accordion>
                        </Box>
                      );
                    })}
                </>
              )}
            </>
          </Box>
          <Box sx={{ marginBottom: "2rem", marginTop: "1rem" }}>
            {getDashedLine()}
          </Box>
          {getRewardCategoryHeadingAndSubheading(
            "Terms and conditions",
            "",
            "",
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              textAlign: "left",
              alignItems: "flex-start",
              gap: "0.6rem",
              marginTop: "1rem",
            }}
          >
            {TnCArray?.map((tnc) => (
              <TnCTypography>{tnc}</TnCTypography>
            ))}{" "}
          </Box>
          <Box sx={{ marginTop: "1.5rem", marginBottom: "0.2rem" }}>
            {getDashedLine()}
          </Box>
          <RewardsPoweredBy logocolor={true} />
        </Box>
      </Box>
    </Box>
  );
};

export default Rewards;
