import React, { Suspense, useEffect, useState } from "react";

import "./App.css";
import RenderRoutes from "./RenderRoutes";
import Routes from "./Routes";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import WishlinkNotification from "./WishlinkComponents/WishlinkNotification";

function App() {
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(true);
  useEffect(() => {
    Routes?.forEach((route) => {
      let isFooterVisible =
        route.showFooter &&
        (localStorage.firebaseToken || localStorage.adminToken);
      if (
        route.path.substring(1).split("/")[0] ===
        location.pathname.substring(1).split("/")[0]
      ) {
        setShowFooter(isFooterVisible);
      }
    });
  }, [location]);

  return (
    <div className="App">
      <Suspense fallback={<></>}>
        <RenderRoutes routes={Routes} />
        <WishlinkNotification />
      </Suspense>
      {showFooter && <Footer />}
    </div>
  );
}

export default App;
