import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import Spinner from "../../../../WishlinkComponents/Spinner";

import { Divider } from "@mui/material";
import WishlinkAccordion from "../../../../WishlinkComponents/WishlinkAccordion";
import { IMAGE_PREFIX } from "../../../../utils/constants";
import { useFetchGetResellerPastPayouts } from "../../../../apiLayer/Payouts";

const PastPayoutItem = (props) => {
  const { payout } = props;
  const {
    combined_commissions,
    rewards_amount,
    contract_extra_commission,
    mg_extra_commission,
    fixed_amount,
    payout_total_gross,
    tds,
    amount_paid,
    status,
    has_breakdown,
    start_date_str,
    end_date_str,
    processed_at,
    combined_commissions_are_negative,
    referral_earning,
  } = payout;
  let breakdown = [
    {
      name: "Confirmed Commissions",
      amount: combined_commissions,
      icon: `organic-earnings.svg`,
      isNegative: combined_commissions_are_negative,
      show: true,
    },
    {
      name: "Rewards",
      amount: rewards_amount,
      icon: `wishlink-rewards.svg`,
      show: false,
    },
    {
      name: "Referral",
      amount: referral_earning,
      icon: `referral_earnings.svg`,
      show: false,
    },
    {
      name: "Additional Contract Earnings",
      amount: contract_extra_commission,
      icon: `contract-earnings.svg`,
      show: false,
    },
    {
      name: "Min. guarantee Earnings",
      amount: mg_extra_commission,
      icon: `guarentee-earnings.svg`,
      show: false,
    },
    {
      name: "Brand Collabs",
      amount: fixed_amount,
      icon: `brands.svg`,
      show: false,
    },
  ];

  if (tds !== 0) {
    breakdown = [
      ...breakdown,
      {
        name: "Total Earnings",
        amount: payout_total_gross,
        icon: `total-earnings.svg`,
        show: false,
        topline: true,
      },
      {
        name: "Tax Deducted",
        amount: tds,
        icon: `tax-deducted.svg`,
        show: true,
        isNegative: true,
      },
      {
        name: "Net Paid",
        amount: amount_paid,
        icon: `rupee-filled-icon.svg`,
        show: false,
        topline: true,
        isBold: true,
      },
    ];
  }

  return (
    <div>
      <WishlinkAccordion
        toggleGa={(isExpanded) => {}}
        expandIcon={
          <>
            {has_breakdown ? (
              <img src={`${IMAGE_PREFIX}down-arrow.svg`} alt="" />
            ) : (
              <div style={{ paddingLeft: "1.125rem" }}></div>
            )}
          </>
        }
        title={
          <div className="flex align-center justify-between w-100 pr-2">
            <div className="mr-2">
              <WishlinkTypography
                color={"#333333"}
                fontSize="0.75rem"
                fontWeight={400}
                className="text-left pt-1"
              >
                {start_date_str} {start_date_str && "-"} {end_date_str}
              </WishlinkTypography>
              <div className="flex align-center">
                <img src={`${IMAGE_PREFIX}rupee-black-icon.svg`} alt="" />
                <WishlinkTypography
                  color={"#333333"}
                  fontSize="1.5rem"
                  fontWeight={700}
                  className="ml-1"
                >
                  {amount_paid}
                </WishlinkTypography>
              </div>
            </div>
            {status === "PAID" ? (
              <img src={`${IMAGE_PREFIX}paid.svg`} alt="" />
            ) : status === "FAILED" ? (
              <img src={`${IMAGE_PREFIX}failed-payout.svg`} alt="" />
            ) : (
              <img src={`${IMAGE_PREFIX}pending.svg`} alt="" />
            )}
          </div>
        }
      >
        {has_breakdown && (
          <>
            <div
              className="border-radius p-4 pb-2"
              style={{
                border: `0.0625rem solid #E5E5E5`,
                background: "#F2F5FF",
              }}
            >
              {breakdown?.map((comp, idx) => {
                return comp.amount || comp.show ? (
                  <div key={idx}>
                    {comp.topline && (
                      <div className="pb-2">
                        <Divider />
                      </div>
                    )}
                    <div className="flex align-center justify-between pb-2">
                      <div className="flex align-center">
                        <img
                          src={`${IMAGE_PREFIX}${comp.icon}`}
                          style={{ width: "1rem", height: "1rem" }}
                          alt=""
                        />
                        <WishlinkTypography
                          color={"#333333"}
                          fontSize={comp.isBold ? "1.125rem" : "0.875rem"}
                          fontWeight={comp.isBold ? 500 : 400}
                          className="ml-2"
                        >
                          {comp.name}
                        </WishlinkTypography>
                      </div>
                      <div className="flex align-center">
                        {comp.isNegative ? "-" : ""}
                        <img
                          src={`${IMAGE_PREFIX}rupee-black-icon.svg`}
                          style={{ width: "0.537rem" }}
                          alt=""
                        />
                        <WishlinkTypography
                          color={"#333333"}
                          fontSize={comp.isBold ? "1.125rem" : "0.875rem"}
                          fontWeight={comp.isBold ? 600 : 400}
                          className="ml-1"
                        >
                          {comp.amount}
                        </WishlinkTypography>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span key={idx}></span>
                );
              })}
            </div>
            {processed_at && (
              <div className="flex align-center pt-2 pb-4">
                <img src={`${IMAGE_PREFIX}calendar-outline-grey.svg`} alt="" />
                <WishlinkTypography
                  color={"#666666"}
                  fontSize="0.75rem"
                  fontWeight={400}
                  className="ml-1"
                >
                  Processed on: {processed_at}
                </WishlinkTypography>
              </div>
            )}
          </>
        )}
      </WishlinkAccordion>
      <Divider />
    </div>
  );
};

export const PastPayouts = () => {
  const { data: pastPayoutsData, isLoading: pastPayoutsIsLoading } =
    useFetchGetResellerPastPayouts({
      enabled: true,
    });

  return (
    <div className="p-4" style={{ paddingBottom: "5rem" }}>
      <WishlinkTypography
        fontSize="1rem"
        fontWeight={500}
        color={"#141414"}
        className="text-left mt-4 right-transition"
      >
        Past Payouts
      </WishlinkTypography>
      {pastPayoutsIsLoading ? (
        <div className="flex align-center justify-center mt-6">
          <Spinner />
        </div>
      ) : (
        <>
          {!pastPayoutsData?.data?.length && (
            <img
              src={`${IMAGE_PREFIX}past-payout-empty.svg`}
              className="pt-5"
              alt=""
            />
          )}
          {pastPayoutsData?.data?.length ? (
            <>
              <WishlinkTypography
                fontSize="0.75rem"
                fontWeight={400}
                color={"#666666"}
                className="text-left right-transition"
              >
                Showing all your past payouts
              </WishlinkTypography>
              {pastPayoutsData?.data?.map((payout, idx) => {
                return (
                  <PastPayoutItem
                    payout={payout}
                    key={idx}
                    position={idx + 1}
                  />
                );
              })}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
