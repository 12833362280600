import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { store } from "./store";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
const queryClient = new QueryClient();

window.customCopy = (text, navigator) => navigator?.clipboard.writeText(text);
window.customPaste = (pasteFunction, navigator) =>
  navigator?.clipboard.readText().then(pasteFunction);
window.customShare = (text, navigator) => navigator?.share({ text: text });

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>,
);
