import { useQuery } from "@tanstack/react-query";
import { getRewardStatusColor, makeAPICall } from "../../utils/common";
import { BASE_URL } from "../../utils/constants";

const transformRewardData = (data) => {
  if (data?.data?.success === true) {
    const rewardsData = data.data.data;
    let resultRewardsData = [];
    rewardsData.map(({ reward_data, reward_name, reward_subtitle }, idx) => {
      resultRewardsData.push({
        status: reward_data.reward_status,
        status_color: getRewardStatusColor(reward_data.reward_status),
        name: reward_name,
        sales: reward_data.sales || 0,
        pending_sales: reward_data.pending_sales || 0,
        targets: reward_data.targets || [],
        subtitle: reward_subtitle,
        rewards_earned: 0,
      });
      if (idx === 0) {
        resultRewardsData[0]["reward_amount"] = reward_data.reward_amount;
      } else if (idx === 1) {
        resultRewardsData[1]["reward_amount"] =
          reward_data.targets[0].reward_amount;
      } else if (idx === 2) {
        resultRewardsData[2]["reward_amount"] =
          reward_data.targets[0].reward_amount +
          reward_data.targets[1].reward_amount;

        if (
          reward_data.sales - reward_data.pending_sales >=
            reward_data.targets[0].target_amount &&
          reward_data.sales - reward_data.pending_sales <
            reward_data.targets[1].target_amount
        ) {
          resultRewardsData[2]["rewards_earned"] =
            reward_data.targets[0].reward_amount;
        } else if (
          reward_data.sales - reward_data.pending_sales >=
          reward_data.targets[1].target_amount
        ) {
          resultRewardsData[2]["rewards_earned"] =
            reward_data.targets[0].reward_amount +
            reward_data.targets[1].reward_amount;
        } else if (
          reward_data.sales - reward_data.pending_sales <
          reward_data.targets[0].target_amount
        ) {
          resultRewardsData[2]["rewards_earned"] = 0;
        }
      }
      return { reward_data, reward_name };
    });
    resultRewardsData.push({
      all_time_sales: data?.data?.honasa_all_time_sales,
      all_time_confirmed_sales:
        data?.data?.honasa_all_time_sales -
        data?.data?.honasa_all_time_pending_sales,
    });
    return resultRewardsData;
  }
  return data;
};

export const useFetchCreatorRewardsData = () => {
  return useQuery({
    queryKey: ["creatorRewards"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerRewards`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    select: transformRewardData,
  });
};
