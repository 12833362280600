import { useNavigate } from "react-router-dom";

import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { PAGE_URLS } from "../../utils/constants";

const BankDetailsEditingDrawers = (props) => {
  const { drawerState } = props;
  const navigate = useNavigate();

  return (
    <>
      <WishlinkDrawer
        drawerOpen={drawerState}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
      >
        <div className="p-4 text-center">
          <WishlinkTypography
            color={"#141414"}
            fontSize="1rem"
            fontWeight={500}
            className="mt-4 mb-3"
          >
            Account Details
          </WishlinkTypography>
          <WishlinkTypography
            color={"#666666"}
            fontSize="0.875rem"
            fontWeight={400}
          >
            Are you sure you want to change your bank account details?
          </WishlinkTypography>
          <WishlinkButton
            background={"#E91E63"}
            className="mt-5 mb-2"
            height="3rem"
            width="100%"
            borderRadius="0.5rem"
            style={{
              ":hover": {
                bgcolor: "#E91E63",
              },
            }}
            onClick={() => navigate(PAGE_URLS.BANKDETAILS, { replace: true })}
          >
            <WishlinkTypography
              fontSize="1rem"
              fontWeight={600}
              color={"#ffffff"}
              lineHeight="1.312rem"
            >
              Yes, proceed
            </WishlinkTypography>
          </WishlinkButton>
          <WishlinkButton
            border={`0.06rem solid ${"#E91E63"}`}
            borderRadius="0.5rem"
            width="100%"
            height="3rem"
            onClick={() => navigate(-1, { replace: true })}
          >
            <WishlinkTypography
              fontSize="1rem"
              fontWeight={600}
              color={"#E91E63"}
              lineHeight="1.312rem"
            >
              No, cancel
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default BankDetailsEditingDrawers;
