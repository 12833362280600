import { useEffect } from "react";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { triggerEvent } from "../../utils/honasa-events";

const StoryIconList = (props) => {
  const { onClickStoryIcon, highlightsRead, highlightsUnread, creatorData } =
    props;

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "storyhighlight_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "story_highlight",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  const storyRenderer = (story, idx) => {
    return (
      <div
        key={idx}
        className="inline-block relative text-center"
        style={{ width: "5rem" }}
        onClick={(e) => onClickStoryIcon(e, story)}
      >
        <div
          className="m-auto cursor-pointer"
          style={{
            background: story.bg_color,
            borderRadius: "50%",
            width: "3.125rem",
            height: "3.125rem",
            filter: story.mark_as_read ? "grayscale(1)" : "grayscale(0)",
            border: "1px solid #DF472C",
          }}
        >
          <img
            src={story.icon_url}
            className="absolute"
            style={{
              width: "2rem",
              height: "2rem",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            alt=""
          />
        </div>
        <WishlinkTypography fontSize="0.75rem" color={"#000"}>
          {story.name}
        </WishlinkTypography>
      </div>
    );
  };

  return (
    <div
      style={{ height: "5rem" }}
      className="white-nowrap overflow-x-scroll overflow-y-hidden pl-4 pr-4 pt-4 text-left"
    >
      {highlightsUnread?.map((story, idx) => {
        return storyRenderer(story, idx);
      })}
      {highlightsRead?.map((story, idx) => {
        return storyRenderer(story, idx);
      })}
    </div>
  );
};

export default StoryIconList;
