import { auth } from "./firebase";
import { saveState } from "./localStorage";
import {
  CONTACT_WISHLINK_NUMBER,
  DATE_PRESETS,
  IMAGE_FILE_EXTENSIONS,
  IMAGE_PREFIX,
  LINK_REGEX,
  NON_PRODUCT_LINK_IDENTIFIERS,
  IMAGE_PREFIX_SOURCING_ASSETS,
} from "./constants";
import { getAnalytics, setUserId } from "firebase/analytics";
import { jwtDecode } from "jwt-decode";

export const makeAPICall = async ({
  url,
  method,
  body,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("firebaseToken")}`,
  },
}) => {
  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });
  if (response.status === 403) firebaseUserStateChange();
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const addHours = (date, hours) => {
  const dateCopy = new Date(date);
  dateCopy.setTime(dateCopy.getTime() + hours * 60 * 60 * 1000);
  return dateCopy;
};

export const refreshFirebaseAuthStatusOrSignOut = async () => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const token = await currentUser.getIdToken();
    saveState("tokenExpiry", addHours(new Date(), 1));
    saveState("firebaseToken", token);
  } else {
    await signOutFirebaseAuth();
  }
};

export const signOutFirebaseAuth = async () => {
  localStorage.clear();
  await auth.signOut();
};

export const getResponsiveWidth = (widthFactor = 100) => {
  return window.innerWidth > 448
    ? `${(28 * widthFactor) / 100}rem`
    : window.innerWidth < 360
      ? `${(22.5 * widthFactor) / 100}rem`
      : `${(window.innerWidth * widthFactor) / 100 / 16}rem`;
};

export const copyToClipboard = async (textToCopy) => {
  await window?.customCopy(textToCopy, navigator);
};

export const openShareDrawer = async (textToShare) => {
  try {
    await window?.customShare(textToShare, navigator);
  } catch (e) {
    // console.log("error opening share drawer", e);
  }
};

export const copyToClipboardAndOpenShareDrawer = async (textToCopyAndShare) => {
  await window?.customCopy(textToCopyAndShare, navigator);
  try {
    await window?.customShare(textToCopyAndShare, navigator);
  } catch (e) {
    // console.log("error opening share drawer", e);
  }
};

export const openLinkInNewTab = (linkToOpen) => {
  window.open(linkToOpen, "_blank", "noreferrer");
};

export const getFormattedDate = (date) => {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const getCommissionRangeOfProducts = (products) => {
  if (!products || products?.length === 0) {
    return "0";
  }
  let minCommission = 100;
  let maxCommission = 0;
  for (let i = 0; i < products?.length; i++) {
    let productCommission = products[i]?.commission
      ? parseFloat(products[i]?.commission)
      : 0;
    if (productCommission > maxCommission) {
      maxCommission = productCommission;
    }
    if (productCommission < minCommission) {
      minCommission = productCommission;
    }
  }
  if (minCommission === maxCommission) {
    return minCommission;
  } else {
    return `${minCommission} - ${maxCommission}`;
  }
};

export const getDataPointLoadingValue = (dataPoint) => {
  return dataPoint?.status === "pending";
};

export const getAssetUrl = (assetUrl) => {
  return IMAGE_PREFIX + assetUrl;
};

export const getSourcingAssetUrl = (assetUrl) => {
  return IMAGE_PREFIX_SOURCING_ASSETS + assetUrl;
};

export const getImageUrlFromCategoryNamePng = (categoryName) => {
  if (categoryName === "") return "";
  return IMAGE_PREFIX_SOURCING_ASSETS + categoryName + ".png";
};

export const getImageUrlFromCategoryNameJpeg = (categoryName) => {
  return IMAGE_PREFIX_SOURCING_ASSETS + categoryName + ".jpeg";
};

export const getOpacityForDisabledValue = (disabled) => {
  return disabled ? "0.4" : "1";
};

export function getCroppedImg(image, crop) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * 4;
  canvas.height = crop.height * 4;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * 4,
    crop.height * 4,
  );
  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        blob.name = "croppedImage";
        resolve(blob);
      },
      "image/jpeg",
      1,
    );
  });
}

export const getDisplayDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return "";
  }
  const sdt = new Date(startDate);
  const sday = sdt.getDate();
  const smonth = sdt.toLocaleString("default", { month: "short" });
  const edt = new Date(endDate);
  const eday = edt.getDate();
  const emonth = edt.toLocaleString("default", { month: "short" });
  return `${sday} ${smonth} - ${eday} ${emonth}`;
};

export const getStartAndEndDateFromPreset = (preset) => {
  let today = new Date();
  let startDate = new Date();
  let endDate = new Date();
  if (preset === DATE_PRESETS.LAST_MONTH) {
    startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  } else if (preset === DATE_PRESETS.YESTERDAY) {
    endDate = new Date(today.setDate(today.getDate() - 1));
    startDate = endDate;
  } else if (preset === DATE_PRESETS.TODAY) {
    startDate = today;
  } else if (preset === DATE_PRESETS.ALL_TIME) {
    startDate = new Date(2020, 1, 1, 0, 0, 0, 0);
  } else if (preset === DATE_PRESETS.THIS_MONTH) {
    startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  } else if (preset === DATE_PRESETS.LAST_2_MONTHS) {
    startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  } else if (preset === DATE_PRESETS.LAST_3_MONTHS) {
    startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
    endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  } else if (preset === DATE_PRESETS.LAST_7_DAYS) {
    startDate = new Date(today.setDate(today.getDate() - 7));
  } else if (preset === DATE_PRESETS.LAST_TO_LAST_MONTH) {
    startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    endDate = new Date(today.getFullYear(), today.getMonth() - 1, 0);
  }
  return [startDate, endDate];
};

export const formatDate = (date) => {
  let year = date.toLocaleString("default", { year: "numeric" });
  let month = date.toLocaleString("default", { month: "2-digit" });
  let day = date.toLocaleString("default", { day: "2-digit" });
  return year + "-" + month + "-" + day;
};

export function getDateObjectFromDateString(date) {
  let dateArray = date?.split("-");
  let dateObject = null;
  if (dateArray?.length === 3)
    dateObject = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2]),
    );
  return dateObject;
}

export const getDisplayTextFromDatesAndPresets = (
  startDate,
  endDate,
  preset,
  allowedPresets,
) => {
  if (preset && allowedPresets?.includes(preset)) {
    return preset;
  } else {
    let startDateToDate = new Date(startDate);
    let endDateToDate = new Date(endDate);
    return getDisplayDateRange(startDateToDate, endDateToDate);
  }
};

export const getShareAllLinksText = (
  creatorUsername,
  postId,
  type,
  products,
  postLink = "",
) => {
  let text = "";
  if (type === "collection") {
    text = `*Collection Link:* ${getPostURL(
      creatorUsername,
      postId,
      type,
    )}\n\n*Short link for stories:* \n\n${getAllProductShortUrls(products)}`;
  } else {
    if (postLink?.includes("instagram.com")) {
      text = `*Post Link:* ${getPostURL(
        creatorUsername,
        postId,
        type,
      )}\n\n*Short link for stories:* \n\n${getAllProductShortUrls(products)}`;
    } else {
      text = `*All Product Links:* ${getPostURL(
        creatorUsername,
        postId,
        type,
      )}\n\n*Individual Product Links:* \n\n${getAllProductShortUrls(
        products,
      )}`;
    }
  }
  return text;
};

export const getAllProductShortUrls = (products) => {
  return products
    .filter((prod) => prod.is_alive)
    .map(
      (product, i) =>
        `${i + 1}. ${product?.name}: ${
          product?.shorturl || product?.short_url
        }\n`,
    )
    .join("\n");
};

export const getPostURL = (creatorUsername, postId, kind) => {
  return `https://wishlink.com/${creatorUsername}/${kind}/${postId}`;
};

export const formatAmountK = (amount) => {
  let formattedAmount = "";
  let isNegative = false;
  let magnitude = 0;
  if (typeof amount != "number") {
    return formattedAmount;
  }
  if (amount < 0) {
    isNegative = true;
  }
  amount = Math.abs(Math.ceil(amount));
  if (amount >= 1000) {
    magnitude += 1;
    amount = amount / 1000;
    while (amount >= 100 && magnitude < 3) {
      magnitude += 1;
      amount = amount / 100;
    }
  }
  amount = Number(amount.toPrecision(3));
  return (
    (isNegative ? "-" : "") +
    amount?.toString() +
    ["", "K", "L", "Cr"][magnitude]
  );
};

export const createDIYPostObj = (inputObj, type) => {
  const diyPost = {};
  if (type === "instagram") {
    diyPost.post_url = inputObj?.permalink;
    diyPost.media_type = inputObj?.media_type;
    diyPost.thumbnail_url = inputObj?.thumbnail_url || inputObj?.media_url;
    diyPost.media_url = inputObj?.media_url;
    diyPost.post_social_media_id = inputObj?.id;
    diyPost.post_added_on_social_media = inputObj?.timestamp;
  } else if (type === "youtube") {
    diyPost.post_url = inputObj?.post_url;
    diyPost.media_type = "VIDEO";
    diyPost.thumbnail_url = `https://img.youtube.com/vi/${inputObj?.contentDetails?.videoId}/0.jpg`;
    diyPost.media_url = `https://www.youtube.com/embed/${inputObj?.contentDetails?.videoId}`;
    diyPost.post_social_media_id = "";
    diyPost.post_added_on_social_media = inputObj?.snippet?.publishedAt;
  } else {
    // console.log("We shouldn't have been here :(");
  }
  return diyPost;
};

export const triggerGAUserId = (creatorId) => {
  let adminToken = localStorage.adminToken;
  if (creatorId && !adminToken) {
    if (window.cordova) {
      window.FirebasePlugin?.setUserId(creatorId?.toString());
    } else {
      const analytics = getAnalytics();
      setUserId(analytics, creatorId);
      window.gtag("config", "G-9K7DV3BSPQ", {
        user_id: creatorId,
      });
    }
  }
};

export const contactWishlinkOnWhatsapp = (messageCopy) => {
  return `https://wa.me/${CONTACT_WISHLINK_NUMBER}?text=${encodeURIComponent(
    messageCopy,
  )}`;
};

export const triggerInstagramLogin = () => {
  window.location.href = `https://api.instagram.com/oauth/authorize?client_id=1219800908925647&redirect_uri=${window.location.origin}/auth/code&response_type=code&scope=user_profile,user_media`;
};

export const triggerYoutubeLogin = async () => {
  const autoLoginApp = localStorage.getItem("loginApp");
  if (autoLoginApp && autoLoginApp === "yt") {
    await signOutFirebaseAuth();
  }
  /* global google */
  const client = google.accounts.oauth2.initCodeClient({
    client_id:
      "429224986687-r19fp48m6j5g1ri7spb3d659ig0pvknu.apps.googleusercontent.com",
    // eslint-disable-next-line no-useless-escape
    scope: `https://www.googleapis.com/auth/youtube.readonly \ https://www.googleapis.com/auth/youtubepartner`,
    ux_mode: "redirect",
    redirect_uri: "https://creator.wishlink.com/youtube-auth",
    access_type: "offline",
    callback: () => {},
  });
  client.requestCode();
};

export const triggerEngageLogin = () => {
  const client_id = "665365291834413";
  let redirect_uri = window.location.origin + "/auth/success";
  if (window.cordova) {
    redirect_uri = redirect_uri.replace("app://", "https://");
  }
  const response_type = "token";
  const scope = [
    "email",
    "public_profile",
    "pages_show_list",
    "instagram_basic",
    "instagram_manage_insights",
    "instagram_manage_comments",
    "instagram_manage_messages",
    "pages_manage_metadata",
    "pages_messaging",
    "pages_read_engagement",
    "pages_show_list",
  ];
  window.location.href = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}`;
};

export const preloadImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = resolve;
    image.onerror = reject;
  });
};

export const preloadImages = (urls) => {
  const promises = urls.map((url) => preloadImage(url));
  return Promise.all(promises);
};

export const checkIfLinkIsValidScrapingLink = (
  link,
  scrapingFailureDomains = [],
  username = "",
) => {
  let returnObject = { success: true, message: "" };
  if (!link) {
    returnObject.message = "Please add a link";
  }
  if (typeof link !== "string") {
    returnObject.message = "Please add a link";
  }
  link = link?.toLowerCase();
  let isUrlType = LINK_REGEX.test(link);
  if (!isUrlType) {
    returnObject.message =
      "Please enter a product link, this does not seem to be a link.";
  }
  if (
    NON_PRODUCT_LINK_IDENTIFIERS.some((str) => link.includes(str.toLowerCase()))
  ) {
    returnObject.message =
      "Please add a product link, this seems like a social media link";
  }
  if (
    link.includes("wishlink.com/") &&
    !(
      link.includes("wishlink.com/share/") || link.includes("wishlink.com/buy/")
    ) &&
    username !== "wishlinksrishti"
  ) {
    returnObject.message =
      "Please add a product link, this seems like a wishlink storefront url";
  }
  try {
    let processedUrl = new URL(link);
    let path = processedUrl.pathname;
    for (const str of IMAGE_FILE_EXTENSIONS) {
      if (path.endsWith(str)) {
        returnObject.message =
          "Please add a product link, this seems like an image url";
        break;
      }
    }
  } catch (e) {
    returnObject.message = "Please add a valid product link";
  }
  for (const domain of scrapingFailureDomains) {
    if (link?.includes(domain)) {
      returnObject.message = "Please add a valid product link";
      break;
    }
  }
  if (returnObject.message !== "") {
    returnObject.success = false;
  }
  return returnObject;
};

export const checkInvalidLinkExists = (links, scrapingFailureDomains = []) => {
  let invalidLinkExists = false;
  for (const link of links) {
    let validity = checkIfLinkIsValidScrapingLink(link, scrapingFailureDomains);
    if (!validity.success && link !== "") {
      invalidLinkExists = true;
      break;
    }
  }
  return invalidLinkExists;
};

export const checkValidLinkExists = (links, scrapingFailureDomains = []) => {
  let validLinkExists = false;
  for (const link of links) {
    let validity = checkIfLinkIsValidScrapingLink(link, scrapingFailureDomains);
    if (validity.success) {
      validLinkExists = true;
      break;
    }
  }
  return validLinkExists;
};

export const getTimeAgoTextFromDateAndCurrentTimestamps = (
  dateSent,
  dateToday,
) => {
  let dateDelta = dateToday - dateSent;
  let daysDelta = Math.floor(dateDelta / (1000 * 60 * 60 * 24));

  if (daysDelta < 0) {
    return "now";
  } else if (daysDelta === 0) {
    let secondsDelta = Math.floor(dateDelta / 1000);

    if (secondsDelta < 60) {
      if (secondsDelta === 0) {
        return "now";
      }
      return `${secondsDelta}s`;
    } else if (secondsDelta >= 60 && secondsDelta < 3600) {
      let minutesDelta = Math.floor(secondsDelta / 60);
      return `${minutesDelta}m`;
    } else {
      let hoursDelta = Math.floor(secondsDelta / 3600);
      return `${hoursDelta}h`;
    }
  } else if (daysDelta >= 1 && daysDelta < 7) {
    return `${daysDelta}d`;
  } else if (daysDelta >= 7 && daysDelta < 30) {
    let weeksDelta = Math.floor(daysDelta / 7);
    return `${weeksDelta}w`;
  } else if (daysDelta >= 30 && daysDelta < 360) {
    let monthsDelta = Math.floor(daysDelta / 30);
    return `${monthsDelta}M`;
  } else {
    if (daysDelta < 365) {
      return "1y";
    } else {
      let yearsDelta = Math.floor(daysDelta / 365);
      return `${yearsDelta}y`;
    }
  }
};

export const firebaseUserStateChange = async () => {
  const unsunscribeFn = auth.onAuthStateChanged(
    async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        if (token && localStorage?.firebaseToken) {
          localStorage.setItem("firebaseToken", token);
          let decodedToken = jwtDecode(token);
          const tokenExpiry = decodedToken?.exp;
          localStorage.setItem("firebaseTokenExpiry", tokenExpiry);
        }
      } else {
        if (!localStorage.adminToken) {
          localStorage.removeItem("firebaseToken");
          localStorage.removeItem("firebaseTokenExpiry");
          localStorage.removeItem("adminToken");
        }
      }
      // resolve(user);
      unsunscribeFn();
    },
    (error) => {
      // reject(error);
    },
  );
};

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol)
    : "0";
}

export function kFormatter(num) {
  if (num >= 1000) {
    const suffixes = ["", "k", "M", "B", "T"];
    const suffixNum = Math.floor(("" + num).length / 3);
    let shortValue = parseFloat(
      (suffixNum !== 0 ? num / Math.pow(1000, suffixNum) : num).toPrecision(2),
    );
    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }
  return num;
}

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function formatDate1(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Pad with leading 0 if needed
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
  }
}

export function formatDate2(dateString) {
  if (dateString) {
    const dateObj = new Date(dateString);
    const date = String(dateObj.getDate()).padStart(2, "0"); // Pad with leading 0 if needed
    const day = dayNames[dateObj.getDay()];
    const month = monthNames[dateObj.getMonth()];

    return `${day}, ${date} ${month}`;
  }
}

const formatDay = (day) => {
  if (day % 10 === 1 && day !== 11) return day + "st";
  if (day % 10 === 2 && day !== 12) return day + "nd";
  if (day % 10 === 3 && day !== 13) return day + "rd";
  return day + "th";
};

export function getDeliveryEstimatedDateRangeType2(startDate, endDate) {
  const startDay = dayNames[startDate.getDay()];
  const endDay = dayNames[endDate.getDay()];
  const startDateDisplay = formatDay(startDate.getDate());
  const endDateDisplay = formatDay(endDate.getDate());
  const startMonth = monthNames[startDate.getMonth()];
  const endMonth = monthNames[endDate.getMonth()];

  return `${startDay}, ${startDateDisplay} ${startMonth} - ${endDay}, ${endDateDisplay} ${endMonth}`;
}

export const getProductVariantWithDelivery = (variant, is_barter = false) => {
  let newObject = { ...variant };
  const startDate = new Date();
  const daysStart =
    newObject?.product?.brand?.estimated_delivery_start_day || 5;
  startDate.setDate(startDate.getDate() + daysStart);

  const daysEnd = newObject?.product?.brand?.estimated_delivery_end_day || 7;
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + daysEnd);

  newObject.dateRange =
    getDeliveryEstimatedDateRangeType2(startDate, endDate) || "Unavailable";
  newObject.isBarter = is_barter;
  return newObject;
};

export const DeliveryStatus = {
  RECEIVED: "received",
  ORDERED: "ordered",
  CANCELLED: "cancelled",
  SHIPPED: "shipped",
  DELIVERED: "delivered",
  DELIVERY_FAILED: "delivery_failed",
  RETURN_INITIATED: "return_initiated",
  RETURN_PICKED_UP: "return_picked_up",
  RETURN_FAILED_X: "return_failed_x",
  RETURN_FAILED_AND_DEAD: "return_failed_and_dead",
  RETURNED: "returned",
  MANUAL_ORDER_CANCELLED: "manual_order_canceled",
};

export const getDeliveryStatusInfo = (status) => {
  let status_display = "";
  let status_logo = "";
  switch (status) {
    case DeliveryStatus.RECEIVED:
      status_display = "Request Received";
      status_logo = "sourcing-order-placed.svg";
      break;
    case DeliveryStatus.ORDERED:
      status_display = "Order Placed";
      status_logo = "sourcing-order-placed.svg";
      break;
    case DeliveryStatus.MANUAL_ORDER_CANCELLED:
    case DeliveryStatus.CANCELLED:
      status_display = "Cancelled";
      status_logo = "sourcing-cancelled.svg";
      break;
    case DeliveryStatus.SHIPPED:
      status_display = "Shipped";
      status_logo = "sourcing-shipped.svg";
      break;
    case DeliveryStatus.DELIVERED:
      status_display = "Delivered";
      status_logo = "sourcing-delivered.svg";
      break;
    case DeliveryStatus.DELIVERY_FAILED:
      status_display = "Delivery Failed";
      status_logo = "sourcing-cancelled.svg";
      break;
    case DeliveryStatus.RETURN_BY:
      status_display = "Return By";
      status_logo = "sourcing-cancelled.svg";
      break;
    case DeliveryStatus.RETURN_INITIATED:
      status_display = "Return Initiated";
      status_logo = "sourcing-return-initiated.svg";
      break;
    case DeliveryStatus.RETURN_PICKED_UP:
      status_display = "Return Picked up";
      status_logo = "sourcing-cancelled.svg";
      break;
    case DeliveryStatus.RETURN_FAILED_X ||
      DeliveryStatus.RETURN_FAILED_AND_DEAD:
      status_display = "Return Failed";
      status_logo = "sourcing-cancelled.svg";
      break;
    case DeliveryStatus.RETURNED:
      status_display = "Returned";
      status_logo = "sourcing-returned.svg";
      break;
    case DeliveryStatus.MARK_AS_BARTER:
      status_display = "Barter";
      status_logo = "sourcing-cancelled.svg";
      break;
    default:
      status_display = "Order Placed";
      status_logo = "sourcing-order-placed.svg";
  }

  return {
    status_display: status_display,
    status_logo: status_logo,
  };
};

export const MANUAL_ORDER_CANCELLED_REASON = "Credit Limit exceeded";

export const sanitize_price = (num) => {
  if (!num) {
    return 0;
  }
  const number = parseInt(num);
  return number.toLocaleString();
};

export const getRewardStatusColor = (status) => {
  switch (status) {
    case "Active":
      return "#00C6A8";
    case "Achieved":
      return "#7BBC57";
    case "Locked":
      return "#FD6464";
    case "Failed":
      return "#BD3F43";
    default:
      return "#00C6A8";
  }
};
