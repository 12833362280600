import Lottie from "react-lottie-player";

const WishlinkLottie = (props) => {
  const { loop, play, style, path, className } = props;
  return (
    <>
      <Lottie
        className={className}
        loop={loop}
        play={play}
        style={style}
        path={path}
      />
    </>
  );
};

export default WishlinkLottie;
