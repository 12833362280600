import { useEffect } from "react";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import AnalyticsCardContainer from "../../components/Analytics/AnalyticsCardContainer";
import OrderHistoryHeader from "../../components/OrderHistory/OrderHistoryHeader";
import OrderLedger from "../../components/OrderHistory/OrderLedger";
import { IMAGE_PREFIX } from "../../utils/constants";
import { triggerEvent } from "../../utils/honasa-events";
import { useFetchCreatorData } from "../../apiLayer/Home";

const OrderHistory = () => {
  const { data: creatorData } = useFetchCreatorData({ enabled: true });
  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "order_history_pl",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Order-History",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "page_load",
          product_name: "",
          product_price: "",
          element: "",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "sales_commissions_orderhistory_header_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Order-History",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "sales_commissions_orderhistory_header",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "order_ledger_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Order-History",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "order_ledger",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  return (
    <div
      className="flex flex-column justify-between"
      style={{ minHeight: window.innerHeight }}
    >
      <div>
        <OrderHistoryHeader creatorData={creatorData} />
        <div>
          <AnalyticsCardContainer />
        </div>
        <div className="pb-4 pl-4 pr-4">
          <OrderLedger />
        </div>
      </div>
      <div
        className="flex justify-center align-center mt-5 pt-1 pb-5"
        style={{ paddingBottom: "7rem" }}
      >
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={400}
          color={"#333333"}
        >
          ⚡ Powered by
        </WishlinkTypography>
        <img
          className="ml-1"
          src={`${IMAGE_PREFIX}wishlink-orange.png`}
          style={{ width: "20%" }}
          alt=""
        />
      </div>
    </div>
  );
};

export default OrderHistory;
