import "./index.css";

const Spinner = (props) => {
  const { size = "0.7rem", color = "#e38125" } = props;
  return (
    <div className="bouncing-loader flex justify-center pt-3">
      <div style={{ background: color, width: size, height: size }}></div>
      <div style={{ background: color, width: size, height: size }}></div>
      <div style={{ background: color, width: size, height: size }}></div>
    </div>
  );
};

export default Spinner;
