import { useState } from "react";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkHelper from "../../WishlinkComponents/WishlinkHelper";
import { IMAGE_PREFIX } from "../../utils/constants";
import { getFormattedDate } from "../../utils/common";

const OrderEntry = (props) => {
  const { item } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      <div
        className="grid mt-3"
        style={{ gridTemplateColumns: "0.4fr 1.75fr 0.75fr" }}
      >
        <img
          src={`${item.brand_logo_url}`}
          style={{ width: "2rem", borderRadius: "1.5rem" }}
          alt=""
        />
        <div className="flex align-center">
          <WishlinkTypography
            fontSize="0.875rem"
            fontWeight={600}
            color={"#333333"}
          >
            {item.brand_name}
          </WishlinkTypography>
          {item.is_returned_order && (
            <WishlinkHelper
              placement="top"
              disableTouchListener={true}
              disableFocusListener={true}
              title={`Ordered on: ${getFormattedDate(item.parent_sale_date)}`}
              open={item.is_returned_order && showTooltip}
            >
              <img
                onMouseOver={() => {
                  setShowTooltip(true);
                }}
                onMouseOut={() => setShowTooltip(false)}
                src={`${IMAGE_PREFIX}info.svg`}
                className="ml-3"
                alt=""
              />
            </WishlinkHelper>
          )}
        </div>
        <WishlinkTypography
          fontSize="0.875rem"
          fontWeight={500}
          color={item.is_returned_order ? "#E91E63" : "#333333"}
          className="text-right pt-1"
        >
          {item.is_returned_order && "-"} ₹ {item.commission}
        </WishlinkTypography>
      </div>
      <div
        className="grid mt-3"
        style={{ gridTemplateColumns: "0.4fr 1fr 1fr 0.5fr" }}
      >
        <div></div>
        <WishlinkTypography
          fontSize="0.625rem"
          fontWeight={400}
          color={"#666666"}
        >
          Order Id:
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.625rem"
          fontWeight={400}
          color={"#666666"}
        >
          {item.is_returned_order ? "Cancelled Amount:" : "Ordered Amount:"}
        </WishlinkTypography>
        <div></div>
        <div></div>
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={500}
          color={"#333333"}
        >
          {item.order_id}
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={500}
          color={"#333333"}
        >
          ₹ {item.amount}
        </WishlinkTypography>
      </div>
    </>
  );
};

export default OrderEntry;
