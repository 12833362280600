import { useNavigate } from "react-router-dom";

import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { signOutFirebaseAuth } from "../../utils/constants";
import { PAGE_URLS } from "../../utils/constants";
import { triggerEvent } from "../../utils/honasa-events";

const LogoutDrawer = (props) => {
  const navigate = useNavigate();

  const { drawerState, setDrawerState, creatorData } = props;

  function onClickLogout() {
    triggerEvent({
      eventName: "logout_confirm_cta_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Home",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "logout_confirm",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    localStorage.clear();
    signOutFirebaseAuth();
    setDrawerState(false);
    navigate(PAGE_URLS.LOGIN, { replace: true });
    window.location.reload();
  }

  return (
    <>
      <WishlinkDrawer
        drawerOpen={drawerState}
        onClose={() => navigate("", { replace: true })}
        onOpen={() => {}}
      >
        <div className="p-5 text-center mb-3">
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={"#141414"}
            lineHeight="1.5rem"
            className="pt-3"
          >
            Log out of Wishlink?
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.875rem"
            fontWeight={400}
            color={"#666666"}
            lineHeight="1.312rem"
            className="p-2 pt-1"
          >
            Stay logged in and be informed about your shop. Are you sure you
            want to log out?
          </WishlinkTypography>
          <WishlinkButton
            border={`0.06rem solid #e91e53`}
            borderRadius="0.5rem"
            width="100%"
            height="3rem"
            className="pt-3"
            onClick={() => onClickLogout()}
          >
            <WishlinkTypography
              fontSize="1rem"
              fontWeight={600}
              color={"#e91e53"}
              lineHeight="1.312rem"
            >
              Yes, log out
            </WishlinkTypography>
          </WishlinkButton>
          <WishlinkButton
            background={"#e91e53"}
            style={{
              ":hover": {
                bgcolor: "#e91e53",
              },
              ":disabled": {
                bgcolor: "#c2c2c2",
              },
            }}
            borderRadius="0.5rem"
            width="100%"
            height="3rem"
            className="pt-3"
            buttonClassName="bs-2"
            onClick={() => {
              navigate("", { replace: true });
              triggerEvent({
                eventName: "logout_denied_cta_click",
                eventAttributes: {
                  page_url: window.location.href,
                  page_name: "Home",
                  user_id: creatorData?.data?.id,
                  timestamp: new Date().toISOString(),
                  event_type: "click",
                  product_name: "",
                  product_price: "",
                  element: "logout_denied",
                  gender: creatorData?.data?.gender,
                  added_on: creatorData?.data?.added_on,
                },
              });
            }}
          >
            <WishlinkTypography
              fontSize="1rem"
              fontWeight={600}
              color={"#fff"}
              lineHeight="1.312rem"
            >
              No, cancel
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default LogoutDrawer;
