import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { useFetchResellerReferrerDetails } from "../../apiLayer/Referral/Referral.services";
import { useEffect } from "react";

const ReferrerDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data: referrerDetails, refetch: refetchReferrerDetails } =
    useFetchResellerReferrerDetails({
      enabled: false,
      referee: localStorage.getItem("referee"),
    });
  const { profile_pic, name } = referrerDetails?.data || {};

  useEffect(() => {
    if (localStorage.getItem("referee")) {
      refetchReferrerDetails();
    }
  }, []);

  return (
    <>
      <div className="flex align-center justify-start mb-3 pl-5 pr-5">
        <img
          src={profile_pic ? profile_pic : ""}
          style={{ width: "2.5rem", height: "2.5rem", borderRadius: "2.5rem" }}
          className="mr-2"
          alt=""
        />
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={500}
          color={colors.grey[0]}
          className="text-left"
        >
          Congratulations! <b>{name ? name : ""}</b> has invited you to join
          Wishlink community :)
        </WishlinkTypography>
      </div>
    </>
  );
};

export default ReferrerDetails;
