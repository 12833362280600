import React from "react";
import PhoneInput from "react-phone-number-input";
import "./PhoneInput.css";

const WishlinkPhoneInput = React.memo((props) => {
  const {
    setCountryCode,
    countryCode,
    international = true,
    countryCallingCodeEditable = false,
    defaultCountry = "IN",
    smartCaret = false,
    width,
    height,
  } = props;

  return (
    <PhoneInput
      international={international}
      countryCallingCodeEditable={countryCallingCodeEditable}
      defaultCountry={defaultCountry}
      placeholder="Enter phone numbers"
      value={countryCode}
      smartCaret={smartCaret}
      width={width}
      height={height}
      onChange={(value) => {
        setCountryCode(value);
      }}
      className="phone-input"
    />
  );
});

export default WishlinkPhoneInput;
