import { Divider } from "@mui/material";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX } from "../../../../utils/constants";
import ValueSkeleton from "../../../../WishlinkComponents/ValueSkeleton";

const PayoutThisMonth = (props) => {
  const {
    tds_formatted,
    rewards_formatted,
    referral_formatted,
    confirmed_commissions_formatted,
    total_payout_this_month_formatted,
    payoutsDataLoading,
  } = props;
  return (
    <div
      className="m-4 border-radius bs-1"
      style={{
        background: "#FFFFFF",
      }}
    >
      <div className="flex justify-between pl-3 pt-3">
        <WishlinkTypography fontSize="0.625rem" color={"#6E717D"}>
          Earnings this payout
        </WishlinkTypography>
        <img
          className="pr-3"
          src={`${IMAGE_PREFIX}honasa-payout-final.png`}
          style={{ width: "4rem" }}
          alt=""
        />
      </div>
      <div className="flex align-center justify-left pl-4 mb-2">
        {!payoutsDataLoading && "₹"}
        <WishlinkTypography
          fontWeight={700}
          fontSize="1.25rem"
          color={"#000"}
          className="text-left"
        >
          <ValueSkeleton
            isLoading={payoutsDataLoading}
            value={total_payout_this_month_formatted}
          />
        </WishlinkTypography>
      </div>
      <div className="flex justify-between align-center pl-4 pr-4">
        <WishlinkTypography fontSize="0.625rem" color={"#6E717D"}>
          Confirmed Commissions
        </WishlinkTypography>
        <div className="flex align-center justify-left pl-4">
          {!payoutsDataLoading && "₹"}
          <WishlinkTypography
            fontWeight={600}
            fontSize="0.875rem"
            color={"#000"}
            className="text-left"
          >
            <ValueSkeleton
              isLoading={payoutsDataLoading}
              value={confirmed_commissions_formatted}
            />
          </WishlinkTypography>
        </div>
      </div>
      <Divider />
      <div className="flex justify-between align-center pl-4 pr-4">
        <WishlinkTypography fontSize="0.625rem" color={"#6E717D"}>
          Rewards
        </WishlinkTypography>
        <div className="flex align-center justify-left pl-4">
          {!payoutsDataLoading && "₹"}
          <WishlinkTypography
            fontWeight={600}
            fontSize="0.875rem"
            color={"#000"}
            className="text-left"
          >
            <ValueSkeleton
              isLoading={payoutsDataLoading}
              value={rewards_formatted}
            />
          </WishlinkTypography>
        </div>
      </div>
      <Divider />
      <div className="flex justify-between align-center pl-4 pr-4">
        <WishlinkTypography fontSize="0.625rem" color={"#6E717D"}>
          Referral Bonus
        </WishlinkTypography>
        <div className="flex align-center justify-left pl-4">
          {!payoutsDataLoading && "₹"}
          <WishlinkTypography
            fontWeight={600}
            fontSize="0.875rem"
            color={"#000"}
            className="text-left"
          >
            <ValueSkeleton
              isLoading={payoutsDataLoading}
              value={referral_formatted}
            />
          </WishlinkTypography>
        </div>
      </div>
      <Divider />
      <div className="flex justify-between align-center pl-4 pr-4">
        <WishlinkTypography fontSize="0.625rem" color={"#6E717D"}>
          TDS (@10%)
        </WishlinkTypography>
        <div className="flex align-center justify-left pl-4">
          {!payoutsDataLoading && "₹"}
          <WishlinkTypography
            fontWeight={600}
            fontSize="0.875rem"
            color={"#000"}
            className="text-left"
          >
            <ValueSkeleton
              isLoading={payoutsDataLoading}
              value={tds_formatted}
            />
          </WishlinkTypography>
        </div>
      </div>
      <div
        style={{
          background: "rgba(122, 84, 240, 0.5)",
          borderBottomLeftRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
        }}
        className="pt-2 pb-2"
      >
        <WishlinkTypography fontSize="0.675rem" className="text-left pl-4">
          Note : This will be processed by 7th-10th May, 2024
        </WishlinkTypography>
      </div>
    </div>
  );
};

export default PayoutThisMonth;
