import { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

const WishlinkAccordion = (props) => {
  const {
    title,
    background = "",
    expandIcon,
    syncIsAccordionOpen,
    expanded,
    onChange,
  } = props;

  useEffect(() => {
    if (!!syncIsAccordionOpen) {
      syncIsAccordionOpen(expanded === "panel1");
    }
  }, [expanded]);

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      elevation={0}
      sx={{
        "& .MuiCollapse-root": { background: background },
        "&:before": {
          display: "none",
        },
      }}
      disableGutters={true}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        expandIcon={expandIcon}
        sx={{ padding: 0, background, paddingRight: "0.625rem" }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          background: background,
        }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};

export default WishlinkAccordion;
