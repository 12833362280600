import { IMAGE_PREFIX } from "../../../../utils/constants";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import ValueSkeleton from "../../../../WishlinkComponents/ValueSkeleton";

export const LifetimeEarnings = (props) => {
  const { lifetime_payout_formatted, payoutsDataLoading } = props;

  return (
    <div className="p-3">
      <div
        style={{
          background: `linear-gradient(90.31deg, #E38125 0.25%, rgba(227, 129, 37, 0.6) 113.83%)`,
          boxShadow: `0px 1px 12px rgba(0, 0, 0, 0.12)`,
        }}
        className="border-radius p-3 flex align-center justify-between"
      >
        <div className="flex align-center">
          <img
            src={`${IMAGE_PREFIX}lifetime-payout-gift.svg`}
            style={{ height: "100%" }}
            alt=""
          />
          <div className="ml-3">
            <div className="flex align-center">
              <WishlinkTypography
                color={"#fff"}
                fontSize="0.875rem"
                fontWeight={500}
                lineHeight=""
              >
                Lifetime payouts
              </WishlinkTypography>
            </div>
            <WishlinkTypography
              color={"#fff"}
              fontSize="0.687rem"
              fontWeight={400}
              className="text-left mt-1"
            >
              excluding TDS
            </WishlinkTypography>
          </div>
        </div>
        <div className="flex align-center justify-left">
          {!payoutsDataLoading && (
            <img src={`${IMAGE_PREFIX}rupee.svg`} alt="" />
          )}
          <WishlinkTypography
            fontSize="1.25rem"
            fontWeight={700}
            color={"#fff"}
            lineHeight="1.125rem"
          >
            <ValueSkeleton
              isLoading={payoutsDataLoading}
              value={lifetime_payout_formatted}
            />
          </WishlinkTypography>
        </div>
      </div>
    </div>
  );
};
