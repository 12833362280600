import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import LogoutDrawer from "../LogoutDrawer";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import { hamburgerItems } from "../../utils/constants";
import ImageSkeleton from "../../WishlinkComponents/ImageSkeleton";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import { triggerEvent } from "../../utils/honasa-events";

const Hamburger = (props) => {
  const { creatorData, creatorDataIsLoading } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerState, setDrawerState] = useState(false);
  const [logoutDrawerState, setLogoutDrawerState] = useState(false);
  const { name = "", profile_pic_url = "" } = creatorData?.data || {};

  useEffect(() => {
    if (location.hash === "#hamburger") {
      setDrawerState(true);
      setLogoutDrawerState(false);
    } else if (location.hash === "#logout") {
      setDrawerState(false);
      setLogoutDrawerState(true);
    } else {
      setDrawerState(false);
      setLogoutDrawerState(false);
    }
  }, [location]);

  function onClickLink(item) {
    navigate(`${item.redirectUrl}`, { replace: true });
    // dispatch(toggleHamburger(false));
    triggerEvent({
      eventName: "hamburger_link_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Home",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: item.redirectUrl,
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  }

  return (
    <WishlinkDrawer
      drawerOpen={drawerState}
      onClose={() => navigate(-1, { replace: true })}
      onOpen={() => {}}
      direction="right"
      anchor="left"
      className="m-4"
      widthFactor={80}
      borderRadius="0rem 3rem 3rem 0rem"
    >
      <div
        className="absolute"
        style={{
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "85%",
          background: "#fff",
          padding: "1.5rem",
          zIndex: 4,
        }}
      >
        <div className="text-center">
          <ImageSkeleton
            className="ml-5"
            isLoading={creatorDataIsLoading}
            imageUrl={profile_pic_url}
            style={{
              border: `0.125rem solid #fff`,
              borderRadius: "50%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
              width: "5.625rem",
              height: "5.625rem",
              margin: "auto",
            }}
            alt=""
          />
        </div>
        <WishlinkTypography
          fontSize="1.5rem"
          fontWeight={700}
          color={"#333333"}
          className="mt-2 text-center m-auto"
        >
          <ValueSkeleton
            isLoading={creatorDataIsLoading}
            className="m-auto"
            value={name}
          />
        </WishlinkTypography>
        {hamburgerItems?.map((item, idx) => {
          return (
            <div key={idx}>
              <div
                className="flex justify-between"
                onClick={() => onClickLink(item)}
              >
                <div className="pt-5 pb-5 text-left">
                  <div className="flex align-center">
                    <WishlinkTypography
                      fontWeight={500}
                      fontSize="0.875rem"
                      color={"#333333"}
                      className="mr-1 white-nowrap"
                    >
                      {item.title}
                    </WishlinkTypography>
                    {item.showNewIcon && (
                      <img
                        src={`${IMAGE_PREFIX}new-label-icon.gif`}
                        style={{ width: "20%", marginTop: "-0.1rem" }}
                        alt=""
                      />
                    )}
                  </div>
                  <WishlinkTypography
                    fontWeight={400}
                    fontSize="0.75rem"
                    color={"#666666"}
                  >
                    {item.description}
                  </WishlinkTypography>
                </div>
                <img
                  src={`${IMAGE_PREFIX}rightIconGrey.svg`}
                  className="mr-2"
                  alt=""
                />
              </div>
              <Divider />
            </div>
          );
        })}
        <div
          className="flex align-center justify-between pt-4 text-left"
          onClick={() => navigate("#logout", { replace: true })}
        >
          <WishlinkTypography
            fontWeight={500}
            fontSize="0.875rem"
            color={"#333333"}
          >
            Logout
          </WishlinkTypography>
          <img src={`${IMAGE_PREFIX}logout.svg`} className="mr-2" alt="" />
        </div>
        <LogoutDrawer
          drawerState={logoutDrawerState}
          setDrawerState={setDrawerState}
          creatorData={creatorData}
        />
      </div>
    </WishlinkDrawer>
  );
};

export default Hamburger;
