import { TextField } from "@mui/material";

import { IMAGE_PREFIX } from "../../utils/constants";

const getAdornments = (showIcon, disabled, endAdornment, startAdornment) => {
  let adornments = {};
  if (showIcon && !disabled) {
    adornments = {
      ...adornments,
      endAdornment: endAdornment,
    };
  }
  if (startAdornment) {
    adornments = {
      ...adornments,
      startAdornment: startAdornment,
    };
  }
  return adornments;
};

const WishlinkTextField = (props) => {
  const {
    placeholder,
    fullWidth,
    onInputValueChange,
    rows = 1,
    multiline = false,
    helperText = "",
    type = "text",
    error = false,
    value = "",
    disabled = false,
    autoFocus = false,
    showIcon,
    icon = "close2.svg",
    onClickIcon,
    startAdornment = null,
    variant,
    height,
    width,
    size,
    border = "#666666",
    style,
    borderRadius = "0.5rem",
    onFocus,
    onBlur = () => {},
  } = props;
  const endAdornment = (
    <img onClick={onClickIcon} src={`${IMAGE_PREFIX}${icon}`} alt="" />
  );
  return (
    <>
      <TextField
        variant={variant}
        value={value}
        fullWidth={fullWidth}
        placeholder={placeholder}
        onChange={(event) => onInputValueChange(event.target.value)}
        multiline={multiline}
        rows={rows}
        type={type}
        error={error}
        helperText={error ? helperText : ""}
        disabled={disabled}
        autoFocus={autoFocus}
        size={size}
        onFocus={onFocus}
        onBlur={onBlur}
        sx={{
          "& input::placeholder": {
            fontSize: "0.875rem",
          },
          "& textarea::placeholder": {
            fontSize: "0.875rem",
          },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderRadius,
              background: error ? "rgba(233, 30, 99, 0.08)" : "",
            },
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: border,
              borderRadius,
            },
          },
          ...style,
          height,
          width,
        }}
        InputProps={getAdornments(
          showIcon,
          disabled,
          endAdornment,
          startAdornment,
        )}
      />
    </>
  );
};

export default WishlinkTextField;
