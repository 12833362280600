import Checkbox from "@mui/material/Checkbox";

const WishlinkCheckbox = (props) => {
  const {
    label,
    color,
    borderColor,
    checked = false,
    onToggleCheckbox,
    size = "small",
  } = props;
  return (
    <>
      <Checkbox
        checked={checked}
        label={label}
        onChange={() => onToggleCheckbox(!checked)}
        size={size}
        sx={{
          color: borderColor,
          "&.Mui-checked": {
            color,
          },
        }}
      />
    </>
  );
};

export default WishlinkCheckbox;
