import { useEffect, useState } from "react";
import WishlinkTextField from "../../WishlinkComponents/WishlinkTextField";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { useUpdateCreateResellerData } from "../../apiLayer/Welcome";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { useNavigate } from "react-router-dom";
import { EMAIL_REGEX, PAGE_URLS, USERNAME_REGEX } from "../../utils/constants";

const SignupForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [referee, setReferee] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const { mutate: createResellerMutation } = useUpdateCreateResellerData({
    onSuccess: () => {
      navigate(PAGE_URLS.HOME);
      localStorage.setItem("fireLoginEvent", true);
    },
  });

  useEffect(() => {
    if (localStorage.getItem("referee")) {
      setReferee(localStorage.getItem("referee"));
    }
  }, []);

  const onClickSubmitDetails = () => {
    const body = {
      name,
      email,
      referee,
    };
    setBtnLoading(true);
    createResellerMutation(body);
  };

  return (
    <div
      style={{ minHeight: window.innerHeight }}
      className="flex flex-column justify-between"
    >
      <div>
        <div className="p-4">
          <WishlinkTypography
            fontWeight={600}
            fontSize="1.375rem"
            lineHeight="2rem"
            textAlign="left"
            className="pt-5"
            color={"#000"}
          >
            Enter your Details
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.875rem"
            lineHeight="1.25rem"
            textAlign="left"
            color={"#000"}
            className="pt-3 pb-5"
          >
            We will use this to set up your basic profile
          </WishlinkTypography>
        </div>
        <div className="pl-4 pr-4 mb-6">
          <WishlinkTextField
            value={name}
            helperText="*Please enter a valid name"
            error={!USERNAME_REGEX.test(name)}
            fullWidth={true}
            borderRadius="0.5rem"
            placeholder="Full Name"
            onInputValueChange={(val) => setName(val)}
          />
        </div>
        <div className="pl-4 pr-4 mb-6">
          <WishlinkTextField
            value={email}
            helperText="*Please enter a valid email"
            error={!EMAIL_REGEX.test(email)}
            fullWidth={true}
            borderRadius="0.5rem"
            placeholder="Email Address"
            onInputValueChange={(val) => setEmail(val)}
          />
        </div>
        <div className="pl-4 pr-4">
          <WishlinkTypography
            className="text-left pl-1 pt-1 pb-2"
            color={"#000"}
            fontSize="0.875rem"
          >
            Do you have a referral code?
          </WishlinkTypography>
          <WishlinkTextField
            value={referee}
            helperText="*Please enter a valid referee"
            error={!USERNAME_REGEX.test(referee) && referee.length > 0}
            fullWidth={true}
            borderRadius="0.5rem"
            placeholder="Referral Code"
            onInputValueChange={(val) => setReferee(val)}
          />
        </div>
      </div>
      <WishlinkButton
        background={"#E91E63"}
        style={{
          ":hover": {
            bgcolor: "#E91E63",
          },
          ":disabled": {
            bgcolor: "#c2c2c2",
          },
        }}
        borderRadius="0.5rem"
        width="100%"
        height="3rem"
        className="p-4"
        onClick={() => onClickSubmitDetails()}
        disabled={name?.length <= 3 || !EMAIL_REGEX.test(email) || btnLoading}
      >
        <WishlinkTypography
          color={"#f5f5f5"}
          fontWeight={600}
          fontSize="1rem"
          className="p-1"
        >
          Yes, Proceed
        </WishlinkTypography>
      </WishlinkButton>
    </div>
  );
};

export default SignupForm;
