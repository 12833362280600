import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../theme";
import {
  copyToClipboard,
  copyToClipboardAndOpenShareDrawer,
} from "../../../../utils/common";
import {
  IMAGE_PREFIX,
  STOREFRONT_HOST_NAME,
} from "../../../../utils/constants";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import {
  triggerEvent,
  triggerShareEvent,
} from "../../../../utils/honasa-events";
import { showToast } from "../../../../features/Notification";
import { useDispatch } from "react-redux";

export const ReferralHeader = (props) => {
  const { referral_code, creatorData } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const copyReferalLink = () => {
    triggerEvent({
      eventName: "copy_code_header_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Referral",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "copy_code_referral_header",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    copyToClipboard(`${STOREFRONT_HOST_NAME}${referral_code}`)
      .then(() => {
        dispatch(
          showToast({ variant: "success", message: "Referral Link Copied" }),
        );
      })
      .catch((err) => {
        dispatch(
          showToast({
            variant: "error",
            message: "Failed to copy Referral Link",
          }),
        );
      });
  };

  const copyAndShareReferalLink = () => {
    triggerEvent({
      eventName: "share_code_header_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Referral",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "share_code_referral_header",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    triggerShareEvent({
      url: `${STOREFRONT_HOST_NAME}/${referral_code}`,
    });
    copyToClipboardAndOpenShareDrawer(
      `${STOREFRONT_HOST_NAME}/${referral_code}`,
    )
      .then(() => {
        dispatch(
          showToast({ variant: "success", message: "Referral Link Copied" }),
        );
      })
      .catch((err) => {
        dispatch(
          showToast({
            variant: "error",
            message: "Failed to copy Referral Link",
          }),
        );
      });
  };

  const onClickBackButton = () => {
    navigate(-1);
    triggerEvent({
      eventName: "back_btn_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Referral",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "back_button_click",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  };

  return (
    <div
      className="sticky flex justify-between p-4"
      style={{ top: 0, background: colors.misc[0], zIndex: 2 }}
    >
      <div className="flex white-nowrap">
        <img
          className="mr-3"
          onClick={() => onClickBackButton()}
          src={`${IMAGE_PREFIX}arrow-left-white.svg`}
          alt=""
        />
        <div>
          <WishlinkTypography
            textAlign="left"
            fontSize="0.8125rem"
            lineHeight="1.21rem"
            style={{ width: "6rem" }}
          >
            Your Referral code
          </WishlinkTypography>
          <WishlinkTypography
            textAlign="left"
            fontWeight={500}
            lineHeight="1.3125rem"
          >
            {referral_code}
          </WishlinkTypography>
        </div>
      </div>
      <div>
        <img
          src={`${IMAGE_PREFIX}copy-clipboard-white.svg`}
          onClick={() => copyReferalLink()}
          className="mr-1"
          alt=""
        />
        <img
          src={`${IMAGE_PREFIX}share-white.svg`}
          onClick={() => copyAndShareReferalLink()}
          className="ml-1"
          alt=""
        />
      </div>
    </div>
  );
};
