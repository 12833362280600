import { useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../utils/firebase";

import { LOGIN_DRAWER_STAGES } from "../../utils/constants";
import PhoneInputComponent from "../../components/Welcome/PhoneInputComponent";
import OtpInputComponent from "../../components/Welcome/OtpInputComponent";

const Welcome = () => {
  const [drawerStage, setDrawerStage] = useState(
    LOGIN_DRAWER_STAGES.ENTER_NUMBER,
  );
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sendingOtp, setSendingOtp] = useState(false);
  const [otpResult, setOtpResult] = useState(null);

  const genRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: () => {
          window?.grecaptcha?.reset();
        },
      },
      auth,
    );
  };

  const handleSendOtp = async (countryCode, phoneNumber, resending) => {
    setCountryCode(countryCode);
    setPhoneNumber(phoneNumber);
    let otpResponse = null;
    setSendingOtp(true);
    try {
      if (!window.recaptchaVerifier) {
        genRecaptcha();
      }
      let appVerifier = window.recaptchaVerifier;
      otpResponse = await signInWithPhoneNumber(
        auth,
        countryCode + phoneNumber,
        appVerifier,
      );
    } catch (e) {
      // console.error(e);
    }
    setOtpResult(otpResponse);
    setSendingOtp(false);
  };

  return (
    <div className="overflow-y-hidden">
      <div id="recaptcha-container" style={{ display: "none" }}></div>
      {drawerStage === LOGIN_DRAWER_STAGES.ENTER_NUMBER && (
        <PhoneInputComponent
          handleSendOtp={handleSendOtp}
          phoneNumber={phoneNumber}
          countryCode={countryCode}
          sendingOtp={sendingOtp}
          setPhoneNumber={setPhoneNumber}
          setCountryCode={setCountryCode}
          setSendingOtp={setSendingOtp}
          setDrawerStage={setDrawerStage}
        />
      )}
      {drawerStage === LOGIN_DRAWER_STAGES.ENTER_OTP && (
        <OtpInputComponent
          sendOtp={handleSendOtp}
          otpResult={otpResult}
          phoneNumber={phoneNumber}
          countryCode={countryCode}
          sendingOtp={sendingOtp}
          drawerStage={drawerStage}
        />
      )}
    </div>
  );
};

export default Welcome;
