import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkTextField from "../../WishlinkComponents/WishlinkTextField";
import WishlinkCheckbox from "../../WishlinkComponents/WishlinkCheckbox";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import PillGroup from "../../WishlinkComponents/WishlinkCalendar/PillGroup";
import {
  CITY_REGEX,
  IMAGE_PREFIX,
  PAGE_URLS,
  PHONE_REGEX,
  PINCODE_REGEX,
  USERNAME_REGEX,
} from "../../utils/constants";
import {
  useFetchCreatorAddressByIdData,
  useFetchCreatorAddressData,
  useUpdateCreateCreatorAddressData,
  useUpdateCreatorAddressData,
} from "../../apiLayer/Profile";
import Spinner from "../../WishlinkComponents/Spinner";
import { triggerEvent } from "../../utils/honasa-events";
import { useFetchCreatorData } from "../../apiLayer/Home";
import { useDispatch } from "react-redux";
import { showToast } from "../../features/Notification";

const Address = () => {
  const queryClient = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();
  const { data: creatorAddress } = useFetchCreatorAddressData({
    enabled: true,
  });
  const {
    data: creatorAddressById,
    isLoading: creatorAddressByIdLoading,
    refetch: getCreatorAddressById,
  } = useFetchCreatorAddressByIdData({ addressId: params.id, enabled: false });
  const { data: creatorData } = useFetchCreatorData({ enabled: true });
  const dispatch = useDispatch();

  const pills = [
    {
      id: 1,
      name: "Home",
      label: "home",
      collapsible: false,
      clickable: !creatorAddress?.data?.find(
        (address) => address.tag === "Home",
      ),
    },
    {
      id: 2,
      name: "Office",
      label: "office",
      collapsible: false,
      clickable: !creatorAddress?.data?.find(
        (address) => address.tag === "Office",
      ),
    },
    {
      id: 3,
      name: "Other",
      label: "other",
      collapsible: false,
      clickable: true,
    },
  ];

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [addressLocality, setLocality] = useState("");
  const [addressPincode, setPincode] = useState("");
  const [addressCity, setCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressTag, setTag] = useState("");
  const [addressDefault, setDefault] = useState(true);
  const [selectedPill, setSelectedPill] = useState([pills[2]]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [isPincodeUpdated, setIsPincodeUpdated] = useState(false);

  useEffect(() => {
    if (params.id !== "new") {
      getCreatorAddressById(params.id);
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      name?.length > 0 &&
      phoneNumber?.length === 10 &&
      phoneNumber > 0 &&
      phoneNumber < 10000000000 &&
      addressLine?.length > 0 &&
      addressLocality?.length > 0 &&
      /^[0-9]+$/.test(addressPincode) &&
      addressPincode > 100000 &&
      addressPincode < 999999 &&
      addressCity?.length > 0 &&
      !CITY_REGEX.test(addressCity) &&
      addressState?.length > 0 &&
      addressTag.length > 0
    )
      setBtnDisable(false);
    else setBtnDisable(true);
  }, [
    name,
    phoneNumber,
    addressLine,
    addressLocality,
    addressPincode,
    addressCity,
    addressState,
    addressTag,
  ]);

  useEffect(() => {
    if (
      creatorAddressById?.data &&
      Object.keys(creatorAddressById?.data).length !== 0 &&
      params.id !== "new"
    ) {
      setAddressLine(creatorAddressById?.data?.address_line);
      setLocality(creatorAddressById?.data?.locality);
      setPincode(creatorAddressById?.data?.pincode);
      setCity(creatorAddressById?.data?.city);
      setAddressState(creatorAddressById?.data?.state);
      setTag(creatorAddressById?.data?.tag);
      setName(creatorAddressById?.data?.name);
      setPhoneNumber(creatorAddressById?.data?.phone_number);
      if (creatorAddressById?.data?.tag === "Home") setSelectedPill([pills[0]]);
      else if (creatorAddressById?.data?.tag === "Office")
        setSelectedPill([pills[1]]);
      else setSelectedPill([pills[2]]);
      setDefault(creatorAddressById?.data?.is_default);
    } else if (params.id === "new") {
      setName(creatorData?.data?.name);
      setPhoneNumber(localStorage.getItem("phoneNumber"));
    }
  }, [creatorAddressById, creatorData]);

  useEffect(() => {
    if (
      /^[0-9]+$/.test(addressPincode) &&
      addressPincode > 100000 &&
      addressPincode < 999999
    ) {
      if (params.id === "new") getDetailsFromPincode();
      else if (params.id !== "new" && isPincodeUpdated) getDetailsFromPincode();
    }
  }, [addressPincode]);

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "address_pl",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Address",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "page_load",
          product_name: "",
          product_price: "",
          element: "",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  const getDetailsFromPincode = async () => {
    await fetch(`https://api.postalpincode.in/pincode/${addressPincode}`)
      .then((data) => data.json())
      .then((res) => {
        setCity(res?.[0]?.PostOffice?.[0]?.District);
        setAddressState(res?.[0]?.PostOffice?.[0]?.State);
      });
  };

  const onClickSelectPill = (pill) => {
    setSelectedPill([pill]);
    if (pill.name !== "Other") {
      setTag(pill.name);
    } else setTag("");
  };

  const { mutate } = useUpdateCreatorAddressData({
    onSuccess: () => {
      dispatch(
        showToast({ variant: "success", message: "Updated Reseller Address" }),
      );
    },
  });
  const { mutate: createAddressMutation } = useUpdateCreateCreatorAddressData({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["creatorAddress"] });
      if (params.id === "new") navigate(PAGE_URLS.PROFILE);
    },
  });

  const onClickSaveAddress = async () => {
    let body = {
      name: name,
      phone_number: phoneNumber,
      address_line: addressLine,
      locality: addressLocality,
      pincode: addressPincode,
      city: addressCity,
      state: addressState,
      tag: addressTag,
      is_default: !creatorAddress?.data?.length || addressDefault,
    };
    if (params.id === "new") {
      createAddressMutation(body);
    } else {
      body.address_id = params.id;
      mutate(body);
    }
    triggerEvent({
      eventName: "save_address_cta_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Address",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "save_new_address",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  };

  const onInputValueChange = (val) => {
    setPincode(val);
    setIsPincodeUpdated(true);
  };

  return (
    <div className="p-4" style={{ minHeight: window.innerHeight }}>
      <div className="text-left">
        <img
          src={`${IMAGE_PREFIX}leftIcon.svg`}
          onClick={() => navigate(-1, { replace: true })}
          alt=""
        />
      </div>
      <WishlinkTypography
        color={"#333333"}
        fontSize="1rem"
        fontWeight={500}
        lineHeight="1rem"
        className="text-left mt-5"
      >
        {params.id === "new" ? "Add" : "Update"} Address
      </WishlinkTypography>
      <WishlinkTypography
        color={"#666666"}
        fontSize="0.8rem"
        fontWeight={400}
        lineHeight="1rem"
        className="text-left mt-2"
      >
        <span style={{ fontWeight: "bold", color: "#e28025" }}>
          Please ensure you enter the correct delivery address here.
          <br />
        </span>
        A detailed address will help our delivery partner to check your location
        easily.
      </WishlinkTypography>
      {creatorAddressByIdLoading && <Spinner />}
      {!creatorAddressByIdLoading && (
        <>
          <WishlinkTypography
            color={"#333333"}
            fontSize="0.875rem"
            fontWeight={500}
            lineHeight="1.3125rem"
            className="text-left mt-4"
          >
            Name
          </WishlinkTypography>
          <WishlinkTextField
            value={name}
            helperText="*Please enter a valid name"
            error={!USERNAME_REGEX.test(name)}
            fullWidth={true}
            borderRadius="0.25rem"
            style={{ marginTop: "0.2rem" }}
            placeholder="Enter Name"
            onInputValueChange={(val) => setName(val)}
          />
          <WishlinkTypography
            color={"#333333"}
            fontSize="0.875rem"
            fontWeight={500}
            lineHeight="1.3125rem"
            className="text-left mt-4"
          >
            Mobile Number
          </WishlinkTypography>
          <div
            className="flex align-center mt-1"
            style={{
              borderRadius: "0.25rem",
              border: `1px solid #c4c4c4`,
            }}
          >
            <WishlinkTypography
              className="p-3"
              color={"#333333"}
              fontSize="0.9rem"
              fontWeight={600}
            >
              +91
            </WishlinkTypography>
            <div
              style={{
                height: "3.5rem",
                width: "1px",
                backgroundColor: "#c4c4c4",
              }}
            />
            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "1rem",
                  marginLeft: "0.75rem",
                },
              }}
              value={phoneNumber}
              error={false}
              fullWidth={true}
              placeholder="Enter Mobile Number"
              onChange={(val) => setPhoneNumber(val.target.value)}
            />
          </div>
          {!PHONE_REGEX.test(phoneNumber) ? (
            <>
              <WishlinkTypography
                color={"#E91E63"}
                fontSize="0.625rem"
                fontWeight={400}
                lineHeight="1.3125rem"
                className="text-left"
              >
                Please enter a valid phone number
              </WishlinkTypography>
            </>
          ) : (
            <></>
          )}
          <WishlinkTypography
            color={"#333333"}
            fontSize="0.875rem"
            fontWeight={500}
            lineHeight="1.3125rem"
            className="text-left mt-4"
          >
            Address
          </WishlinkTypography>
          <WishlinkTextField
            value={addressLine}
            helperText="*Please enter a valid house no."
            error={false}
            fullWidth={true}
            borderRadius="0.25rem"
            style={{ marginTop: "0.2rem" }}
            placeholder="Enter House No. / Flat / Block No./ Street / Area"
            onInputValueChange={(val) => setAddressLine(val)}
          />
          <WishlinkTypography
            color={"#333333"}
            fontSize="0.875rem"
            fontWeight={500}
            lineHeight="1.3125rem"
            className="text-left mt-4"
          >
            Locality / Town
          </WishlinkTypography>
          <WishlinkTextField
            value={addressLocality}
            helperText="*Please enter a valid house no."
            error={false}
            fullWidth={true}
            borderRadius="0.25rem"
            style={{ marginTop: "0.2rem" }}
            placeholder="Enter locality"
            onInputValueChange={(val) => setLocality(val)}
          />
          <WishlinkTypography
            color={"#333333"}
            fontSize="0.875rem"
            fontWeight={500}
            lineHeight="1.3125rem"
            className="text-left mt-4"
          >
            Pincode
          </WishlinkTypography>
          <WishlinkTextField
            type="number"
            value={addressPincode}
            helperText="*Please enter a valid pincode."
            error={!PINCODE_REGEX.test(addressPincode)}
            fullWidth={true}
            borderRadius="0.25rem"
            style={{ marginTop: "0.2rem" }}
            placeholder="Enter pincode"
            onInputValueChange={(val) => onInputValueChange(val)}
          />
          <div className="flex justify-around">
            <div style={{ flex: 1 }} className="mr-1">
              <WishlinkTypography
                color={"#333333"}
                fontSize="0.875rem"
                fontWeight={500}
                lineHeight="1.3125rem"
                className="text-left mt-4"
              >
                City
              </WishlinkTypography>
              <WishlinkTextField
                value={addressCity}
                helperText="*Enter a valid city"
                error={CITY_REGEX.test(addressCity)}
                fullWidth={true}
                disabled={false}
                borderRadius="0.25rem"
                style={{ marginTop: "0.2rem" }}
                placeholder="Enter city"
                onInputValueChange={(val) => setCity(val)}
              />
            </div>
            <div style={{ flex: 1 }} className="ml-1">
              <WishlinkTypography
                color={"#333333"}
                fontSize="0.875rem"
                fontWeight={500}
                lineHeight="1.3125rem"
                className="text-left mt-4"
              >
                State
              </WishlinkTypography>
              <WishlinkTextField
                value={addressState}
                helperText="*Please enter a valid house no."
                error={false}
                disabled={false}
                fullWidth={true}
                borderRadius="0.25rem"
                style={{ marginTop: "0.2rem" }}
                placeholder="Enter state"
                onInputValueChange={(val) => setAddressState(val)}
              />
            </div>
          </div>
          <WishlinkTypography
            fontSize="0.875rem"
            fontWeight={500}
            lineHeight="1.5rem"
            className="text-left mt-4"
            color={"#333333"}
          >
            Save as
          </WishlinkTypography>
          <PillGroup
            pills={pills}
            selectedPill={selectedPill}
            onClickSelectPill={onClickSelectPill}
          />
          {selectedPill[0].name === "Other" && (
            <WishlinkTextField
              value={addressTag}
              helperText="*Please enter a valid house no."
              error={false}
              fullWidth={true}
              borderRadius="0.25rem"
              style={{ marginTop: "0.5rem" }}
              placeholder="Enter a name"
              onInputValueChange={(val) => setTag(val)}
            />
          )}
        </>
      )}
      <>
        {params.id === "new" && (
          <div className="flex justify-start align-center">
            <WishlinkCheckbox
              label="Make this my default address"
              borderColor={"#c4c4c4"}
              color={"#e91e63"}
              onToggleCheckbox={(checked) => setDefault(checked)}
              checked={addressDefault}
            />
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              lineHeight="1.5rem"
              color={"#666666"}
            >
              Make this my default address
            </WishlinkTypography>
          </div>
        )}
      </>
      <WishlinkButton
        background={"#e91e63"}
        style={{
          ":hover": {
            bgcolor: "#e91e63",
          },
          ":disabled": {
            bgcolor: "#c2c2c2",
          },
        }}
        borderRadius="0.5rem"
        width="100%"
        height="3rem"
        className="pt-3 mt-3 mb-3"
        disabled={btnDisable}
        onClick={() => onClickSaveAddress()}
      >
        <WishlinkTypography
          color={"#f5f5f5"}
          fontWeight={600}
          fontSize="1rem"
          className="p-1"
        >
          Save
        </WishlinkTypography>
      </WishlinkButton>
    </div>
  );
};

export default Address;
