import { makeAPICall } from "../../utils/common";
import { BASE_URL } from "../../utils/constants";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useFetchCreatorData = ({ enabled }) => {
  return useQuery({
    queryKey: ["creatorData"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/fetchResellerDetailsShop`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useFetchCreatorHighlightsData = ({ enabled }) => {
  return useQuery({
    queryKey: ["creatorHighlightsData"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/fetchOnboardingStories`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useUpdateCreatorStoryDetails = ({ onSuccess }) => {
  return useMutation({
    mutationFn: (body) => {
      return makeAPICall({
        url: `${BASE_URL}r/markStoryAsWatched`,
        method: "POST",
        body,
      });
    },
    onSuccess,
  });
};
