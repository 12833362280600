import { Tooltip } from "@mui/material";

const WishlinkHelper = (props) => {
  const {
    placement = "bottom",
    open = true,
    handleClose,
    title,
    disableFocusListener = false,
    disableTouchListener = false,
  } = props;
  return (
    <>
      <Tooltip
        open={open}
        onClose={handleClose}
        title={title}
        placement={placement}
        arrow
        disableFocusListener={disableFocusListener}
        disableTouchListener={disableTouchListener}
      >
        {props.children}
      </Tooltip>
    </>
  );
};

export default WishlinkHelper;
