import ValueSkeleton from "../../../../WishlinkComponents/ValueSkeleton";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX } from "../../../../utils/constants";

const ConfirmedCommissions = (props) => {
  const { confirmed_commissions_formatted, payoutsDataLoading } = props;
  return (
    <div className="m-4">
      <div
        className="p-3 bs-1"
        style={{
          background: "#F1FFFD",
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
        }}
      >
        <div className="flex justify-between">
          <WishlinkTypography fontSize="0.625rem" color={"#6E717D"}>
            Confirmed Commission
          </WishlinkTypography>
          <img
            src={`${IMAGE_PREFIX}confirmed-commission.png`}
            style={{ width: "5rem" }}
            alt=""
          />
        </div>
        <div className="flex align-center justify-left">
          {!payoutsDataLoading && "₹"}
          <WishlinkTypography
            fontWeight={700}
            fontSize="1.25rem"
            color={"#000"}
            className="text-left"
          >
            <ValueSkeleton
              isLoading={payoutsDataLoading}
              value={confirmed_commissions_formatted}
            />
          </WishlinkTypography>
        </div>
      </div>
      <div
        className="p-2"
        style={{
          background: "#D9E5E4",
          borderBottomLeftRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
        }}
      >
        <WishlinkTypography
          fontSize="0.625rem"
          color={"#424242"}
          className="text-left"
        >
          Commissions on products that were delivered
        </WishlinkTypography>
      </div>
    </div>
  );
};

export default ConfirmedCommissions;
