import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import {
  IMAGE_PREFIX,
  STOREFRONT_HOST_NAME,
} from "../../../../utils/constants";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import {
  copyToClipboard,
  copyToClipboardAndOpenShareDrawer,
} from "../../../../utils/common";
import {
  triggerEvent,
  triggerShareEvent,
} from "../../../../utils/honasa-events";
import { showToast } from "../../../../features/Notification";
import { useDispatch } from "react-redux";
import ValueSkeleton from "../../../../WishlinkComponents/ValueSkeleton";

export const ReferralCode = (props) => {
  const { referral_code, creatorData } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const copyReferalLink = () => {
    triggerEvent({
      eventName: "copy_code_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Referral",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "copy_code_referral",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    triggerShareEvent({
      url: `${STOREFRONT_HOST_NAME}/${referral_code}`,
    });
    copyToClipboard(`${STOREFRONT_HOST_NAME}${referral_code}`).then(() => {
      dispatch(
        showToast({ variant: "success", message: "Referral Link Copied" }),
      );
    });
  };

  const copyAndShareReferalLink = () => {
    triggerEvent({
      eventName: "share_code_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Referral",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "share_code_referral",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    copyToClipboardAndOpenShareDrawer(`${STOREFRONT_HOST_NAME}${referral_code}`)
      .then(() => {
        dispatch(
          showToast({ variant: "success", message: "Referral Link Copied" }),
        );
      })
      .catch((err) => {
        dispatch(
          showToast({
            variant: "error",
            message: "Failed to copy Referral Link",
          }),
        );
      });
  };

  const onClickShareWhatsapp = () => {
    triggerEvent({
      eventName: "share_code_whatsapp_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Referral",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "share_code_whatsapp",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    // const text = `${STOREFRONT_HOST_NAME}${creatorReferalData?.referral_code}?utm_medium=wa`;
    const text = `Heyyy! 👋

Want to know what's helping me in my content journey📈:  🧡WISHLINK🧡

🛍️ 250+ Big brands like H&M, Myntra etc for crazy commissions
✅ Features to boost Social Media handles
💰100% Content monetization
🫱🏻‍🫲🏼 Creator Meet & Greet Events

I am absolutely in love with Wishlink! 💥
Why not give it a shot yourself? 🤌

Sign up using my code ${referral_code}`;
    const whatsappUrl = "whatsapp://send?text=" + encodeURIComponent(text);
    window.open(whatsappUrl, "_blank", "noreferrer");
  };

  return (
    <div
      className="p-4 ml-4 mr-4 mt-4 mb-4 border-radius2"
      style={{
        background: `linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 100%)`,
      }}
    >
      <WishlinkTypography
        lineHeight="1.3125rem"
        className="mt-3"
        color={colors.grey[700]}
      >
        YOUR REFERRAL CODE
      </WishlinkTypography>
      <WishlinkTypography
        lineHeight="1.3125rem"
        fontWeight={600}
        color={colors.grey[700]}
        fontSize={
          referral_code?.length > 26
            ? `1rem`
            : referral_code?.length > 22
              ? `1.25rem`
              : `1.5rem`
        }
        className="mt-3"
      >
        <ValueSkeleton
          isLoading={!referral_code}
          className="m-auto"
          value={referral_code}
        />
      </WishlinkTypography>
      <div className="flex justify-around pt-4 mt-4">
        <div onClick={() => onClickShareWhatsapp()}>
          <img
            src={`${IMAGE_PREFIX}whatsapp-icon.svg`}
            style={{ width: "4rem", height: "4rem" }}
            alt=""
          />
          <WishlinkTypography
            fontSize="0.75rem"
            lineHeight="0.5rem"
            color={colors.grey[700]}
          >
            WhatsApp
          </WishlinkTypography>
        </div>
        <div onClick={() => copyReferalLink()}>
          <img
            src={`${IMAGE_PREFIX}copy-clipboard-white.svg`}
            style={{ width: "4rem", height: "4rem" }}
            alt=""
          />
          <WishlinkTypography
            fontSize="0.75rem"
            lineHeight="0.5rem"
            color={colors.grey[700]}
          >
            Copy Link
          </WishlinkTypography>
        </div>
        <div onClick={() => copyAndShareReferalLink()}>
          <img
            src={`${IMAGE_PREFIX}share-white.svg`}
            style={{ width: "4rem", height: "4rem" }}
            alt=""
          />
          <WishlinkTypography
            fontSize="0.75rem"
            lineHeight="0.5rem"
            color={colors.grey[700]}
          >
            Share
          </WishlinkTypography>
        </div>
      </div>
    </div>
  );
};

export default ReferralCode;
