import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { IMAGE_PREFIX } from "../../utils/constants";
import { getDateObjectFromDateString, formatDate } from "../../utils/common";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkDatePicker from "../../WishlinkComponents/WishlinkDatePicker";
import WishlinkModal from "../../WishlinkComponents/WishlinkModal";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import { useUpdateCreatorProfileData } from "../../apiLayer/Profile";
import { useFetchCreatorData } from "../../apiLayer/Home/index";
import { triggerEvent } from "../../utils/honasa-events";
import { showToast } from "../../features/Notification";
import { useDispatch } from "react-redux";

const AdditionalInfoDOB = () => {
  const {
    data: creatorData,
    refetch: getCreatorData,
    isLoading: creatorDataIsLoading,
  } = useFetchCreatorData({ enabled: false });
  const { dob = "" } = creatorData?.data || {};
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [creatorDOB, setCreatorDOB] = useState(
    getDateObjectFromDateString(
      `Sat Jan 01 2000 05:30:00 GMT+0530 (India Standard Time)`,
    ),
  );
  const [drawerState, setDrawerState] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function refetchCreatorDataAndShowToast() {
    getCreatorData();
    dispatch(
      showToast({
        variant: "success",
        message: "Date of Birth updated successfully",
      }),
    );
  }

  const { mutate } = useUpdateCreatorProfileData({
    onSuccess: refetchCreatorDataAndShowToast,
  });

  useEffect(() => {
    if (location.hash === "#dob") {
      setDrawerState(true);
    } else setDrawerState(false);
  }, [location]);

  useEffect(() => {
    if (dob) setCreatorDOB(getDateObjectFromDateString(dob));
  }, [dob]);

  const handleDateSelect = (date) => {
    setCreatorDOB(date);
  };

  const onClickConfirmBday = async () => {
    const body = {
      dob: formatDate(creatorDOB),
    };
    mutate(body);
    setShowModal(false);
    triggerEvent({
      eventName: "dob_update_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Profile",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: `dob_update_${creatorDOB}`,
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  };

  const onClickCancel = () => {
    setShowModal(false);
    setCreatorDOB(getDateObjectFromDateString(dob));
    triggerEvent({
      eventName: "dob_update_cancel_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Profile",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: `dob_update_cancel`,
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  };

  return (
    <>
      <div
        className="flex align-center justify-between p-4"
        onClick={() => {
          navigate("#dob");
        }}
      >
        <div className="flex align-start">
          <img src={`${IMAGE_PREFIX}calendar.svg`} className="pt-1" alt="" />
          <div>
            <WishlinkTypography
              color={"#666666"}
              fontSize="0.875rem"
              fontWeight={500}
              lineHeight="1.5rem"
              className="text-left ml-3"
            >
              Date of Birth
            </WishlinkTypography>
            {!dob && !creatorDataIsLoading && (
              <WishlinkTypography
                color={"c2c2c2"}
                fontSize="0.75rem"
                fontWeight={400}
                lineHeight="1.5rem"
                className="text-left ml-3"
              >
                Add your birthday
              </WishlinkTypography>
            )}
            <div className="flex align-center">
              <WishlinkTypography
                color={"#333333"}
                fontSize="1rem"
                lineHeight="1.5rem"
                className="text-left ml-3"
              >
                <ValueSkeleton
                  isLoading={creatorDataIsLoading}
                  value={creatorDOB && formatDate(creatorDOB)}
                />
              </WishlinkTypography>
            </div>
          </div>
        </div>
        <img src={`${IMAGE_PREFIX}rightIconGrey.svg`} alt="" />
      </div>
      <WishlinkModal
        showModal={showModal}
        setShowModal={setShowModal}
        showCloseIcon={false}
        persistent={true}
        width="auto"
      >
        <WishlinkDatePicker
          date={creatorDOB}
          handleDateSelect={handleDateSelect}
        />
        <div className="flex justify-end mt-3">
          <WishlinkTypography
            color={"#e91e63"}
            fontWeight={500}
            fontSize="0.875rem"
            className="p-1 cursor-pointer"
            onClick={() => {
              onClickConfirmBday();
            }}
          >
            CONFIRM
          </WishlinkTypography>
          <WishlinkTypography
            color={"#e91e63"}
            fontWeight={500}
            fontSize="0.875rem"
            className="p-1 ml-3 cursor-pointer"
            onClick={() => {
              onClickCancel();
            }}
          >
            CANCEL
          </WishlinkTypography>
        </div>
      </WishlinkModal>
      <WishlinkDrawer
        drawerOpen={drawerState}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
        className="p-4"
      >
        <div className="p-4">
          <WishlinkTypography
            color={"#141414"}
            fontSize="1rem"
            fontWeight={500}
            className="text-center pt-4 pb-2"
          >
            Date of birth
          </WishlinkTypography>
          <WishlinkTypography
            color={"#666666"}
            fontSize="0.875rem"
            fontWeight={400}
            className="text-center pb-4"
          >
            Add your birthday and we will make sure its special
          </WishlinkTypography>
          <div
            style={{
              border: `0.06rem solid ${"#E5E5E5"}`,
              borderRadius: "0.5rem",
            }}
            className="pl-3 pt-3 pb-3 flex justify-between align-center"
            onClick={() => setShowModal(true)}
          >
            <WishlinkTypography
              color={"#666666"}
              fontSize="0.875rem"
              fontWeight={400}
              className="text-left"
              fontStyle={dob ? "" : "italic"}
            >
              {dob ? dob.split("T")[0] : `Select your birthday`}
            </WishlinkTypography>
            <img
              src={`${IMAGE_PREFIX}down-arrow.svg`}
              className="pr-2"
              alt=""
            />
          </div>
          <WishlinkButton
            background={"#e91e63"}
            style={{
              ":hover": {
                bgcolor: "#e91e63",
              },
              ":disabled": {
                bgcolor: "c2c2c2",
              },
            }}
            borderRadius="0.5rem"
            width="100%"
            height="3rem"
            className="mt-3 mb-3"
            onClick={() => {
              navigate(-1, { replace: true });
            }}
          >
            <WishlinkTypography
              color={"#f5f5f5"}
              fontWeight={600}
              fontSize="1rem"
              className="p-1"
            >
              Save
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default AdditionalInfoDOB;
