import PerformanceMetricCard from "./PerformanceMetricCard";
import {
  IMAGE_PREFIX,
  PAGE_URLS,
  PERFORMANCE_METRIC_TYPES,
} from "../../utils/constants";
import { useFetchResellerEarningsThisPayoutData } from "../../apiLayer/Analytics";

const PerformanceMetricCardContainer = (props) => {
  const { creatorData } = props;
  const { data: earningsThisPayoutData, isLoading: earningsDataIsLoading } =
    useFetchResellerEarningsThisPayoutData({ enabled: true });
  const milestones = {},
    rewardsAnalytics = {},
    earningsThisPayout = earningsThisPayoutData?.data || {};
  const creator2kMilestoneAchieved =
    milestones?.check_earnings_cross_2k_milestone?.achieved === true;

  const performanceMetrics = [
    {
      icon: IMAGE_PREFIX + "totalearnings.png",
      value: earningsThisPayout?.earnings_this_payout,
      isLoading: earningsDataIsLoading,
      title: "Total earnings this payout",
      subtextIcon: IMAGE_PREFIX + "calendar-outline-grey.svg",
      subtextHeader: "Last Payout Date",
      subtextValue: earningsThisPayout?.date_since_formatted,
      redirectUrl: PAGE_URLS.PAYOUTS,
      type: PERFORMANCE_METRIC_TYPES.PAYOUT,
      isNegative: earningsThisPayout.is_earnings_this_payout_negative,
      toShow: true,
    },
    {
      icon: IMAGE_PREFIX + "bonuses.png",
      value: rewardsAnalytics?.data?.data?.reward_won,
      isLoading: false,
      title: "Rewards won in " + rewardsAnalytics?.data?.data?.campaign_name,
      subtextIcon: IMAGE_PREFIX + "rupee-filled-icon.svg",
      subtextHeader: "Winning Potential",
      subtextValue: "₹ " + rewardsAnalytics?.data?.data?.max_potential,
      type: PERFORMANCE_METRIC_TYPES.REWARDS,
      redirectUrl: `/rewards/${rewardsAnalytics?.data?.data?.campaign_id}`,
      toShow: !!rewardsAnalytics?.data?.data?.campaign_id,
    },
  ];

  return (
    <div className="flex flex-column mb-2" style={{ paddingBottom: "5rem" }}>
      {performanceMetrics.map((metric, idx) => {
        return (
          <PerformanceMetricCard
            value={metric.value}
            title={metric.title}
            icon={metric.icon}
            type={metric.type}
            subtextIcon={metric.subtextIcon}
            subtextHeader={metric.subtextHeader}
            subtextValue={metric.subtextValue}
            redirectUrl={metric.redirectUrl}
            isLoading={metric.isLoading}
            key={idx}
            gaLabel={metric.gaLabel}
            gaLabel2={metric.gaLabel2}
            is2kMilestoneAchieved={creator2kMilestoneAchieved}
            toShow={metric.toShow}
            isNegative={metric.isNegative}
            creatorData={creatorData}
          />
        );
      })}
    </div>
  );
};

export default PerformanceMetricCardContainer;
