import { useEffect } from "react";
import { Divider } from "@mui/material";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkEmptyState from "../../WishlinkComponents/WishlinkEmptyState";
// import CommissionRangeTooltip from "../CommissionRangeTooltip";
// import CommissionHelpTooltip from "../CommissionHelpTooltip";
import ListSkeletonContainer from "../../WishlinkComponents/ListSkeletonContainer";
import Spinner from "../../WishlinkComponents/Spinner";

const AnalyticsProductsList = (props) => {
  const {
    list,
    isLoading,
    isListLoading,
    buttonOnClick,
    emptyStateExists,
    fadeIn,
  } = props;

  useEffect(() => {
    if (fadeIn) fadeIn();
  }, [list]);

  return (
    <>
      {isLoading ? (
        <ListSkeletonContainer numRows={5} />
      ) : (
        <>
          {list?.length > 0 ? (
            list?.map((listItem) => {
              return (
                <div
                  key={listItem.id}
                  className={`${fadeIn ? `tile-animation` : ""}`}
                >
                  <div className="pt-5 pb-5">
                    <div className="flex align-center justify-between">
                      <div className="flex align-start">
                        <img
                          src={listItem.thumbnail_url}
                          alt=""
                          style={{ width: "3.25rem" }}
                        />
                        <div className="ml-4">
                          <div className="flex" style={{ maxWidth: "14rem" }}>
                            <WishlinkTypography
                              fontWeight={600}
                              fontSize="0.875rem"
                              color={"#333333"}
                            >
                              {listItem.brand}
                            </WishlinkTypography>
                            {listItem.commission_new !== 0 ? (
                              <>
                                <span
                                  style={{
                                    background:
                                      listItem?.show_commission_additional_info &&
                                      listItem?.commission_additional_info_text
                                        ? "#FFFDD2"
                                        : "#F1F4F9",
                                    height: "1.5rem",
                                    borderRadius: "0.25rem",
                                  }}
                                  className="pl-1 pr-1 ml-2 flex align-center justify-center"
                                >
                                  <WishlinkTypography
                                    color={"#333333"}
                                    fontSize="0.625rem"
                                    fontWeight={400}
                                  >
                                    @{listItem.live_commission}%
                                  </WishlinkTypography>
                                </span>
                                {listItem?.show_commission_additional_info &&
                                listItem?.commission_additional_info_text ? (
                                  // <CommissionHelpTooltip
                                  //   commissionDataText={
                                  //     listItem?.commission_additional_info_text
                                  //   }
                                  // />
                                  <></>
                                ) : listItem?.multiple_commissions_exist &&
                                  listItem?.show_multiple_commissions ? (
                                  <></>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    background: "#ffe8e8",
                                    borderRadius: "0.125rem",
                                  }}
                                  className="p-1 ml-2"
                                >
                                  <WishlinkTypography
                                    fontSize="0.625rem"
                                    fontWeight={400}
                                    color={"#141414"}
                                  >
                                    @0%
                                  </WishlinkTypography>
                                </div>
                                {listItem?.show_commission_additional_info &&
                                  listItem?.commission_additional_info_text && (
                                    // <CommissionHelpTooltip
                                    //   commissionDataText={
                                    //     listItem?.commission_additional_info_text
                                    //   }
                                    // />
                                    <></>
                                  )}
                              </>
                            )}
                          </div>
                          <WishlinkTypography
                            style={{ width: "12rem" }}
                            fontWeight={400}
                            fontSize="0.75rem"
                            color={"#333333"}
                            noWrap={true}
                            className="overflow-ellipsis overflow-hidden white-nowrap text-left"
                          >
                            {listItem.name}
                          </WishlinkTypography>
                          <div className="flex mt-2">
                            <div className="flex">
                              <WishlinkTypography
                                fontWeight={400}
                                fontSize="0.75rem"
                                lineHeight="1.125rem"
                                color={"#333333"}
                              >
                                Lifetime Commissions:
                                {listItem?.earnings_is_negative ? "- " : " "}
                                ₹&nbsp;
                                {listItem?.earnings}
                              </WishlinkTypography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <WishlinkTypography
                          fontWeight={700}
                          fontSize="1rem"
                          lineHeight="1.5rem"
                          color={"#333333"}
                        >
                          {listItem?.lifetime_sales_is_negative ? " - " : " "}
                          ₹&nbsp;
                          {listItem?.lifetime_sales}
                        </WishlinkTypography>
                      </div>
                    </div>
                  </div>
                  <Divider />
                </div>
              );
            })
          ) : (
            <WishlinkEmptyState
              headingText={
                emptyStateExists
                  ? "No single products yet"
                  : "No products found"
              }
              subHeadingText={
                emptyStateExists
                  ? "Instantly create and share a product Wishlink with your audience. This won't be visible on your shop"
                  : "No insights in the selected time period. Try changing the channel or date range"
              }
              buttonText="Create a Product Wishlink"
              buttonOnClick={buttonOnClick}
              emptyStateExists={emptyStateExists}
            />
          )}
        </>
      )}
      {isListLoading && <Spinner />}
    </>
  );
};

export default AnalyticsProductsList;
