import Pagination from "@mui/material/Pagination";

const WishlinkPagination = (props) => {
  const { count = 0, size = "small", onChange } = props;
  return (
    <Pagination
      count={count}
      size={size}
      sx={{ display: "flex", justifyContent: "center" }}
      onChange={onChange}
    />
  );
};

export default WishlinkPagination;
