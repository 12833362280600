import React from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import WishlinkTypography from "../WishlinkTypography";
import WishlinkButton from "../WishlinkButton";
import { getAssetUrl } from "../../utils/common";
import styled from "@emotion/styled";
const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;

const WishlinkProductCard = (props) => {
  const { product, onClickProduct, onClickCopyLinkButton } = props;
  const {
    name: title,
    brand,
    mrp = 1,
    price = 1,
    thumbnail_url = getAssetUrl("Wishlink-logo.png"),
    shorturl,
    commission,
  } = product;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      style={{
        cursor: "pointer",
        border: "1px solid #eee",
      }}
      className="border-radius relative"
      onClick={() => onClickProduct({ productLink: shorturl, product })}
    >
      <div className="relative">
        <Image
          src={thumbnail_url || getAssetUrl("Wishlink-logo.png")}
          height="200px"
          width="100%"
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = getAssetUrl("Wishlink-logo.png");
          }}
        />
      </div>
      <div
        style={{
          background: colors.grey[0],
          borderBottomLeftRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
        }}
        className="p-2 pt-2"
      >
        <WishlinkTypography
          color={colors.grey[700]}
          fontSize="0.6875rem"
          fontWeight={400}
          className="text-left pb-1"
          typographystyle={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            display: "-webkit-box",
          }}
          mb={0.5}
        >
          {title}
        </WishlinkTypography>
        <div className="flex align-center justify-start">
          <WishlinkTypography
            color={colors.grey[700]}
            fontSize="0.625rem"
            fontWeight={400}
          >
            {brand}
          </WishlinkTypography>
          <span
            style={{
              background: colors.indigo[500],
              borderRadius: "0.125rem",
            }}
            className="p-1 ml-1"
          >
            <WishlinkTypography
              color={colors.grey[700]}
              fontSize="0.625rem"
              fontWeight={400}
            >
              {commission}%
            </WishlinkTypography>
          </span>
        </div>
        <div className="flex align-center">
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={colors.grey[700]}
          >
            &#8377; {price}
          </WishlinkTypography>
          {mrp > price && (
            <WishlinkTypography
              fontSize="0.8rem"
              className="pl-2"
              fontWeight={400}
              color={colors.grey[300]}
              style={{ textDecoration: "line-through" }}
            >
              &#8377; {mrp}
            </WishlinkTypography>
          )}
        </div>
        <WishlinkButton
          background={colors.red[500]}
          style={{
            ":hover": {
              bgcolor: colors.red[500],
            },
            zIndex: 3,
          }}
          borderRadius="0.5rem"
          width="95%"
          height="2rem"
          className="pt-2 pb-1"
          onClick={(event) =>
            onClickCopyLinkButton({
              productLink: shorturl,
              product,
              event,
            })
          }
        >
          <WishlinkTypography
            fontSize="0.7rem"
            fontWeight={600}
            color={colors.grey[0]}
          >
            Copy Link
          </WishlinkTypography>
        </WishlinkButton>
      </div>
    </div>
  );
};

WishlinkProductCard.defaultProps = {
  product: {
    title: "Product Title",
    brand: {
      name: "Brand Name",
      display_name: "Brand Display Name",
      brand_sale_offer: {
        start_date: "2021-08-01",
        end_date: "2021-08-31",
      },
      default_coupon_code: "COUPON",
    },
    mrp: 1,
    price: 1,
    thumbnail_url: getAssetUrl("Wishlink-logo.png"),
    sourcing_commission_min: 0,
    sourcing_commission_max: 0,
  },
  addToCart: () => {},
  isTagEnabled: true,
  onClickProduct: () => {},
  brandLimit: null,
  isBarter: false,
};

export default WishlinkProductCard;
