import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX } from "../../utils/constants";
import AddressDrawer from "./AddressDrawer";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import { useFetchCreatorAddressData } from "../../apiLayer/Profile";

const AdditionalInfoAddress = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: creatorAddress, isLoading: creatorAddressDataIsLoading } =
    useFetchCreatorAddressData({ enabled: false });

  const defaultAddress = creatorAddress?.data?.find(
    (address) => address.is_default,
  );

  const [drawerState, setDrawerState] = useState(false);

  useEffect(() => {
    if (location.hash === "#address") {
      setDrawerState(true);
    } else setDrawerState(false);
  }, [location]);

  const onClickAddress = () => {
    if (creatorAddress?.data?.length === 0) navigate(`/address/new`);
    else navigate(`/profile#address`);
  };

  return (
    <div className="flex align-center justify-between p-4">
      <div className="flex align-start w-100" onClick={() => onClickAddress()}>
        <img src={`${IMAGE_PREFIX}home-black.svg`} className="pt-1" alt="" />
        <div>
          <WishlinkTypography
            color={"#666666"}
            fontSize="0.875rem"
            fontWeight={500}
            lineHeight="1.5rem"
            className="text-left ml-3"
          >
            Address
          </WishlinkTypography>
          {!creatorAddress?.data?.length && !creatorAddressDataIsLoading && (
            <WishlinkTypography
              color={"#c2c2c2"}
              fontSize="0.75rem"
              fontWeight={400}
              lineHeight="1.5rem"
              className="text-left ml-3"
            >
              Add your address
            </WishlinkTypography>
          )}
          <ValueSkeleton
            isLoading={creatorAddressDataIsLoading}
            value={
              defaultAddress && (
                <>
                  <div className="flex justify-start ml-3">
                    <WishlinkTypography
                      color={"#333333"}
                      fontSize="1rem"
                      fontWeight={500}
                      lineHeight="1.5rem"
                      className="text-left"
                    >
                      {defaultAddress?.tag}
                    </WishlinkTypography>
                    <WishlinkTypography
                      color={"#666666"}
                      fontSize="0.6875rem"
                      fontWeight={400}
                      lineHeight="1.5rem"
                      className="text-center ml-3 pl-2 pr-2"
                      style={{
                        background: "#F1F4F9",
                        border: `0.06rem solid "#E5E5E5"`,
                        borderRadius: "0.25rem",
                      }}
                    >
                      Default
                    </WishlinkTypography>
                  </div>
                  <WishlinkTypography
                    color={"#666666"}
                    fontSize="0.875rem"
                    fontWeight={400}
                    lineHeight="1.5rem"
                    className="text-left mr-5 ml-3"
                  >
                    {defaultAddress?.address_line +
                      ", " +
                      defaultAddress?.locality +
                      ", " +
                      defaultAddress?.pincode +
                      ", " +
                      defaultAddress?.city}
                  </WishlinkTypography>
                </>
              )
            }
          />
        </div>
      </div>
      <img
        src={`${IMAGE_PREFIX}rightIconGrey.svg`}
        onClick={() => onClickAddress()}
        alt=""
      />
      <AddressDrawer drawerState={drawerState} />
    </div>
  );
};

export default AdditionalInfoAddress;
