import { Radio } from "@mui/material";

const WishlinkRadioButton = (props) => {
  const { checked, handleChange } = props;

  return (
    <Radio
      checked={checked}
      sx={{
        color: "#c4c4c4",
        "&.Mui-checked": {
          color: "#4031E9",
        },
      }}
      onChange={handleChange}
      name="radio-buttons"
    />
  );
};

export default WishlinkRadioButton;
