import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { EMAIL_REGEX, IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkEditableInput from "../../WishlinkComponents/WishlinkEditableInput";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import { useUpdateCreatorProfileData } from "../../apiLayer/Profile";
import { useFetchCreatorData } from "../../apiLayer/Home/index";
import { triggerEvent } from "../../utils/honasa-events";
import { showToast } from "../../features/Notification";
import { useDispatch } from "react-redux";

const AdditionalInfoEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: creatorData,
    refetch: getCreatorData,
    isLoading: creatorDataIsLoading,
  } = useFetchCreatorData({ enabled: false });
  const { email = "" } = creatorData?.data || {};
  const [drawerState, setDrawerState] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [creatorEmail, setCreatorEmail] = useState(email);
  const dispatch = useDispatch();

  function refetchCreatorDataAndShowToast() {
    getCreatorData();
    dispatch(
      showToast({
        variant: "success",
        message: "Email updated successfully",
      }),
    );
  }

  const { mutate } = useUpdateCreatorProfileData({
    onSuccess: refetchCreatorDataAndShowToast,
  });

  useEffect(() => {
    if (location.hash === "#email") {
      setDrawerState(true);
    } else setDrawerState(false);
  }, [location]);

  useEffect(() => {
    setCreatorEmail(email);
  }, [email]);

  async function onClickCheckMark() {
    if (!creatorEmail || !EMAIL_REGEX.test(creatorEmail)) {
      dispatch(
        showToast({
          variant: "error",
          message: "Please enter a valid email",
        }),
      );
      return;
    }
    const body = {
      email: creatorEmail?.toLowerCase(),
    };
    mutate(body);
    setEditMode(false);
    triggerEvent({
      eventName: "email_update_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Profile",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: `email_update_${creatorEmail}`,
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  }

  function onClickCross() {
    setEditMode(false);
    setCreatorEmail(email);
    triggerEvent({
      eventName: "dob_update_cancel_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Profile",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: `email_update_cancel`,
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  }

  return (
    <>
      <div
        className="flex align-center justify-between p-4"
        onClick={() => {
          navigate("#email");
        }}
      >
        <div className="flex align-start">
          <img src={`${IMAGE_PREFIX}email.svg`} className="pt-1" alt="" />
          <div>
            <WishlinkTypography
              color={"#666666"}
              fontSize="0.875rem"
              fontWeight={500}
              lineHeight="1.5rem"
              className="text-left ml-3"
            >
              Email
            </WishlinkTypography>
            {!email && !creatorDataIsLoading && (
              <WishlinkTypography
                color={"#c2c2c2"}
                fontSize="0.75rem"
                fontWeight={400}
                lineHeight="1.5rem"
                className="text-left ml-3"
              >
                Add your email id
              </WishlinkTypography>
            )}
            <div className="flex align-center">
              <WishlinkTypography
                color={"#333333"}
                fontSize="1rem"
                lineHeight="1.5rem"
                className="text-left ml-3"
              >
                <ValueSkeleton isLoading={creatorDataIsLoading} value={email} />
              </WishlinkTypography>
            </div>
          </div>
        </div>
        <img src={`${IMAGE_PREFIX}rightIconGrey.svg`} alt="" />
      </div>
      <WishlinkDrawer
        drawerOpen={drawerState}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
        className="p-4"
      >
        <div className="p-4">
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={"#141414"}
            className="text-center pt-4"
          >
            Add your email address
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.875rem"
            fontWeight={400}
            color={"#666666"}
            className="text-center mt-2 pb-3"
          >
            Stay up to date with all relevant Wishlink updates and information.
          </WishlinkTypography>
          <div style={{ height: "4rem" }}>
            <WishlinkEditableInput
              value={creatorEmail}
              editMode={editMode}
              multiline={true}
              error={!EMAIL_REGEX.test(creatorEmail)}
              helperText="Please enter a valid email"
              setEditMode={setEditMode}
              placeholder="Your email address here"
              onInputValueChange={(val) => setCreatorEmail(val)}
              onClickCheckMark={onClickCheckMark}
              onClickCross={onClickCross}
            />
          </div>
          <WishlinkButton
            background={"#E91E63"}
            style={{
              ":hover": {
                bgcolor: "#E91E63",
              },
              ":disabled": {
                bgcolor: "#c2c2c2",
              },
            }}
            borderRadius="0.5rem"
            width="100%"
            height="3rem"
            className="mt-3 mb-3"
            disabled={editMode}
            onClick={() => {
              navigate(-1, { replace: true });
            }}
          >
            <WishlinkTypography
              color={"#f5f5f5"}
              fontWeight={600}
              fontSize="1rem"
              className="p-1"
            >
              Save
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default AdditionalInfoEmail;
