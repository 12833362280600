import { useNavigate } from "react-router-dom";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX, PERFORMANCE_METRIC_TYPES } from "../../utils/constants";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import { triggerEvent } from "../../utils/honasa-events";

const PerformanceMetricCard = (props) => {
  const navigate = useNavigate();
  const {
    value,
    title,
    icon,
    redirectUrl,
    is2kMilestoneAchieved,
    type,
    isLoading,
    isNegative = false,
    toShow,
    creatorData,
  } = props;

  const handleNavigateAndGa = () => {
    navigate(redirectUrl);
  };

  const handleOnClick = () => {
    triggerEvent({
      eventName: "earnings_overview_cta_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Analytics-Home",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "earnings_overview_analytics",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    (is2kMilestoneAchieved || type === PERFORMANCE_METRIC_TYPES.PAYOUT) &&
      !!redirectUrl &&
      handleNavigateAndGa();
  };

  return (
    <>
      {toShow && (
        <div
          className="flex border-radius p-4 align-center relative"
          style={{ background: "#fff", zIndex: 100 }}
        >
          <img
            src={icon}
            alt="performance-header-icon"
            className="mr-2"
            style={{ maxHeight: "2rem" }}
            onClick={() => handleOnClick()}
          />
          <div className=" flex flex-column flex-grow">
            <div className="flex align-center" onClick={() => handleOnClick()}>
              <div className="flex flex-column align-start">
                <WishlinkTypography
                  fontWeight={700}
                  fontSize="1.125rem"
                  lineheight="1.6875rem"
                  color={"#333333"}
                  className="pb-2"
                >
                  <ValueSkeleton
                    width="1rem"
                    isLoading={isLoading}
                    value={`${isNegative ? "- " : ""}₹ ${value}`}
                  />
                </WishlinkTypography>
                <WishlinkTypography
                  fontWeight={500}
                  fontSize="0.875rem"
                  lineheight="1.3125rem"
                  color={"#666666"}
                >
                  <ValueSkeleton isLoading={isLoading} value={title} />
                </WishlinkTypography>
              </div>
              <img
                src={IMAGE_PREFIX + "rightIconGrey.svg"}
                className="ml-auto"
                alt="right-icon"
              />
            </div>
          </div>
          <div
            style={{
              borderTop: `0.5rem solid #F1F4F9`,
            }}
          >
            <></>
          </div>
        </div>
      )}
    </>
  );
};

export default PerformanceMetricCard;
