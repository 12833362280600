import { Divider } from "@mui/material";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import AdditionalInfoGender from "./AdditionalInfoGender.jsx";
import AdditionalInfoDOB from "./AdditionalInfoDOB.jsx";
import AdditionalInfoEmail from "./AdditionalInfoEmail.jsx";
import AdditionalInfoAddress from "./AdditionalInfoAddress.jsx";
import { IMAGE_PREFIX } from "../../utils/constants";

const AdditionalInfo = () => {
  return (
    <div className="p-4">
      <div className="flex align-center">
        <WishlinkTypography
          fontSize="1rem"
          fontWeight={500}
          color={"#333333"}
          className="text-left"
        >
          Additional Info
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.6875rem"
          fontWeight={400}
          color={"#333333"}
          typographyClassName="flex align-center"
          className="text-left p-1 pl-2 pr-2 ml-2"
          style={{ background: "#ECF0FF" }}
        >
          <img
            src={`${IMAGE_PREFIX}private.svg`}
            className="mr-1"
            style={{ marginBottom: "0.12rem" }}
            alt=""
          />
          PRIVATE TO YOU
        </WishlinkTypography>
      </div>
      <AdditionalInfoGender />
      <Divider />
      <AdditionalInfoDOB />
      <Divider />
      <AdditionalInfoEmail />
      <Divider />
      <AdditionalInfoAddress />
    </div>
  );
};

export default AdditionalInfo;
