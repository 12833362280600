import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showToast: false,
  variant: "",
  message: "",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showToast: (state, action) => {
      state.showToast = true;
      state.variant = action.payload.variant;
      state.message = action.payload.message;
    },
    hideToast: (state, action) => {
      state.showToast = false;
      state.variant = "";
      state.message = "";
    },
  },
});

export const { showToast, hideToast } = notificationSlice.actions;

export default notificationSlice.reducer;
