import { useNavigate } from "react-router-dom";
import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import { triggerEvent } from "../../utils/honasa-events";

const RewardsSummary = (props) => {
  const { creatorData } = props;
  const rewardsSummaryHasLoaded = true;
  const rewardBannerUrl = `${IMAGE_PREFIX}honasa-rewards-banner.jpeg`;
  const is2KmilestoneAchieved = true;
  const navigate = useNavigate();

  return (
    <>
      {rewardsSummaryHasLoaded &&
        !!rewardBannerUrl &&
        is2KmilestoneAchieved && (
          <div className="pl-4 pr-4">
            <img
              src={rewardBannerUrl}
              className="border-radius"
              style={{ width: "100%", zIndex: 2 }}
              alt="reward-banner"
              onClick={() => {
                navigate(PAGE_URLS.REWARDS);
                triggerEvent({
                  eventName: "rewards_banner_click",
                  eventAttributes: {
                    page_url: window.location.href,
                    page_name: "Home",
                    user_id: creatorData?.data?.id,
                    timestamp: new Date().toISOString(),
                    event_type: "click",
                    product_name: "",
                    product_price: "",
                    element: "rewards_banner",
                    gender: creatorData?.data?.gender,
                    added_on: creatorData?.data?.added_on,
                  },
                });
              }}
            />
          </div>
        )}
    </>
  );
};

export default RewardsSummary;
