import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { useEffect, useState } from "react";
import { getResponsiveWidth } from "../../utils/common";
import { IMAGE_PREFIX } from "../../utils/constants";

const AnalyticsOverlay = () => {
  const [responsiveWidth, setResponsiveWidth] = useState(getResponsiveWidth());

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    const handleScreenResize = () => {
      setResponsiveWidth(getResponsiveWidth());
    };

    window.addEventListener("resize", handleScreenResize);
    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  return (
    <>
      <div
        style={{
          height: window.innerHeight,
          width: responsiveWidth,
          zIndex: 99,
          position: "fixed",
          top: 0,
        }}
      >
        <div
          style={{
            boxShadow: "0 0 0 99999px rgba(0, 0, 0, .8)",
            zIndex: 99,
          }}
        ></div>
        <div
          className="flex flex-column align-center justify-center"
          style={{
            maxWidth: "19rem",
            margin: "auto",
            top: "30%",
            position: "relative",
          }}
        >
          <img
            src={`${IMAGE_PREFIX}earnings-locked.png`}
            alt=""
            style={{ height: "5rem" }}
          />
          <WishlinkTypography
            fontSize={"1.25rem"}
            color={"#FFFFFF"}
            mt={"2rem"}
          >
            Your Payouts will be unlocked after earnings of ₹500
          </WishlinkTypography>
          <WishlinkTypography
            fontSize={"0.875rem"}
            lineHeight={"1.25rem"}
            color={"#FFFFFF"}
            mt={"2rem"}
          >
            You'll be able to see Sales, commissions and get Insights here once
            you earn ₹500
          </WishlinkTypography>
        </div>
      </div>
    </>
  );
};

export default AnalyticsOverlay;
