import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

// import ReferCard from "./Components/ReferCard";
import { ReferFriends } from "./Components/ReferFriends";
import { ReferralCode } from "./Components/ReferralCode";
// import ReferralDrawer from "./Components/ReferralDrawer";
import { ReferralHeader } from "./Components/ReferralHeader";
import { ReferralRewards } from "./Components/ReferralRewards";
import { tokens } from "../../theme";
import { ReferCard } from "./Components/ReferCard";
import { useFetchResellerReferralConfig } from "../../apiLayer/Referral/Referral.services";
import { useFetchCreatorData } from "../../apiLayer/Home";
import { triggerEvent } from "../../utils/honasa-events";

const Referral = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const creatorReferalData = {
    referral_code: "123456",
    show_invite_drawer: false,
  };
  //   const { creatorReferalData } = useSelector((state) => state.referralReducer);
  const { show_invite_drawer } = creatorReferalData;

  const { data: resellerReferralConfig, isLoading: referralConfigLoading } =
    useFetchResellerReferralConfig();
  const { data: creatorData } = useFetchCreatorData({ enabled: true });
  const {
    referral_code = "",
    reseller_name = "",
    slab1_num_referrals_required = 0,
    slab1_referral_amount = 0,
    slab2_num_referrals_required = 0,
    slab2_referral_amount = 0,
    slab3_num_referrals_required = 0,
    slab3_referral_amount = 0,
  } = resellerReferralConfig?.data || {};

  useEffect(() => {
    if (show_invite_drawer) {
      navigate("#invite");
    }
  }, [show_invite_drawer]);

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "referral_pl",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Referral",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "page_load",
          product_name: "",
          product_price: "",
          element: "",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "referral_code_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Referral",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "referral_code",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "referral_stats_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Referral",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "referral_stats",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  return (
    <div
      className="flex flex-column"
      style={{ background: colors.misc[0], color: colors.grey[0] }}
    >
      <ReferralHeader referral_code={referral_code} creatorData={creatorData} />
      <ReferFriends
        reseller_name={reseller_name}
        isLoading={referralConfigLoading}
      />
      <ReferCard
        slab1_num_referrals_required={slab1_num_referrals_required}
        slab1_referral_amount={slab1_referral_amount}
        slab2_num_referrals_required={slab2_num_referrals_required}
        slab2_referral_amount={slab2_referral_amount}
        slab3_num_referrals_required={slab3_num_referrals_required}
        slab3_referral_amount={slab3_referral_amount}
        referral_code={referral_code}
      />
      <ReferralCode referral_code={referral_code} creatorData={creatorData} />
      <ReferralRewards
        slab1_num_referrals_required={slab1_num_referrals_required}
        slab1_referral_amount={slab1_referral_amount}
        slab2_num_referrals_required={slab2_num_referrals_required}
        slab2_referral_amount={slab2_referral_amount}
        slab3_num_referrals_required={slab3_num_referrals_required}
        slab3_referral_amount={slab3_referral_amount}
        referralConfigLoading={referralConfigLoading}
      />
      {/* 
      <ReferralDrawer drawerState={drawerState} /> */}
    </div>
  );
};

export default Referral;
