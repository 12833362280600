import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

import { useNavigate } from "react-router-dom";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";

const AnalyticsCard = (props) => {
  const {
    titleIcon,
    redirectIcon = null,
    title,
    analyticsFigure,
    analyticsShippedFigure,
    analyticsReturnedFigure,
    showRupeePrefix = false,
    analyticsFigureFontColor,
    redirectUrl = "",
    showRedirectIcon,
    isLoading,
    backgroundColor = "transparent",
    isNegative = false,
  } = props;
  const navigate = useNavigate();
  const returnedValue = `${analyticsReturnedFigure !== "0" ? "- " : ""}${
    showRupeePrefix ? "₹ " : ""
  }${analyticsReturnedFigure}`;

  const handleNavigate = () => {
    navigate(redirectUrl);
  };

  return (
    <div
      className="flex flex-column bs-1 border-radius align-start p-4 "
      onClick={() => {
        !!redirectUrl && !!showRedirectIcon && handleNavigate();
      }}
      style={{ backgroundColor }}
    >
      {titleIcon && (
        <div className="flex align-center justify-between w-100">
          <img
            src={titleIcon}
            alt="title-metric-icon"
            className="mb-2"
            style={{ width: "1.5rem" }}
          />
          {!!redirectIcon && !!showRedirectIcon && (
            <img src={redirectIcon} alt="redirect-icon" />
          )}
        </div>
      )}
      <WishlinkTypography
        fontWeight={500}
        fontSize="0.75rem"
        lineHeight="1.3125rem"
        color={"#666666"}
        className="mb-2"
      >
        {title}
      </WishlinkTypography>
      <WishlinkTypography
        fontWeight={700}
        fontSize="1.25rem"
        lineHeight="2.25rem"
        color={analyticsFigureFontColor}
      >
        <ValueSkeleton
          isLoading={isLoading}
          value={`${isNegative ? "-" : ""}${showRupeePrefix ? "₹ " : ""}${
            analyticsFigure ? analyticsFigure : "0"
          }`}
        />
      </WishlinkTypography>
      <div className="flex justify-between w-100 mt-4">
        <div className="shipped text-left">
          <WishlinkTypography
            fontWeight={500}
            fontSize="0.687rem"
            lineHeight="1rem"
            color={"#666666"}
          >
            Ordered
          </WishlinkTypography>
          <WishlinkTypography
            fontWeight={600}
            fontSize="0.8125rem"
            lineHeight="1.25rem"
            color={"#18a716"}
          >
            <ValueSkeleton
              isLoading={isLoading}
              value={`${showRupeePrefix ? "₹ " : ""}${
                analyticsShippedFigure ? analyticsShippedFigure : "0"
              }`}
            />
          </WishlinkTypography>
        </div>
        <div className="returned text-left">
          <WishlinkTypography
            fontWeight={500}
            fontSize="0.687rem"
            lineHeight="1rem"
            color={"#666666"}
          >
            Cancelled
          </WishlinkTypography>
          <WishlinkTypography
            fontWeight={600}
            fontSize="0.8125rem"
            lineHeight="1.25rem"
            color={"#e91e63"}
          >
            <ValueSkeleton
              isLoading={isLoading}
              value={returnedValue ? returnedValue : "0"}
            />
          </WishlinkTypography>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsCard;
