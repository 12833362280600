import { makeAPICall } from "../../utils/common";
import { BASE_URL } from "../../utils/constants";
import { useQuery } from "@tanstack/react-query";

export const useFetchGetResellerCurrentPayout = ({ enabled }) => {
  return useQuery({
    queryKey: ["resellerCurrentPayout"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerCurrentPayout`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useFetchGetResellerPastPayouts = ({ enabled }) => {
  return useQuery({
    queryKey: ["resellerPastPayouts"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerPastPayouts`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};
