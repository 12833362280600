import ValueSkeleton from "../../../../WishlinkComponents/ValueSkeleton";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX } from "../../../../utils/constants";

export const ReferFriends = (props) => {
  const { reseller_name, isLoading } = props;

  return (
    <div className="relative">
      <img
        src={`${IMAGE_PREFIX}mask-group.svg`}
        className="w-100"
        style={{ position: "absolute", top: 0, left: 0 }}
        alt=""
      />
      <WishlinkTypography
        fontSize="1.5rem"
        lineHeight="2.25rem"
        className="mt-4"
      >
        <ValueSkeleton isLoading={isLoading} value={`Hi, ${reseller_name}`} />
      </WishlinkTypography>
      <WishlinkTypography
        fontSize="1.25rem"
        lineHeight="2.25rem"
        fontWeight={700}
        className="ml-2 mr-2 mt-4"
      >
        Invite your friends
      </WishlinkTypography>
      <WishlinkTypography
        fontSize="1.25rem"
        lineHeight="2.25rem"
        fontWeight={700}
        className="ml-2 mr-2"
      >
        to Wishlink x Pure Origin
      </WishlinkTypography>
      <WishlinkTypography
        fontSize="0.875rem"
        lineHeight="1.3125rem"
        fontWeight={500}
        className="ml-2 mr-2 mt-2"
      >
        and win exciting cash prizes
      </WishlinkTypography>
    </div>
  );
};
