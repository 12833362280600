import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import OtpInput from "./OtpInput";
import { useState } from "react";

const OtpInputComponent = (props) => {
  const {
    sendOtp,
    otpResult,
    phoneNumber,
    countryCode,
    sendingOtp,
    drawerStage,
  } = props;

  const [verifyingOtp, setVerifyingOtp] = useState(false);

  const syncVerifyingOtp = (newState) => {
    setVerifyingOtp(newState);
  };

  const onClickBackButton = () => {
    window.history.back();
  };

  return (
    <div
      className="text-left"
      style={{
        background: "#fff",
      }}
    >
      <div className="p-4">
        <img
          src={`${IMAGE_PREFIX}leftIcon.svg`}
          className="mr-4"
          onClick={() => onClickBackButton()}
          alt=""
        />
        <img src={`${IMAGE_PREFIX}wishlink-orange.svg`} alt="" />
        <WishlinkTypography
          fontSize="1.375rem"
          fontWeight={600}
          color={"#333333"}
          className="pt-5"
        >
          We've sent you an OTP
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.875rem"
          fontWeight={400}
          color={"#666666"}
          className="pt-3"
        >
          Please enter the 6-digit OTP sent to you at{" "}
          <span style={{ fontWeight: 700 }}>
            {countryCode}-{phoneNumber}
          </span>
        </WishlinkTypography>
      </div>
      <OtpInput
        sendOtp={sendOtp}
        otpResult={otpResult}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        sendingOtp={sendingOtp}
        syncVerifyingOtp={syncVerifyingOtp}
        verifyingOtp={verifyingOtp}
        drawerStage={drawerStage}
      />
    </div>
  );
};

export default OtpInputComponent;
