import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { IMAGE_PREFIX } from "../../../../utils/constants";

const wishlinkLogoWhite = "wishlink-logo-white.svg";

export const RewardsPoweredBy = React.memo(function RewardsPoweredBy(props) {
  const { textColor = "#333333" } = props;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        margin: "3.5rem 0rem 1rem 0rem",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "0.6875rem",
          lineHeight: "1.42rem",
          textAlign: "center",
          color: textColor,
        }}
      >
        Powered by
      </Typography>
      <Link
        eventLabel={"powered_by"}
        to={{ pathname: "https://home.wishlink.com" }}
        target="_blank"
        rel={"noreferrer"}
        style={{ color: "black", textDecoration: "none" }}
      >
        <img
          src={`${IMAGE_PREFIX}${wishlinkLogoWhite}`}
          style={{
            width: "3rem",
            height: "0.75rem",
            marginLeft: "0.5rem",
            marginTop: "0rem",
            transform: "scale(2)",
          }}
          alt=""
        />
      </Link>
    </Box>
  );
});

export default RewardsPoweredBy;
