import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import {
  getOpacityForDisabledValue,
  LOGIN_DRAWER_STAGES,
  PAGE_URLS,
} from "../../utils/constants";
import Spinner from "../../WishlinkComponents/Spinner";
import OtpInputLoop from "./OtpInputLoop";
import OtpVerificationButton from "./OtpVerificationButton";
import { useMutation } from "@tanstack/react-query";
import { postFirebaseLogin } from "../../apiLayer/Welcome";

const OtpInput = (props) => {
  const navigate = useNavigate();
  const {
    sendOtp,
    otpResult,
    phoneNumber,
    countryCode,
    sendingOtp,
    syncVerifyingOtp,
    drawerStage,
  } = props;
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [consolidatedOtp, setConsolidatedOtp] = useState("");
  const [otpIndexWithFocus, setOtpIndexWithFocus] = useState(0);
  const [confirmingOtp, setConfirmingOtp] = useState(false);
  const [lastOtpIndexUpdated, setLastOtpIndexUpdated] = useState(-1);
  const [proceedButtonDisabled, setProceedButtonDisabled] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [otpResendIntervalId, setOtpResendIntervalId] = useState(0);
  const ref = useRef(null);

  const [otpResendTimeLeft, setOtpResendTimeLeft] = useState(30);
  const [height, setHeight] = useState(window.innerHeight - 180);

  const postFirebaseLoginMutation = useMutation({
    mutationFn: (variables) => postFirebaseLogin(variables),
    onSuccess: (data, { firebaseToken }) => {
      const firebaseLoginResult = data;
      localStorage.removeItem("creatorSource");
      if (firebaseLoginResult?.data?.success) {
        localStorage.setItem("firebaseToken", firebaseToken);
        handleAfterFirebaseLoginSuccess(firebaseLoginResult, firebaseToken);
        setConfirmingOtp(false);
      }
    },
  });

  useEffect(() => {
    const handleResize = (e) => {
      setHeight(e.target.height - 170);
    };
    window.visualViewport.addEventListener("resize", handleResize);
    return () =>
      window.visualViewport.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setupOtpResendTimeLeft();
  }, [sendingOtp]);

  useEffect(() => {
    let tempOtp = "";
    for (let i = 0; i < otpDigits.length; i++) {
      tempOtp += otpDigits[i];
    }
    setOtpValidated(tempOtp?.length === 6);
    setConsolidatedOtp(tempOtp);
  }, [`${otpDigits}`]);

  useEffect(() => {
    setProceedButtonDisabled(!otpValidated || confirmingOtp);
  }, [otpValidated, confirmingOtp]);

  useEffect(() => {
    const handleEnterButtonClick = (event) => {
      if (
        event.key === "Enter" &&
        !proceedButtonDisabled &&
        drawerStage === LOGIN_DRAWER_STAGES.ENTER_OTP
      ) {
        handleValidateOtp();
      }
    };

    const onPasteOtp = (event) => {
      setOtpDigits(event.clipboardData.getData("text"));
    };

    window.addEventListener("keydown", handleEnterButtonClick);
    window.addEventListener("paste", onPasteOtp);
    return () => {
      window.removeEventListener("keydown", handleEnterButtonClick);
      window.removeEventListener("paste", onPasteOtp);
    };
  }, [proceedButtonDisabled, drawerStage]);

  const handleOtpChange = (e) => {
    if (e.target.value === "" && lastOtpIndexUpdated >= 0) {
      let tempOtpDigits = otpDigits;
      tempOtpDigits[lastOtpIndexUpdated] = "";
      setOtpDigits(tempOtpDigits);
      setLastOtpIndexUpdated((prevState) => prevState - 1);
      setOtpIndexWithFocus(lastOtpIndexUpdated);
      setTimeout(() => {
        ref?.current?.setSelectionRange(2, 2);
      }, 10);
    } else if (/^\d+$/.test(e.target.value)) {
      if (
        (e.target.value.length === 1 || e.target.value.length === 2) &&
        lastOtpIndexUpdated < 5
      ) {
        let tempOtpString = e.target.value;
        let tempOtpDigits = otpDigits;
        if (tempOtpString.length === 1) {
          tempOtpDigits[lastOtpIndexUpdated + 1] = tempOtpString;
        } else {
          tempOtpDigits[lastOtpIndexUpdated + 1] = tempOtpString[1];
        }
        setOtpDigits(tempOtpDigits);
        setLastOtpIndexUpdated((prevState) => prevState + 1);
        if (lastOtpIndexUpdated !== 4) {
          setOtpIndexWithFocus(lastOtpIndexUpdated + 2);
        }
      } else if (e.target.value.length === 6) {
        let tempOtpDigits = ["", "", "", "", "", ""];
        let tempOtpString = e.target.value;
        for (let i = 0; i < tempOtpString.length; i++) {
          tempOtpDigits[i] = tempOtpString[i];
        }
        setOtpDigits(tempOtpDigits);
        setLastOtpIndexUpdated(tempOtpString.length - 1);
        setOtpIndexWithFocus(tempOtpString.length - 1);
      }
    }
  };

  const handleOtpFocusChange = () => {
    ref?.current?.focus();
  };

  const handleFocusToggleOnOtp = (focusVal) => {
    if (focusVal) {
      if (lastOtpIndexUpdated < 5) {
        setOtpIndexWithFocus(lastOtpIndexUpdated + 1);
      } else {
        setOtpIndexWithFocus(lastOtpIndexUpdated);
      }
    } else {
      setOtpIndexWithFocus(-1);
    }
  };

  const handleValidateOtp = async () => {
    setConfirmingOtp(true);
    verifyFirebase();
  };

  const verifyFirebase = async () => {
    try {
      const confirmOtpResult = await otpResult.confirm(consolidatedOtp);
      const uuid = confirmOtpResult.user.uid;
      const creatorSource = localStorage.getItem("creatorSource");
      postFirebaseLoginMutation.mutate({
        phoneNumberWithoutCountryCode: phoneNumber,
        countryCode: countryCode,
        uuid: uuid,
        allowWhatsappChat: false,
        source: creatorSource,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const handleAfterFirebaseLoginSuccess = (
    firebaseLoginResult,
    firebaseToken,
  ) => {
    localStorage.removeItem("adminToken");
    localStorage.removeItem("username");
    localStorage.setItem("firebaseToken", firebaseToken);
    if (
      firebaseLoginResult?.success &&
      firebaseLoginResult?.data?.creator_exists
    ) {
      navigate(PAGE_URLS.HOME);
      localStorage.setItem("fireLoginEvent", true);
    } else if (
      firebaseLoginResult?.success &&
      !firebaseLoginResult?.data?.creator_exists
    ) {
      navigate(PAGE_URLS.SIGNUPFORM);
    }
  };

  const handleKeyboardNextClickOtpVerification = async (
    otpValidationStatus,
  ) => {
    if (otpValidationStatus) {
      await handleValidateOtp();
    }
    handleOtpFocusChange();
  };

  const handleResendOtp = () => {
    setupOtpResendTimeLeft();
    sendOtp(countryCode, phoneNumber, true);
  };

  const setupOtpResendTimeLeft = () => {
    setOtpResendTimeLeft(30);
    if (otpResendIntervalId === 0) {
      let interval = setInterval(() => {
        setOtpResendTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      setOtpResendIntervalId(interval);
    }
    return () => clearInterval(otpResendIntervalId);
  };

  useEffect(() => {
    if (syncVerifyingOtp) syncVerifyingOtp(confirmingOtp);
  }, [confirmingOtp]);

  const onChange = (input) => {
    setOtpDigits(input);
    setOtpIndexWithFocus(input.length);
  };

  return (
    <>
      {sendingOtp || confirmingOtp ? (
        <div
          className="flex align-start justify-center"
          style={{ height: height + "px" }}
        >
          <Spinner size="1rem" />
        </div>
      ) : (
        <div
          className="flex flex-column justify-between"
          style={{ height: height + "px" }}
        >
          <div
            onFocus={() => {
              handleFocusToggleOnOtp(true);
            }}
            onBlur={() => {
              handleFocusToggleOnOtp(false);
            }}
            className="flex flex-column align-stretch justify-stretch pl-4 pr-4"
          >
            <div className="flex justify-between align-center">
              <OtpInputLoop
                otpDigits={otpDigits}
                otpIndexWithFocus={otpIndexWithFocus}
                handleOtpChange={handleOtpChange}
                handleOtpFocusChange={handleOtpFocusChange}
                handleKeyboardNextClickOtpVerification={
                  handleKeyboardNextClickOtpVerification
                }
                otpValidated={otpValidated}
                inputRef={ref}
              />
            </div>
            {otpResendTimeLeft > 0 ? (
              <WishlinkTypography
                fontWeight={400}
                fontSize="0.75rem"
                lineHeight="1.125rem"
                color={"#333333"}
                className="mt-4 pr-4"
              >
                {"Resend OTP in"}&nbsp;{otpResendTimeLeft}
                &nbsp;{"seconds"}
              </WishlinkTypography>
            ) : (
              <WishlinkTypography
                fontWeight={600}
                fontSize="0.75rem"
                lineHeight="1.125rem"
                color={"#7B78FD"}
                className="mt-4 pr-4"
                onClick={() => handleResendOtp()}
              >
                Send OTP again
              </WishlinkTypography>
            )}
          </div>
          <OtpVerificationButton
            getOpacityForDisabledValue={getOpacityForDisabledValue}
            proceedButtonDisabled={proceedButtonDisabled}
            handleButtonClick={handleValidateOtp}
            confirmingOtp={confirmingOtp}
            onChange={onChange}
          />
        </div>
      )}
    </>
  );
};

export default OtpInput;
