import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkEditableInput from "../../WishlinkComponents/WishlinkEditableInput";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import { useFetchCreatorData } from "../../apiLayer/Home/index";
import { useEffect, useState } from "react";
import { useUpdateCreatorProfileData } from "../../apiLayer/Profile";
import { triggerEvent } from "../../utils/honasa-events";
import { showToast } from "../../features/Notification";
import { useDispatch } from "react-redux";

const CreatorName = () => {
  const {
    data: creatorData,
    isLoading: creatorDataIsLoading,
    refetch: getCreatorData,
  } = useFetchCreatorData({ enabled: false });
  // const { name: creatorName = "" } = creatorData?.data || {};

  const [editMode, setEditMode] = useState(false);
  const [creatorName, setCreatorName] = useState(creatorData?.data?.name);
  const dispatch = useDispatch();

  function refetchCreatorDataAndShowToast() {
    getCreatorData();
    dispatch(
      showToast({
        variant: "success",
        message: "Name updated successfully",
      }),
    );
  }

  const { mutate } = useUpdateCreatorProfileData({
    onSuccess: refetchCreatorDataAndShowToast,
  });

  async function onClickCheckMark() {
    const body = {
      name: creatorName,
    };
    mutate(body);
    setEditMode(false);
    triggerEvent({
      eventName: "profile_name_update_cta_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Profile",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "profile_name_update",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  }

  function onClickCross() {
    setEditMode(false);
    setCreatorName(creatorData.data.name);
    triggerEvent({
      eventName: "profile_name_update_cancel_cta_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Profile",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "profile_name_update_cancel",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  }

  useEffect(() => {
    setCreatorName(creatorData?.data?.name);
  }, [creatorData]);

  return (
    <div className="p-4 pb-1" style={{ marginTop: "3rem" }}>
      <WishlinkTypography
        color={"#333333"}
        fontSize="1rem"
        fontWeight={500}
        className="text-left mb-3"
      >
        Name
      </WishlinkTypography>
      {creatorDataIsLoading ? (
        <ValueSkeleton
          isLoading={creatorDataIsLoading}
          width="100%"
          style={{ height: "3rem" }}
        />
      ) : (
        <div className="text-left border-radius mb-2">
          <WishlinkEditableInput
            value={creatorName}
            editMode={editMode}
            multiline={true}
            setEditMode={(editVal) => {
              setEditMode(editVal);
            }}
            placeholder="Add your name here"
            error={creatorName?.length > 20}
            helperText="Name should have maximum 20 characters"
            onInputValueChange={(val) => setCreatorName(val)}
            onClickCheckMark={onClickCheckMark}
            onClickCross={onClickCross}
          />
        </div>
      )}
    </div>
  );
};

export default CreatorName;
