import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { BASE_URL, IMAGE_PREFIX } from "../../utils/constants";
import WishlinkModal from "../../WishlinkComponents/WishlinkModal";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { getCroppedImg } from "../../utils/constants.js";
import { cover_pic_crop, profile_pic_crop } from "../../utils/constants";
import AboutDrawers from "./AboutDrawers.jsx";
import AboutSkeletons from "./AboutSkeletons.jsx";
import CreatorName from "./CreatorName";
import { useFetchCheckTaskStatusData } from "../../apiLayer/Profile/index.js";
import { useFetchCreatorData } from "../../apiLayer/Home/index.js";
import { triggerEvent } from "../../utils/honasa-events.js";

const About = (props) => {
  const { creatorData } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const coverFileInput = useRef(null);
  const imgRef = useRef(null);

  const aspect = 45 / 13;

  const profileFileInput = useRef(null);
  const profileImgRef = useRef(null);

  const [showModal, setShowModal] = useState(false);

  const [crop, setCrop] = useState(cover_pic_crop);
  const [imgSrc, setImgSrc] = useState("");

  const [profileCrop, setProfileCrop] = useState(profile_pic_crop);
  const [profileImgSrc, setProfileImgSrc] = useState("");
  const [profileCompletedCrop, setProfileCompletedCrop] = useState();

  const [isPolling, setIsPolling] = useState(false);
  const [intervalId, setIntervalId] = useState(0);

  const [profileDrawer, setProfileDrawer] = useState(false);
  const [coverDrawer, setCoverDrawer] = useState(false);
  const [celeryTaskId, setCeleryTaskId] = useState("");

  const handleUploadImageChange = (event, type) => {
    const fileSize = event.target.files[0]?.size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb > 10) {
      return;
    }
    navigate("#image-upload");
    const reader = new FileReader();
    reader.onload = () => {
      if (type === "cover") setImgSrc(reader.result?.toString() || "");
      else setProfileImgSrc(reader.result?.toString() || "");
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const { refetch: getCreatorData } = useFetchCreatorData({ enabled: false });

  const { data: taskStatusResult, refetch: getTaskStatus } =
    useFetchCheckTaskStatusData({ celeryTaskId, enabled: false });

  useEffect(() => {
    if (taskStatusResult?.success) setIsPolling(false);
  }, [taskStatusResult]);

  useEffect(() => {
    if (location.hash === "#profile") {
      setProfileDrawer(true);
      setCoverDrawer(false);
      setShowModal(false);
    } else if (location.hash === "#cover") {
      setProfileDrawer(false);
      setCoverDrawer(true);
      setShowModal(false);
    } else if (location.hash === "#image-upload") {
      setShowModal(true);
      setProfileDrawer(false);
      setCoverDrawer(false);
    } else {
      setProfileDrawer(false);
      setCoverDrawer(false);
      setShowModal(false);
    }
  }, [location]);

  useEffect(() => {
    if (!isPolling && intervalId !== 0) {
      clearInterval(intervalId);
      getCreatorData();
    }
  }, [isPolling]);

  async function uploadCroppedImage(ref, crop, key) {
    const croppedImg = await getCroppedImg(ref, crop);
    const formData = new FormData();
    formData.append(key, croppedImg);
    const response = await fetch(`${BASE_URL}r/updateResellerProfile`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${localStorage.getItem("firebaseToken")}`,
      },
      body: formData,
    });
    response.json().then((updatedProfile) => {
      if (updatedProfile?.success) {
        setIsPolling(true);
        setCeleryTaskId(updatedProfile?.data?.profile_upload_task_id);
        pollCeleryTaskStatus(updatedProfile?.data?.profile_upload_task_id);
      }
    });
    closeModal();
  }

  async function pollCeleryTaskStatus() {
    let interval = setInterval(async () => {
      getTaskStatus();
    }, 1000);
    setIntervalId(interval);
  }

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  function onImageLoad(e, type) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      if (type === "cover") setCrop(centerAspectCrop(width, height, aspect));
      else setProfileCrop(centerAspectCrop(width, height, 1));
    }
  }

  function closeModal() {
    setShowModal(false);
    setImgSrc("");
    setProfileImgSrc("");
    triggerEvent({
      eventName: "profile_pic_cancel_cta_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Profile",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "profile_pic_cancel_click",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    navigate(-1, { replace: true });
  }

  function onProfilePicChoose() {
    navigate(-1, { replace: true });
    profileFileInput.current.click();
  }

  function onCoverPicChoose() {
    navigate(-1, { replace: true });
    coverFileInput.current.click();
  }

  return (
    <>
      <div className="relative" style={{ width: "inherit" }}>
        <AboutSkeletons />
        <img
          src={`${IMAGE_PREFIX}upload-image.svg`}
          style={{ position: "absolute", top: "8.5rem", left: "6rem" }}
          onClick={(e) => navigate("#profile")}
          alt=""
        />
        <input
          ref={profileFileInput}
          type="file"
          name="cover_pic"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={(event) => {
            handleUploadImageChange(event, "profile");
          }}
        />
      </div>
      <CreatorName />
      <WishlinkModal
        showModal={showModal}
        setShowModal={setShowModal}
        showCloseIcon={false}
      >
        <div className="text-center">
          <WishlinkTypography className="text-center pt-4 pb-4">
            Drag an area to crop
          </WishlinkTypography>
          <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
            {!!profileImgSrc && (
              <ReactCrop
                crop={profileCrop}
                onChange={(_, percentCrop) => setProfileCrop(percentCrop)}
                onComplete={(c) => setProfileCompletedCrop(c)}
                style={{ border: `0.06rem solid #333333` }}
                aspect={1}
                circularCrop={true}
              >
                <div className="flex justify-center align-center overflow-hidden">
                  <img
                    ref={profileImgRef}
                    alt=""
                    src={profileImgSrc}
                    onLoad={(e) => onImageLoad(e, "cover")}
                    style={{
                      flexShrink: 0,
                      maxHeight: "70vh",
                      maxWidth: "100%",
                    }}
                  />
                </div>
              </ReactCrop>
            )}
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={() => {}}
                style={{ border: `0.06rem solid #333333` }}
                aspect={aspect}
              >
                <div className="flex justify-center align-center overflow-hidden">
                  <img
                    ref={imgRef}
                    alt=""
                    src={imgSrc}
                    style={{
                      flexShrink: 0,
                      maxHeight: "70vh",
                      maxWidth: "100%",
                    }}
                    onLoad={(e) => onImageLoad(e, "profile")}
                  />
                </div>
              </ReactCrop>
            )}
          </div>
        </div>
        <div className="flex justify-between">
          <WishlinkButton onClick={() => closeModal()}>Cancel</WishlinkButton>
          <WishlinkButton
            onClick={() => {
              uploadCroppedImage(
                profileImgRef.current,
                profileCompletedCrop,
                "profile_pic",
              );
              triggerEvent({
                eventName: "profile_pic_done_cta_click",
                eventAttributes: {
                  page_url: window.location.href,
                  page_name: "Profile",
                  user_id: creatorData?.data?.id,
                  timestamp: new Date().toISOString(),
                  event_type: "click",
                  product_name: "",
                  product_price: "",
                  element: "profile_pic_done_click",
                  gender: creatorData?.data?.gender,
                  added_on: creatorData?.data?.added_on,
                },
              });
            }}
          >
            Done, Upload
          </WishlinkButton>
        </div>
      </WishlinkModal>
      <AboutDrawers
        profileDrawer={profileDrawer}
        coverDrawer={coverDrawer}
        onProfilePicChoose={onProfilePicChoose}
        onCoverPicChoose={onCoverPicChoose}
      />
    </>
  );
};

export default About;
