import { useNavigate, useLocation } from "react-router-dom";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import { triggerEvent } from "../../utils/honasa-events";

const Footer = (props) => {
  const { creatorData } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const navigationItems = [
    {
      url: PAGE_URLS.PROFILE,
      icon:
        location.pathname === PAGE_URLS.PROFILE
          ? `${IMAGE_PREFIX}profile-orange.png`
          : `${IMAGE_PREFIX}profile-grey.png`,
      fontColor:
        location.pathname === PAGE_URLS.PROFILE ? "#DF472C" : "#808080",
      isVisible: true,
      title: "PROFILE",
      navigateRoute: PAGE_URLS.PROFILE,
    },
    {
      url: PAGE_URLS.HOME,
      icon:
        location.pathname === PAGE_URLS.HOME
          ? `${IMAGE_PREFIX}homeOrange.svg`
          : `${IMAGE_PREFIX}home.svg`,
      fontColor: location.pathname === PAGE_URLS.HOME ? "#DF472C" : "#808080",
      isVisible: true,
      title: "HOME",
      navigateRoute: PAGE_URLS.HOME,
    },
    {
      url: PAGE_URLS.PRODUCTS,
      icon:
        location.pathname === PAGE_URLS.PRODUCTS
          ? `${IMAGE_PREFIX}productsOrange.png`
          : `${IMAGE_PREFIX}products-grey.png`,
      fontColor:
        location.pathname === PAGE_URLS.PRODUCTS ? "#DF472C" : "#808080",
      isVisible: true,
      title: "PRODUCTS",
    },
    {
      url: PAGE_URLS.ANALYTICSHOME,
      icon:
        location.pathname === PAGE_URLS.ANALYTICSHOME
          ? `${IMAGE_PREFIX}analyticsOrange.svg`
          : `${IMAGE_PREFIX}analytics-grey.svg`,
      fontColor:
        location.pathname === PAGE_URLS.ANALYTICSHOME ? "#DF472C" : "#808080",
      isVisible: true,
      title: "ANALYTICS",
    },
  ];

  return (
    <div
      className="fixed footer"
      style={{
        bottom: "0rem",
        background: "#FFFFFF",
        height: "5rem",
        zIndex: 100,
      }}
    >
      <div
        className="flex justify-around align-center h-100"
        style={{ boxShadow: "0px -1px 4px 0px rgba(0, 0, 0, 0.10)" }}
      >
        {navigationItems.map((item, idx) => {
          return item.isVisible ? (
            <div
              className="flex flex-column align-center justify-center w-100 h-100"
              onClick={() => {
                navigate(item.url);
                triggerEvent({
                  eventName: "footer_item_click",
                  eventAttributes: {
                    page_url: window.location.href,
                    page_name: window.location.href,
                    user_id: creatorData?.data?.id,
                    timestamp: new Date().toISOString(),
                    event_type: "click",
                    product_name: "",
                    product_price: "",
                    element: `${item.title}_footer`,
                    gender: creatorData?.data?.gender,
                    added_on: creatorData?.data?.added_on,
                  },
                });
              }}
              key={idx}
            >
              <img
                className="mb-1"
                src={item.icon}
                style={{ height: "1.5rem" }}
                alt=""
              />
              <WishlinkTypography
                fontSize="0.625rem"
                fontWeight={500}
                lineHeight="0.9375rem"
                color={item.fontColor}
              >
                {item.title}
              </WishlinkTypography>
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
