import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import WishlinkModal from "../../WishlinkComponents/WishlinkModal";
import WishlinkRadioButton from "../../WishlinkComponents/WishlinkRadioButton";
import {
  useFetchCreatorAddressData,
  useUpdateCreatorAddressData,
} from "../../apiLayer/Profile";
import { useFetchCreatorData } from "../../apiLayer/Home/index";
import { triggerEvent } from "../../utils/honasa-events";
import { showToast } from "../../features/Notification";
import { useDispatch } from "react-redux";

const AddressList = (props) => {
  const navigate = useNavigate();
  const { data: creatorAddress, refetch: refetchCreatorAddresses } =
    useFetchCreatorAddressData({
      enabled: true,
    });
  const { data: creatorData } = useFetchCreatorData({ enabled: true });
  const isFromCart = props.source === "cart";
  const isFromProfile = props.source === "profile";
  const { replace } = props;
  const [showModal, setShowModal] = useState(false);
  const [currentAddressId, setCurrentAddressId] = useState(0);
  const dispatch = useDispatch();
  const isChecked = (address) => {
    if (isFromCart) {
      if (!props.selectedAddress) return false;
      return address.id === props.selectedAddress.id;
    } else if (isFromProfile) {
      return address.is_default;
    }
  };

  const { refetch: getCreatorData } = useFetchCreatorData({ enabled: false });

  const { mutate } = useUpdateCreatorAddressData({
    onSuccess: () => {
      getCreatorData();
      refetchCreatorAddresses();
    },
  });

  const onClickRemoveAddress = (address) => {
    if (address.is_default) {
      dispatch(
        showToast({
          variant: "error",
          message: "Default Address cannot be removed",
        }),
      );
      return;
    }
    setShowModal(true);
    setCurrentAddressId(address.id);
  };

  const onClickConfirmDelete = async () => {
    const body = {
      address_id: currentAddressId,
      is_alive: false,
    };
    mutate(body);
    if (isFromCart) {
      navigate(`${PAGE_URLS.SOURCING_CART}#select-address`, { replace: true });
    } else navigate(PAGE_URLS.PROFILE, { replace: true });

    setShowModal(false);
  };

  return (
    <>
      <div>
        {creatorAddress?.data?.map((address, idx) => {
          return (
            <div key={idx} className="pt-5">
              <div className="flex justify-between align-center">
                <div className="flex justify-start">
                  <WishlinkTypography
                    color={"#333333"}
                    fontSize="1rem"
                    fontWeight={500}
                    lineHeight="1.5rem"
                    className="text-left"
                  >
                    {address.tag}
                  </WishlinkTypography>
                  {address.is_default && (
                    <WishlinkTypography
                      color={"#666666"}
                      fontSize="0.6875rem"
                      fontWeight={400}
                      lineHeight="1.5rem"
                      className="text-center ml-3 pl-2 pr-2"
                      style={{
                        background: "#F1F4F9",
                        border: `0.06rem solid #E5E5E5`,
                        borderRadius: "0.25rem",
                      }}
                    >
                      Default
                    </WishlinkTypography>
                  )}
                </div>
                <WishlinkRadioButton
                  handleChange={() => props.onClickRadioBtn(address)}
                  checked={isChecked(address)}
                />
              </div>
              <WishlinkTypography
                color={"#333333"}
                fontSize="0.925rem"
                fontWeight={400}
                lineHeight="1.5rem"
                className="text-left"
              >
                {(address.name || "Not found") +
                  ", " +
                  (address.phone_number || "")}
              </WishlinkTypography>
              <WishlinkTypography
                color={"#666666"}
                fontSize="0.875rem"
                fontWeight={400}
                lineHeight="1.5rem"
                className="text-left"
              >
                {address.address_line +
                  ", " +
                  address.locality +
                  ", " +
                  address.pincode +
                  ", " +
                  address.city}
              </WishlinkTypography>
              <div className="flex justify-start mt-3 mb-5">
                <WishlinkButton
                  className="border-pink border-radius"
                  height="2rem"
                  onClick={() => {
                    onClickRemoveAddress(address);
                  }}
                >
                  <WishlinkTypography
                    fontWeight={500}
                    fontSize="0.875rem"
                    lineHeight="1.3125rem"
                    color={"#E91E63"}
                    className="m-1"
                  >
                    Remove
                  </WishlinkTypography>
                </WishlinkButton>
                <WishlinkButton
                  className="border-pink border-radius ml-3"
                  height="2rem"
                  onClick={() => {}}
                >
                  <WishlinkTypography
                    fontWeight={500}
                    fontSize="0.875rem"
                    lineHeight="1.3125rem"
                    color={"#E91E63"}
                    className="m-1 pl-3 pr-3"
                    onClick={() => {
                      navigate(`/address/${address.id}`, { replace: replace });
                      triggerEvent({
                        eventName: "edit_btn_click",
                        eventAttributes: {
                          page_url: window.location.href,
                          page_name: "Address",
                          user_id: creatorData?.data?.id,
                          timestamp: new Date().toISOString(),
                          event_type: "click",
                          product_name: "",
                          product_price: "",
                          element: "edit_address_from_drawer",
                          gender: creatorData?.data?.gender,
                          added_on: creatorData?.data?.added_on,
                        },
                      });
                    }}
                  >
                    Edit
                  </WishlinkTypography>
                </WishlinkButton>
              </div>
              <Divider />
            </div>
          );
        })}
        <div
          style={{
            cursor: "pointer",
          }}
          className="flex justify-start align-center mt-3 mb-3"
          onClick={() => {
            if (isFromCart) {
              navigate(`/address/new#cart`);
            } else {
              navigate(`/address/new`);
            }
            triggerEvent({
              eventName: "add_address_cta_click",
              eventAttributes: {
                page_url: window.location.href,
                page_name: "Address",
                user_id: creatorData?.data?.id,
                timestamp: new Date().toISOString(),
                event_type: "click",
                product_name: "",
                product_price: "",
                element: "add_new_address_from_drawer",
                gender: creatorData?.data?.gender,
                added_on: creatorData?.data?.added_on,
              },
            });
          }}
        >
          <img src={`${IMAGE_PREFIX}pluscircle.svg`} alt="" />
          <WishlinkTypography
            fontWeight={500}
            fontSize="0.875rem"
            lineHeight="1.3125rem"
            color={"#E91E63"}
            className="m-1"
          >
            Add New Address
          </WishlinkTypography>
        </div>
      </div>
      <WishlinkModal
        showModal={showModal}
        setShowModal={setShowModal}
        showCloseIcon={false}
      >
        <WishlinkTypography
          fontWeight={500}
          fontSize="1rem"
          lineHeight="1.3125rem"
          color={"#141414"}
          className="m-1 text-center"
        >
          Remove Address
        </WishlinkTypography>
        <WishlinkTypography
          fontWeight={400}
          fontSize="0.875rem"
          lineHeight="1.3125rem"
          color={"#666666"}
          className="mt-3 text-center"
        >
          Are you sure you want to delete this address? This action cannot be
          undone
        </WishlinkTypography>
        <div className="flex justify-end mt-3">
          <WishlinkTypography
            fontWeight={500}
            fontSize="0.875rem"
            style={{ cursor: "pointer" }}
            lineHeight="1.3125rem"
            color={"#E91E63"}
            className="m-1 pl-3 pr-3"
            onClick={() => {
              onClickConfirmDelete().then();
            }}
          >
            CONFIRM
          </WishlinkTypography>
          <WishlinkTypography
            fontWeight={500}
            fontSize="0.875rem"
            lineHeight="1.3125rem"
            color={"#E91E63"}
            style={{ cursor: "pointer" }}
            className="m-1 pl-3 pr-3"
            onClick={() => {
              setShowModal(false);
            }}
          >
            CANCEL
          </WishlinkTypography>
        </div>
      </WishlinkModal>
    </>
  );
};

export default AddressList;
