import { useEffect } from "react";
import { useFetchCreatorData } from "../../apiLayer/Home";
import Header from "../../components/Header/index";
import About from "../../components/Profile/About";
import AccountDetails from "../../components/Profile/AccountDetails";
import AdditionalInfo from "../../components/Profile/AdditionalInfo";
import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { triggerEvent } from "../../utils/honasa-events";

const Profile = () => {
  const { data: creatorData, isLoading: creatorDataIsLoading } =
    useFetchCreatorData({ enabled: true });
  const { name = "", profile_pic_url = "" } = creatorData?.data || {};

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "profile_pl",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Profile",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "page_load",
          product_name: "",
          product_price: "",
          element: "",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  return (
    <div style={{ minHeight: window.innerHeight }}>
      <Header
        creatorDataIsLoading={creatorDataIsLoading}
        creatorName={name}
        creatorImage={profile_pic_url}
        showBackButton={true}
        showHamburgerIcon={false}
        eventPage="Profile"
      />
      <About creatorData={creatorData} />
      <div
        className="w-100"
        style={{ background: "#f5f5f5", height: "12px" }}
      ></div>
      <AdditionalInfo />
      <div
        className="w-100"
        style={{ background: "#f5f5f5", height: "12px" }}
      ></div>
      <AccountDetails />
      <div
        className="w-100"
        style={{ background: "#f5f5f5", height: "12px" }}
      ></div>
      <div
        className="flex justify-center align-center mt-5 pt-1 pb-5"
        style={{ paddingBottom: "7rem" }}
      >
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={400}
          color={"#333333"}
        >
          ⚡ Powered by
        </WishlinkTypography>
        <img
          className="ml-1"
          src={`${IMAGE_PREFIX}wishlink-orange.png`}
          style={{ width: "20%" }}
          alt=""
        />
      </div>
    </div>
  );
};

export default Profile;
