import { useNavigate } from "react-router-dom";

import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import { triggerEvent } from "../../utils/honasa-events";

const CreatorEarning = (props) => {
  const { earning, idx, isLoading, creatorData } = props;
  const navigate = useNavigate();

  function onClickEarningsCard() {
    if (idx === 1) {
      navigate(PAGE_URLS.ANALYTICSHOME);
      triggerEvent({
        eventName: "earnings_card_cta_click",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "click",
          product_name: "",
          product_price: "",
          element: "net_commissions_payout_home",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    } else {
      navigate(PAGE_URLS.PAYOUTS);
      triggerEvent({
        eventName: "net_commissions_today_cta_click",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "click",
          product_name: "",
          product_price: "",
          element: "net_commissions_today_home",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }

  return (
    <div
      className="p-4 border-radius"
      style={{ background: earning.background, height: "6rem" }}
      onClick={() => onClickEarningsCard()}
    >
      <WishlinkTypography
        fontSize="0.75rem"
        fontWeight={500}
        color={"#ffffff"}
        lineHeight="1.125rem"
        className="text-left"
      >
        {earning.title}
      </WishlinkTypography>
      <div
        className={`flex align-center justify-left ${idx === 1 ? "mt-1" : "mt-5"}`}
        style={{ color: "#fff" }}
      >
        {earning.isNegative ? "-" : ""}
        <img src={`${IMAGE_PREFIX}rupee.svg`} alt="" />
        <WishlinkTypography
          fontSize="1.5rem"
          fontWeight={700}
          color={"#fff"}
          lineHeight="1.125rem"
          className="text-left ml-1"
        >
          <ValueSkeleton
            isLoading={isLoading}
            width="1rem"
            value={earning.amount}
          />
        </WishlinkTypography>
      </div>
      <div className="flex justify-between align-end">
        <img
          src={`${IMAGE_PREFIX}${
            idx === 1 ? `second-slide.svg` : `first-slide.svg`
          }`}
          alt=""
        />
        <img
          style={{ width: "25%" }}
          src={`${IMAGE_PREFIX}earnings-graphic.png`}
          alt=""
        />
      </div>
    </div>
  );
};

export default CreatorEarning;
