import { makeAPICall } from "../../utils/common";
import { BASE_URL } from "../../utils/constants";
import { useQuery } from "@tanstack/react-query";

export const useFetchGetDateWiseOrderSummary = ({
  startDate,
  endDate,
  page,
  enabled,
}) => {
  return useQuery({
    queryKey: ["dateWiseOrderHistory"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerDateWiseOrderSummary?startDate=${startDate}&endDate=${endDate}&onlyShowReturnedOrders=false&page=${page}&limit=100`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useFetchGetOrderHistoryForDate = ({
  fetchDate,
  page,
  enabled,
}) => {
  return useQuery({
    queryKey: ["orderHistoryForDate", page, fetchDate],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerOrderHistoryForDate?fetchDate=${fetchDate}&onlyShowReturnedOrders=false&page=${page}`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};
