import { TextField } from "@mui/material";

const OtpDigit = (props) => {
  const {
    value,
    active,
    onClick,
    listKey,
    onFocus,
    inputRef = null,
    inputProps = {},
    autoFocus = false,
  } = props;
  const borderColor = active ? "#e91e63" : "#1B1B1B";

  const textFieldStyle = {
    width: "2.25rem",
    height: "2.25rem",
    caretColor: "transparent",
    "& .MuiOutlinedInput-root": {
      height: "100%",
      width: "100%",
      display: "flex",
      fontFamily: "Poppins",
      "& .MuiOutlinedInput-notchedOutline": {
        width: "100%",
        height: "100%",
        borderStyle: "none",
        "& legend": {
          display: "none",
        },
      },
      "& .MuiInputBase-input": {
        width: "100%",
        height: "100%",
        padding: "0rem !important",
        textAlign: "center",
        border: `0.1rem solid ${borderColor}`,
        borderRadius: "0.25rem",
      },
    },
  };

  return (
    <TextField
      type="tel"
      key={listKey}
      sx={textFieldStyle}
      value={value}
      onClick={onClick}
      onFocus={onFocus}
      inputRef={inputRef}
      inputProps={inputProps}
      autoFocus={autoFocus}
    />
  );
};

export default OtpDigit;
