import { useNavigate } from "react-router-dom";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { useEffect, useState } from "react";
import ReferrerDetails from "../../components/ReferrerDetails";

const Login = () => {
  const navigate = useNavigate();
  const [lottieHeight, setLottieHeight] = useState(0);

  const calculateLottieHeight = () => {
    if (window.innerHeight > 775) {
      return localStorage.getItem("referee") !== null
        ? window.innerHeight / 1.95 - 0.5
        : window.innerHeight / 1.8 - 0.5;
    }
    if (window.innerHeight > 600) {
      return localStorage.getItem("referee") !== null
        ? window.innerHeight / 2.35 - 0.5
        : window.innerHeight / 2.2 - 0.5;
    }
    if (window.innerHeight > 450) {
      return localStorage.getItem("referee") !== null
        ? window.innerHeight / 3.35 - 0.5
        : window.innerHeight / 2.9 - 0.5;
    }
    return localStorage.getItem("referee") !== null
      ? window.innerHeight / 2.25 - 0.5
      : window.innerHeight / 2.1 - 0.5;
  };

  useEffect(() => {
    setLottieHeight(calculateLottieHeight());
  }, []);

  return (
    <div
      style={{
        width: "inherit",
        minHeight: window.innerHeight,
        background:
          "linear-gradient(358.18deg, #E78021 1.54%, rgba(227, 129, 37, 0.9) 102.46%)",
      }}
    >
      <div style={{ height: "4.75rem" }}>
        <img
          className="mt-8"
          style={{
            width: "30%",
          }}
          src={`${IMAGE_PREFIX}Wishlink-White-2x.png`}
          alt=""
        />
      </div>
      <div style={{ height: "5.625rem" }}>
        <WishlinkTypography
          fontWeight={700}
          fontSize="1.25rem"
          color={"#ffffff"}
          className="pl-3 pr-3 mt-5 visibility-hidden"
        >
          EARN FROM ALL YOUR CONTENT
        </WishlinkTypography>
        <WishlinkTypography
          fontWeight={400}
          fontSize="1.25rem"
          color={"#ffffff"}
          lineHeight="1.45rem"
        >
          Content with which you are currently earning money
        </WishlinkTypography>
      </div>
      <div style={{ height: lottieHeight }} className="m-2 flex justify-center">
        <img
          src={`${IMAGE_PREFIX}acquisitionScreenEarnings.png`}
          className="h-auto block"
          style={{
            maxHeight: lottieHeight,
            maxWidth: "100%",
            objectFit: "contain",
          }}
          alt=""
        />
      </div>
      <div style={{ height: "5.0625rem" }}>
        <WishlinkTypography
          fontWeight={400}
          fontSize="1.125rem"
          color={"#ffffff"}
          className="pl-7 pr-7"
          lineHeight="1.45rem"
        >
          Content with which you can earn huge money using Wishlink
        </WishlinkTypography>
      </div>
      <div className="w-100">
        {localStorage.getItem("referee") ? <ReferrerDetails /> : <></>}
      </div>
      <WishlinkButton
        onClick={() => navigate(PAGE_URLS.WELCOME)}
        background={"#ffffff"}
        style={{
          ":hover": {
            bgcolor: "#ffffff",
          },
        }}
        className="mt-3 story-button"
        buttonClassName="bs-2"
        borderRadius="1.25rem"
        height="2.5rem"
      >
        <WishlinkTypography
          fontWeight={600}
          fontSize="0.875rem"
          color={"#e79548"}
        >
          Get Started
        </WishlinkTypography>
      </WishlinkButton>
    </div>
  );
};

export default Login;
