import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import BankDetailsSubmittedDrawer from "../BankDetails/BankDetailsSubmittedDrawer";
import BankDetailsEditingDrawers from "../BankDetails/BankDetailsEditingDrawer";
import { useFetchCreatorBankDetailsData } from "../../apiLayer/Profile";

const AccountDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [drawerState, setDrawerState] = useState(false);
  const [submittedDrawerState, setSubmittedDrawerState] = useState(false);

  const { data: bankDetailsData, isLoading: bankDetailsIsLoading } =
    useFetchCreatorBankDetailsData({ enabled: true });

  const bankDetails = bankDetailsData?.bank_details || {};

  useEffect(() => {
    if (location.hash === "#submitted") {
      setDrawerState(true);
      setSubmittedDrawerState(false);
    } else if (location.hash === "#thanks") {
      setDrawerState(false);
      setSubmittedDrawerState(true);
      setTimeout(() => {
        navigate(PAGE_URLS.PROFILE, { replace: true });
        setSubmittedDrawerState(false);
      }, 3000);
    } else {
      setDrawerState(false);
      setSubmittedDrawerState(false);
    }
  }, [location]);

  return (
    <div className="p-4">
      <WishlinkTypography
        color={"#333333"}
        fontSize="1rem"
        fontWeight={500}
        className="text-left"
      >
        Account Details
      </WishlinkTypography>
      <WishlinkTypography
        color={"#666666"}
        fontSize="0.75rem"
        fontWeight={400}
        lineHeight="1.125rem"
        className="text-left"
      >
        Payouts will be done in the first week of every month in the following
        account
      </WishlinkTypography>
      {bankDetailsIsLoading ? (
        <ValueSkeleton
          isLoading={bankDetailsIsLoading}
          width="100%"
          style={{ height: "3rem" }}
        />
      ) : (
        <>
          {Object.keys(bankDetails).length === 0 ? (
            <div
              style={{ border: `0.06rem solid ${"#E5E5E5"}` }}
              className="border-radius flex align-center justify-between p-4 mt-2"
              onClick={() => {
                navigate(`/bank-details`);
              }}
            >
              <WishlinkTypography
                color={"#c2c2c2"}
                fontSize="0.75rem"
                fontWeight={400}
                fontStyle="italic"
              >
                Add your bank details here
              </WishlinkTypography>
              <img src={`${IMAGE_PREFIX}rightIconGrey.svg`} alt="" />
            </div>
          ) : (
            <div onClick={() => navigate("#submitted")}>
              {bankDetails?.pan && bankDetails?.pan_name ? (
                <>
                  <div className="flex justify-between align-center">
                    <div>
                      <WishlinkTypography
                        color={"#333333"}
                        fontSize="0.875rem"
                        fontWeight={500}
                        lineHeight="1.312rem"
                        className="text-left mt-4"
                      >
                        PAN: {bankDetails?.pan_masked}
                      </WishlinkTypography>
                      <WishlinkTypography
                        color={"#666666"}
                        fontSize="0.875rem"
                        fontWeight={400}
                        lineHeight="1.312rem"
                        className="text-left"
                      >
                        Registered Name:
                      </WishlinkTypography>
                      <WishlinkTypography
                        color={"#666666"}
                        fontSize="0.875rem"
                        fontWeight={400}
                        lineHeight="1.312rem"
                        className="text-left"
                        typographystyle={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                          display: "-webkit-box",
                        }}
                      >
                        {bankDetails?.pan_name}
                      </WishlinkTypography>
                    </div>
                    <img
                      src={`${IMAGE_PREFIX}rightIconGrey.svg`}
                      className="mr-2"
                      alt=""
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="flex align-center justify-between">
                <div>
                  <WishlinkTypography
                    color={"#333333"}
                    fontSize="0.875rem"
                    fontWeight={500}
                    lineHeight="1.312rem"
                    className="text-left mt-4"
                  >
                    {bankDetails?.bank_account?.bank_name}{" "}
                    {bankDetails?.bank_account?.account_number_masked}
                  </WishlinkTypography>
                  <WishlinkTypography
                    color={"#666666"}
                    fontSize="0.875rem"
                    fontWeight={500}
                    lineHeight="1.312rem"
                    className="text-left"
                  >
                    IFSC Code: {bankDetails?.bank_account?.ifsc}
                  </WishlinkTypography>
                  {bankDetails?.bank_registered_name && (
                    <>
                      <WishlinkTypography
                        color={"#666666"}
                        fontSize="0.875rem"
                        fontWeight={400}
                        lineHeight="1.312rem"
                        className="text-left"
                      >
                        Bank Registered Name:
                      </WishlinkTypography>
                      <WishlinkTypography
                        color={"#666666"}
                        fontSize="0.875rem"
                        fontWeight={400}
                        lineHeight="1.312rem"
                        className="text-left"
                        typographystyle={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                          display: "-webkit-box",
                        }}
                      >
                        {bankDetails?.bank_registered_name}
                      </WishlinkTypography>
                    </>
                  )}
                </div>
                <img
                  src={`${IMAGE_PREFIX}rightIconGrey.svg`}
                  className="mr-2"
                  alt=""
                />
              </div>
              {bankDetails.does_name_match ? (
                <></>
              ) : (
                <div
                  className="border-radius p-2 mt-7"
                  style={{ border: `0.06rem solid ${"#e91e63"}` }}
                >
                  <WishlinkTypography fontSize="0.75rem" color={"#666666"}>
                    Please ensure your registered name with the bank matches
                    your submitted PAN for TDS claims
                  </WishlinkTypography>
                </div>
              )}
            </div>
          )}
        </>
      )}
      <BankDetailsEditingDrawers drawerState={drawerState} />
      <BankDetailsSubmittedDrawer
        submittedDrawerState={submittedDrawerState}
        setSubmittedDrawerState={setSubmittedDrawerState}
      />
    </div>
  );
};

export default AccountDetails;
