import { PAGE_URLS } from "./utils/constants";
import About from "./views/About";
import Home from "./views/Home";
import Welcome from "./views/Welcome";
import Profile from "./views/Profile";
import Address from "./views/Address";
import BankDetails from "./views/BankDetails";
import Login from "./views/Login";
import AnalyticsHome from "./views/AnalyticsHome";
import Rewards from "./views/Rewards";
import OrderHistory from "./views/OrderHistory";
import SignupForm from "./views/SignupForm";
import ProductsListing from "./views/Products";
import Payouts from "./views/Payouts";
import Referrals from "./views/Referrals";
import ErrorPage from "./views/ErrorPage";

const Routes = [
  {
    path: PAGE_URLS.DEFAULT,
    exact: true,
    needFirebaseAuthentication: false,
    needResellerExists: false,
    component: <></>,
    showFooter: true,
  },
  {
    path: PAGE_URLS.HOME,
    exact: true,
    needFirebaseAuthentication: true,
    needResellerExists: true,
    component: <Home />,
    showFooter: true,
  },
  {
    path: PAGE_URLS.ABOUT,
    exact: true,
    needFirebaseAuthentication: false,
    needResellerExists: false,
    component: <About />,
    showFooter: true,
  },
  {
    path: PAGE_URLS.WELCOME,
    exact: true,
    needFirebaseAuthentication: false,
    needResellerExists: false,
    component: <Welcome />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.PROFILE,
    exact: true,
    needFirebaseAuthentication: true,
    needResellerExists: true,
    component: <Profile />,
    showFooter: true,
  },
  {
    path: PAGE_URLS.ADDRESS,
    exact: false,
    needFirebaseAuthentication: true,
    needResellerExists: true,
    component: <Address />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.BANKDETAILS,
    exact: false,
    needFirebaseAuthentication: true,
    needResellerExists: true,
    component: <BankDetails />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.LOGIN,
    exact: false,
    needFirebaseAuthentication: false,
    needResellerExists: false,
    component: <Login />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.ANALYTICSHOME,
    exact: false,
    needFirebaseAuthentication: true,
    needResellerExists: true,
    component: <AnalyticsHome />,
    showFooter: true,
  },
  {
    path: PAGE_URLS.REWARDS,
    exact: false,
    needFirebaseAuthentication: true,
    component: <Rewards />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.ORDERHISTORY,
    exact: false,
    needFirebaseAuthentication: true,
    needResellerExists: true,
    component: <OrderHistory />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.SIGNUPFORM,
    exact: false,
    needFirebaseAuthentication: true,
    needResellerExists: false,
    component: <SignupForm />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.PRODUCTS,
    exact: false,
    needFirebaseAuthentication: true,
    needResellerExists: true,
    component: <ProductsListing />,
    showFooter: true,
  },
  {
    path: PAGE_URLS.PAYOUTS,
    exact: false,
    needFirebaseAuthentication: true,
    needResellerExists: true,
    component: <Payouts />,
    showFooter: true,
  },
  {
    path: PAGE_URLS.REFERRALS,
    exact: true,
    needFirebaseAuthentication: true,
    component: <Referrals />,
    showFooter: false,
  },
  {
    path: "/*",
    component: <ErrorPage errorPage={true} />,
    showFooter: false,
  },
];

export default Routes;
