import { useNavigate } from "react-router-dom";

import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { triggerEvent } from "../../utils/honasa-events";

const BankDetailsDrawers = (props) => {
  const {
    drawerState,
    accountNumber,
    btnLoading,
    onClickPostBankDetails,
    creatorData,
  } = props;
  const navigate = useNavigate();

  return (
    <>
      <WishlinkDrawer
        drawerOpen={drawerState}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
      >
        <div className="p-4">
          <WishlinkTypography
            color={"#141414"}
            fontWeight={500}
            fontSize="1rem"
            className="text-center pt-4"
          >
            Account Confirmation
          </WishlinkTypography>
          <WishlinkTypography
            color={"#666666"}
            fontWeight={400}
            fontSize="0.875rem"
            lineHeight="1.312rem"
            className="text-center pt-1 pb-2 pl-4 pr-4"
          >
            We are adding account number {accountNumber} to your profile. Please
            confirm if you want to proceed?
          </WishlinkTypography>
          <WishlinkButton
            background={"#E91E63"}
            style={{
              ":hover": {
                bgcolor: "#E91E63",
              },
              ":disabled": {
                bgcolor: "#c2c2c2",
              },
            }}
            borderRadius="0.5rem"
            width="100%"
            height="3rem"
            className="pt-3"
            onClick={() => onClickPostBankDetails()}
            disabled={btnLoading}
          >
            <WishlinkTypography
              color={"#f5f5f5"}
              fontWeight={600}
              fontSize="1rem"
              className="p-1"
            >
              Yes, Proceed
            </WishlinkTypography>
          </WishlinkButton>
          <WishlinkButton
            border={`1px solid ${"#E91E63"}`}
            style={{
              ":hover": {
                border: `1px solid ${"#E91E63"}`,
              },
            }}
            borderRadius="0.5rem"
            width="100%"
            height="3rem"
            className="pt-3"
            onClick={() => {
              navigate(-1, { replace: true });
              triggerEvent({
                eventName: "save_details_cancel_cta_click",
                eventAttributes: {
                  page_url: window.location.href,
                  page_name: "Bank Details",
                  user_id: creatorData?.data?.id,
                  timestamp: new Date().toISOString(),
                  event_type: "click",
                  product_name: "",
                  product_price: "",
                  element: "save_bank_details_reject",
                  gender: creatorData?.data?.gender,
                  added_on: creatorData?.data?.added_on,
                },
              });
            }}
          >
            <WishlinkTypography
              color={"#E91E63"}
              fontWeight={600}
              fontSize="1rem"
              className="p-1"
            >
              No, cancel
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default BankDetailsDrawers;
