import { useNavigate } from "react-router-dom";

import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import AddressList from "./AddressList";
import {
  useFetchCreatorAddressData,
  useUpdateCreatorAddressData,
} from "../../apiLayer/Profile";
import { useFetchCreatorData } from "../../apiLayer/Home/index";

const AddressDrawer = (props) => {
  const { drawerState } = props;
  const navigate = useNavigate();

  const { refetch: getCreatorData } = useFetchCreatorData({ enabled: false });
  const { refetch: refetchCreatorAddresses } = useFetchCreatorAddressData({
    enabled: false,
  });

  const { mutate } = useUpdateCreatorAddressData({
    onSuccess: () => {
      getCreatorData();
      refetchCreatorAddresses();
    },
  });

  const onClickRadioBtn = async (address) => {
    const body = {
      address_id: address.id,
      is_default: true,
    };
    mutate(body);
  };

  return (
    <>
      <WishlinkDrawer
        drawerOpen={drawerState}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
      >
        <div className="p-4">
          <WishlinkTypography
            color={"#333333"}
            fontSize="1rem"
            fontWeight={500}
            lineHeight="1.5rem"
            className="text-center pt-4"
          >
            My Addresses
          </WishlinkTypography>
          <WishlinkTypography
            color={"#666666"}
            fontSize="0.75rem"
            fontWeight={400}
            lineHeight="1rem"
            className="text-center mt-2"
          >
            Please add your current address for us to ensure timely deliveries.
          </WishlinkTypography>
          <AddressList
            onClickRadioBtn={(address) => onClickRadioBtn(address)}
            source={"profile"}
            replace={true}
          />
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default AddressDrawer;
