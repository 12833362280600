import React from "react";
import { Grid } from "@mui/material";
import ListSkeletonElement from "../ListSkeletonElement";

export const ListSkeletonContainer = (props) => {
  const { numRows = 3, showForValue = true } = props;

  return (
    <Grid container sx={{ width: "100%" }}>
      <>
        {Array(numRows)
          .fill(0)
          .map((placeholder, idx) => (
            <div key={`list-skeleton${idx}`} className="w-100">
              {!!idx && (
                <div style={{ padding: "0rem 0rem", width: "100%" }}>
                  <hr
                    style={{
                      borderWidth: "0.0625rem",
                      borderStyle: "solid",
                      borderColor: "#E5E5E5",
                    }}
                  />
                </div>
              )}
              <Grid item xs={12}>
                <ListSkeletonElement showForValue={showForValue} />
              </Grid>
            </div>
          ))}
      </>
    </Grid>
  );
};

export default ListSkeletonContainer;
