import { TextField } from "@mui/material";

import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTypography from "../WishlinkTypography";

const WishlinkEditableInput = (props) => {
  const {
    editMode,
    setEditMode,
    value,
    onInputValueChange,
    onClickCheckMark,
    onClickCross,
    onClickDelete,
    showDelete = false,
    multiline = false,
    toggleEditModeGa,
    placeholder,
    error = false,
    helperText = "",
  } = props;

  return (
    <>
      {!editMode && (
        <div
          className="flex align-center p-3 justify-between border-radius"
          style={{
            border: `0.06rem solid #E5E5E5`,
            fontSize: "0.875rem",
          }}
        >
          <TextField
            fullWidth={true}
            multiline={multiline}
            maxRows={3}
            value={value}
            disabled
            placeholder={placeholder}
            sx={{
              paddingRight: "2rem",
              "& fieldset": { border: "none", padding: 0 },
              "& .MuiOutlinedInput-root": {
                padding: 0,
              },
            }}
            inputProps={{
              style: {
                padding: 0,
                fontSize: "0.875rem",
                fontWeight: 400,
              },
            }}
          />
          <div className="flex align-center">
            <img
              onClick={() => {
                !!toggleEditModeGa && toggleEditModeGa();
                setEditMode(true);
              }}
              src={`${IMAGE_PREFIX}editPost.svg`}
              alt=""
            />
          </div>
          {showDelete && (
            <img
              onClick={() => onClickDelete()}
              className="ml-3"
              src={`${IMAGE_PREFIX}delete.svg`}
              alt=""
            />
          )}
        </div>
      )}
      {editMode && (
        <>
          <div
            className={multiline ? "flex p-3" : "flex"}
            style={{
              border: `0.06rem solid #E5E5E5`,
              background: error ? "rgba(233, 30, 99, 0.08)" : "",
            }}
          >
            <TextField
              inputProps={{
                maxLength: 256,
                style: {
                  fontSize: "0.875rem",
                  fontWeight: 400,
                },
              }}
              placeholder={placeholder}
              fullWidth={true}
              sx={{
                width: "100%",
                "& fieldset": { border: "none", padding: 0 },
                "& .MuiOutlinedInput-root": {
                  padding: 0,
                },
              }}
              onChange={(event) => onInputValueChange(event.target.value)}
              multiline={multiline}
              maxRows={3}
              value={value}
              autoFocus={true}
            />
            <img
              src={`${IMAGE_PREFIX}Tick.svg`}
              alt=""
              onClick={() => onClickCheckMark()}
              className="mr-2"
              disabled={error}
            />
            <img
              src={`${IMAGE_PREFIX}close2.svg`}
              alt=""
              onClick={() => onClickCross()}
            />
          </div>
          {helperText && error && (
            <WishlinkTypography
              fontSize="0.625rem"
              textAlign="left"
              color={"#e91e63"}
            >
              {helperText}
            </WishlinkTypography>
          )}
        </>
      )}
    </>
  );
};

export default WishlinkEditableInput;
