import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import WishlinkTypography from "../WishlinkTypography";
import "./index.css";

const WishlinkDatePicker = (props) => {
  const { date, handleDateSelect } = props;

  const renderCustomDays = (day) => {
    return (
      <div className="flex flex-column align-center">
        <WishlinkTypography
          fontWeight={500}
          fontSize="0.875rem"
          lineHeight="1.3125rem"
        >
          {day.getDate()}
        </WishlinkTypography>
      </div>
    );
  };

  return (
    <>
      <Calendar
        date={date}
        onChange={handleDateSelect}
        direction="vertical"
        showMonthArrow={false}
        showDateDisplay={false}
        maxDate={new Date()}
        rangeColors={["#E79548"]}
        dayContentRenderer={(day) => renderCustomDays(day)}
        className="wishlink-date-picker"
      />
    </>
  );
};

export default WishlinkDatePicker;
