import { Button } from "@mui/material";

const WishlinkButton = (props) => {
  const {
    background,
    borderRadius = "0.5rem",
    boxShadow,
    width = "100%",
    height,
    border,
    disabled,
    className,
    onClick,
    style,
    parentDivStyle,
    buttonClassName,
    opacity = 1,
  } = props;
  return (
    <div className={className} style={parentDivStyle}>
      <Button
        sx={{
          background,
          borderRadius,
          boxShadow,
          width,
          height,
          textTransform: "none",
          border,
          opacity,
          minWidth: "0px",
          ...style,
        }}
        disabled={disabled}
        onClick={onClick}
        className={buttonClassName}
      >
        {props.children}
      </Button>
    </div>
  );
};

export default WishlinkButton;
