const PillGroup = (props) => {
  const { pills, selectedPill, onClickSelectPill, onClickSuffixIcon } = props;

  function isPillSelected(pill, selectedPill) {
    let selected = false;
    selectedPill.forEach((p) => {
      if (p.id === pill.id) selected = true;
    });
    return selected;
  }

  return (
    <div className="flex overflow-scroll align-center">
      {pills.map((pill, index) => {
        return (
          <div
            key={index}
            className="p-2 mr-2 flex justify-center align-center"
            style={{
              border: isPillSelected(pill, selectedPill)
                ? `1px solid #e91e63`
                : `1px solid #E5E5E5`,
              borderRadius: "20px",
              height: "34px",
              background: isPillSelected(pill, selectedPill) ? "#E91E6320" : "",
            }}
          >
            {!pill.collapsible || isPillSelected(pill, selectedPill) ? (
              <div className="flex align-center">
                <div
                  className="cursor-pointer ml-2"
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    color: "#666666",
                  }}
                  onClick={() => {
                    pill?.clickable && onClickSelectPill(pill);
                  }}
                >
                  {pill.name}
                </div>
                <img
                  src={pill.suffixIcon}
                  onClick={() => onClickSuffixIcon(pill)}
                  className="ml-2"
                  alt=""
                />
              </div>
            ) : (
              <img
                src={pill.prefixIcon}
                alt=""
                onClick={() => {
                  pill?.clickable && onClickSelectPill(pill);
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PillGroup;
