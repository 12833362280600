import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { tokens } from "../../../../theme";
import WishlinkLottie from "../../../../WishlinkComponents/WishlinkLottie";
import { IMAGE_PREFIX } from "../../../../utils/constants";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";

export const ReferCard = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const {
    slab1_num_referrals_required,
    slab1_referral_amount,
    slab2_num_referrals_required,
    slab2_referral_amount,
    slab3_num_referrals_required,
    slab3_referral_amount,
  } = props;

  const onClickOpenHowItWorks = () => {
    navigate("#invite");
  };

  return (
    <div
      className="border-radius2 bs-1 ml-4 mt-4 mr-4"
      style={{
        border: `0.06rem solid ${colors.grey[400]}`,
        background: colors.misc[100],
        marginTop: "2rem",
      }}
      onClick={() => onClickOpenHowItWorks()}
    >
      <div
        className="border-radius2 blue-gradient m-4 relative"
        style={{ border: `0.06rem solid ${colors.grey[0]}`, height: "9.5rem" }}
      >
        <WishlinkTypography
          className="mt-5"
          fontSize="0.6875rem"
          color={colors.grey[0]}
        >
          REFER & WIN
        </WishlinkTypography>
        <div
          className="refer-card absolute"
          style={{ top: "3rem", width: "100%" }}
        >
          <WishlinkTypography fontSize="1.5rem" fontWeight={700}>
            ₹ {slab2_referral_amount}
          </WishlinkTypography>
          <WishlinkTypography fontSize="0.75rem" className="pl-4 pr-4 pt-2">
            on referring {slab2_num_referrals_required} Friends
          </WishlinkTypography>
        </div>
        <div
          className="refer-card-2 absolute"
          style={{ top: "3rem", width: "100%" }}
        >
          <WishlinkTypography fontSize="1.5rem" fontWeight={700}>
            ₹ {slab3_referral_amount}
          </WishlinkTypography>
          <WishlinkTypography fontSize="0.75rem" className="pl-4 pr-4 pt-2">
            on referring {slab3_num_referrals_required} Friends
          </WishlinkTypography>
        </div>
        <div
          className="refer-card-3 absolute"
          style={{ top: "3rem", width: "100%" }}
        >
          <WishlinkTypography fontSize="1.5rem" fontWeight={700}>
            ₹ {slab1_referral_amount}
          </WishlinkTypography>
          <WishlinkTypography fontSize="0.75rem" className="pl-4 pr-4 pt-2">
            on referring {slab1_num_referrals_required} Friends
          </WishlinkTypography>
        </div>
        <WishlinkLottie
          play={true}
          loop={true}
          path={`${IMAGE_PREFIX}falling-coins.json`}
          className="lottie absolute"
          style={{ right: 0, height: "10rem", top: 0, opacity: 0.5 }}
        ></WishlinkLottie>
      </div>
    </div>
  );
};

export default ReferCard;
