const configDev = {
  API: {
    url: "http://localhost:8000/api/",
    baseFrontend: "http://localhost:3000/",
  },
};

const configProd = {
  API: {
    url: "https://honasa.azurewebsites.net/api/",
    baseFrontend: "https://insights.wishlink.com/",
  },
};

const configStage = {
  API: {
    url: "https://api-c.wishllink.app/api/",
  },
};

if (process.env.NODE_ENV === "development") {
  module.exports = configDev;
} else if (process.env.NODE_ENV === "none") {
  module.exports = configStage;
} else if (process.env.NODE_ENV === "production") {
  module.exports = configProd;
} else {
  module.exports = configStage;
}
