import Chart from "react-apexcharts";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { DATE_PRESETS, IMAGE_PREFIX } from "../../utils/constants";
import {
  formatAmountK,
  formatDate,
  getOpacityForDisabledValue,
  getStartAndEndDateFromPreset,
} from "../../utils/common";
import "./LoadingChart.css";
import "./style.css";
import { useEffect, useState } from "react";
import { useFetchResellerAnalyticsChartData } from "../../apiLayer/Analytics";
import { triggerEvent } from "../../utils/honasa-events";

const getXYObjectArrayFromDataDict = (dataDict) => {
  let xyObjectArray = [];
  for (const [key, value] of Object.entries(dataDict)) {
    xyObjectArray.push({ x: key.split(" "), y: value });
  }
  return xyObjectArray;
};

const getToggle = (title, selected) => {
  let background = selected ? "#E91E63" : "#FAF4F9";
  let fontColor = selected ? "#FFFFFF" : "#666666";
  return (
    <div
      style={{
        borderRadius: "0.25rem",
        background: background,
        padding: "0.25rem 0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <WishlinkTypography
        fontWeight={400}
        fontSize="0.625rem"
        lineHeight="0.9375rem"
        color={fontColor}
      >
        {title}
      </WishlinkTypography>
    </div>
  );
};

const AnalyticsChart = (props) => {
  const { creatorData } = props;
  const [analyticsChartType, setAnalyticsChartType] = useState("week");
  const [endDateToAchieve, setEndDateToAchieve] = useState(
    formatDate(getStartAndEndDateFromPreset(DATE_PRESETS.THIS_MONTH)[1]),
  );

  const {
    data: chartData,
    refetch: refetchResellerAnalyticsChartData,
    isFetching,
  } = useFetchResellerAnalyticsChartData({
    endDateToAchieve: endDateToAchieve,
    chartType: analyticsChartType,
    enabled: true,
  });

  let columnWidth = analyticsChartType === "day" ? "40%" : "70%";
  const [
    analyticsChartPreviousPageExists,
    setAnalyticsChartPreviousPageExists,
  ] = useState(false);
  const [analyticsChartNextPageExists, setAnalyticsChartNextPageExists] =
    useState(false);
  const [analyticsChartCurrentStartDate, setAnalyticsChartCurrentStartDate] =
    useState(new Date());
  const [analyticsChartCurrentEndDate, setAnalyticsChartCurrentEndDate] =
    useState(new Date());
  const [analyticsChartLoading, setAnalyticsChartLoading] = useState(false);
  const [creatorHasNoRedirections, setCreatorHasNoRedirections] =
    useState(false);
  const [minScaleEarnings, setMinScaleEarnings] = useState(0);
  const [maxScaleEarnings, setMaxScaleEarnings] = useState(1000);
  const [minScaleRedirections, setMinScaleRedirections] = useState(0);
  const [maxScaleRedirections, setMaxScaleRedirections] = useState(1000);
  const [minScaleOrders, setMinScaleOrders] = useState(0);
  const [maxScaleOrders, setMaxScaleOrders] = useState(1000);
  const [chartAnalyticsRedirections, setChartAnalyticsRedirections] = useState(
    {},
  );
  const [grossChartAnalyticsEarnings, setGrossChartAnalyticsEarnings] =
    useState({});
  const [returnsChartAnalyticsEarnings, setReturnsChartAnalyticsEarnings] =
    useState({});
  const [ordersChartAnalyticsEarnings, setOrdersChartAnalyticsEarnings] =
    useState({});

  useEffect(() => {
    let chartDataCustom = chartData?.data;
    if (chartDataCustom) {
      setAnalyticsChartPreviousPageExists(chartDataCustom.previous_page_exists);
      setAnalyticsChartNextPageExists(chartDataCustom.next_page_exists);
      setAnalyticsChartCurrentStartDate(new Date(chartDataCustom.start_date));
      setAnalyticsChartCurrentEndDate(new Date(chartDataCustom.end_date));
      setCreatorHasNoRedirections(chartDataCustom.creator_has_no_redirections);
      setMinScaleEarnings(chartDataCustom.min_scale_earnings);
      setMaxScaleEarnings(chartDataCustom.max_scale_earnings);
      setMinScaleRedirections(chartDataCustom.min_scale_redirects);
      setMaxScaleRedirections(chartDataCustom.max_scale_redirects);
      setMinScaleOrders(chartDataCustom.min_scale_orders);
      setMaxScaleOrders(chartDataCustom.max_scale_orders);
      setChartAnalyticsRedirections(chartDataCustom.redirections_dict);
      setGrossChartAnalyticsEarnings(chartDataCustom.gross_earnings_dict);
      setReturnsChartAnalyticsEarnings(chartDataCustom.returns_earnings_dict);
      setOrdersChartAnalyticsEarnings(chartDataCustom.num_orders_dict);
    }
  }, [chartData]);

  useEffect(() => {
    setAnalyticsChartLoading(isFetching);
  }, [isFetching]);

  let options = {
    chart: {
      height: 300,
      maxHeight: 300,
      type: "line",
      stacked: true,
      toolbar: {
        show: false,
      },
      style: {
        ".apexcharts-text": {
          color: "#FFFFFF",
        },
      },
    },
    dataLabels: { enabled: false },
    colors: ["#DD9653", "#4E90FF", "#18A716", "#DE4444"],
    stroke: {
      width: [3, 3, 0, 0],
      curve: "smooth",
    },
    title: {
      show: false,
      text: "",
      style: {
        display: "none",
      },
    },
    legend: {
      offsetY: 0,
      show: true,
      itemMargin: {
        vertical: 8,
      },
      fontFamily: "Poppins",
      fontSize: 10,
      formatter: (val) => {
        let val1 = val?.split(" ")[0];
        let val2 = val?.split(" ")[1];
        return `<div style="display: flex; flex-direction: column; align-items: flex-start"><span style="font-family: 'Poppins', sans-serif">${val1}</span><span style="font-family: 'Poppins', sans-serif">${val2}</span></div>`;
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    // labels: Object.keys(chartAnalyticsEarnings),
    xaxis: {
      type: "category",
      style: {
        color: "#FFFFFF",
      },
      tooltip: { enabled: false },
      labels: {
        style: {
          fontFamily: "Poppins",
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: columnWidth,
        stacked: true,
      },
    },
    noData: {
      text: `${
        analyticsChartLoading
          ? "Loading data..."
          : creatorHasNoRedirections
            ? "Share Wishlinks & start earning!"
            : "No data"
      }`,
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#666666",
        fontSize: "0.75rem",
        fontFamily: "Poppins",
        maxWidth: "100%",
      },
    },
    yaxis: [
      {
        tickAmount: 6,
        show: false,
        min: minScaleRedirections || 0,
        max: maxScaleRedirections || 1000,
      },
      {
        tickAmount: 6,
        show: false,
        min: minScaleOrders || 0,
        max: maxScaleOrders || 1000,
      },
      {
        tickAmount: 6,
        show: false,
        min: minScaleEarnings || 0,
        max: maxScaleEarnings || 1000,
      },
      {
        tickAmount: 6,
        show: false,
        min: minScaleEarnings || 0,
        max: maxScaleEarnings || 1000,
      },
    ],
    tooltip: {
      y: {
        title: {
          formatter: (val) => {
            return `<span style="font-family: 'Poppins', sans-serif">${val}</span>`;
          },
        },
        formatter: (val) => {
          return `<span style="font-family: 'Poppins', sans-serif">${formatAmountK(
            val,
          )}</span>`;
        },
      },
      x: {
        formatter: (val) => {
          return `<span style="font-family: 'Poppins', sans-serif">${
            Object.keys(grossChartAnalyticsEarnings)[val - 1]
          }</span>`;
        },
      },
    },
  };

  let actualSeries = [
    {
      name: "Link Clicks",
      type: "line",
      data: getXYObjectArrayFromDataDict(chartAnalyticsRedirections),
    },
    {
      name: "Orders Placed",
      type: "line",
      data: getXYObjectArrayFromDataDict(ordersChartAnalyticsEarnings),
    },
    {
      name: "Ordered Sales",
      type: "column",
      data: getXYObjectArrayFromDataDict(grossChartAnalyticsEarnings),
    },
    {
      name: "Cancelled Sales",
      type: "column",
      data: getXYObjectArrayFromDataDict(returnsChartAnalyticsEarnings),
    },
  ];

  let loadingSeries = [
    {
      name: "Clicks",
      type: "line",
      data: [],
    },
    {
      name: "Sales",
      type: "line",
      data: [],
    },
    {
      name: "Commissions",
      type: "column",
      data: [],
    },
    {
      name: "Returns",
      type: "column",
      data: [],
    },
  ];

  let series =
    analyticsChartLoading || creatorHasNoRedirections
      ? loadingSeries
      : actualSeries;

  const handlePreviousOrNextClick = (type) => {
    let params = {
      chartType: analyticsChartType,
      startDatePrevious: analyticsChartCurrentStartDate,
      endDatePrevious: analyticsChartCurrentEndDate,
    };
    setEndDateToAchieve(formatDate(analyticsChartCurrentStartDate));
    if (type === "previous") {
      params["showPreviousPage"] = true;
    } else {
      params["showNextPage"] = true;
    }
    const button_analytics =
      type === "previous"
        ? "previous_button_analytics"
        : "next_button_analytics";
    triggerEvent({
      eventName: "analytics_cta_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Analytics-Home",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: button_analytics,
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  };

  const handleDayWeekToggle = (chartType) => {
    setAnalyticsChartType(chartType);
    const button_analytics =
      chartType === "day" ? "day_button_analytics" : "week_button_analytics";
    triggerEvent({
      eventName: "analytics_chart_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Analytics-Home",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: button_analytics,
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
  };

  useEffect(() => {
    refetchResellerAnalyticsChartData();
  }, [analyticsChartType, endDateToAchieve]);

  return (
    <div className="pl-4 pr-4">
      <div
        className={`border-radius border-grey ${
          analyticsChartLoading && "loading-chart"
        }`}
      >
        <div
          className={analyticsChartLoading ? "p-3" : "ml-3"}
          style={{ maxHeight: "19rem" }}
        >
          <Chart
            options={options}
            series={series}
            type={"line"}
            height={300}
            width={"100%"}
          />
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <WishlinkButton
          onClick={() => handlePreviousOrNextClick("previous")}
          disabled={!analyticsChartPreviousPageExists}
          style={{
            width: "2rem",
            height: "2rem",
            borderRadius: "1rem",
            border: "0.0625rem solid #E5E5E5",
            boxShadow: "0rem 0.0625rem 0.25rem rgba(0,0,0,0.12)",
            opacity: getOpacityForDisabledValue(
              !analyticsChartPreviousPageExists,
            ),
          }}
        >
          <img
            src={`${IMAGE_PREFIX}left-nudge.svg`}
            style={{ borderRadius: "1rem" }}
            alt="left"
          />
        </WishlinkButton>
        <div className="flex">
          <WishlinkButton
            disabled={analyticsChartLoading}
            onClick={() => {
              handleDayWeekToggle("day");
            }}
          >
            {getToggle("Day", analyticsChartType === "day")}
          </WishlinkButton>
          <WishlinkButton
            disabled={analyticsChartLoading}
            onClick={() => {
              handleDayWeekToggle("week");
            }}
          >
            {getToggle("Week", analyticsChartType === "week")}
          </WishlinkButton>
        </div>
        <WishlinkButton
          onClick={() => handlePreviousOrNextClick("next")}
          disabled={!analyticsChartNextPageExists}
          style={{
            width: "2rem",
            height: "2rem",
            borderRadius: "1rem",
            border: "0.0625rem solid #E5E5E5",
            boxShadow: "0rem 0.0625rem 0.25rem rgba(0,0,0,0.12)",
            opacity: getOpacityForDisabledValue(!analyticsChartNextPageExists),
          }}
        >
          <img
            src={`${IMAGE_PREFIX}right-nudge.svg`}
            style={{ borderRadius: "1rem" }}
            alt="right"
          />
        </WishlinkButton>
      </div>
    </div>
  );
};

export default AnalyticsChart;
