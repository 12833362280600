import React, { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { IMAGE_PREFIX, SLAB_BACKGROUNDS } from "../../../../utils/constants";
import { kFormatter } from "../../../../utils/common";

const rewardProgressCheckmark = "reward-progress-checkmark-main.svg";

const getMarginLeft = (slabAmount, maxAmount) => {
  return slabAmount ? `${(slabAmount * 100) / maxAmount}%` : "0";
};

const getTooltipTitle = (slab, achieveMetric) => {
  const reward = slab?.reward_amount;
  const slabAmount = slab?.target_amount;
  const isAchieved = slab?.is_achieved;
  let beginningText = isAchieved ? "Earned" : "Win";
  return (
    <React.Fragment>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "0.625rem",
          lineHeight: "0.9375rem",
          color: "#666666",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        {`${beginningText} ₹${reward}`}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "0.625rem",
          lineHeight: "0.9375rem",
          color: "#666666",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        {`on achieving ₹${slabAmount} confirmed sales`}
      </Typography>
    </React.Fragment>
  );
};

export const RewardProgress = React.memo(function RewardProgress(props) {
  const slab = props.slab;
  const idx = props.idx;
  const campaignMaxProgressValue = props.campaignMaxProgressValue;
  const achieveMetric = props.achieveMetric;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const ref = useRef();

  const handleTooltipClick = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleToolTipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Tooltip
      open={tooltipOpen}
      onClose={handleToolTipClose}
      title={getTooltipTitle(slab, achieveMetric)}
      arrow={true}
      placement={"top"}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            bgcolor: "#FFF9F5 !important",
            maxWidth: 90,
            border: "1px solid #dadde9",
          },
          "& .MuiTooltip-arrow": {
            color: "#FFF9F5 !important",
          },
        },
      }}
    >
      <Box
        ref={ref}
        onClick={() => {
          handleTooltipClick();
        }}
        sx={{
          width: "1.5rem",
          height: "1.5rem",
          position: "absolute",
          marginTop: "-0.5rem",
          zIndex: 1,
          marginLeft: getMarginLeft(
            slab?.target_amount,
            campaignMaxProgressValue,
          ),
        }}
      >
        {slab?.is_achieved ? (
          <img
            src={`${IMAGE_PREFIX}${rewardProgressCheckmark}`}
            style={{ width: "1.5rem", height: "1.5rem" }}
            alt=""
          />
        ) : (
          <Box
            sx={{
              background: SLAB_BACKGROUNDS[idx],
              boxShadow: "0rem 0.25rem 0.25rem rgba(255, 255, 255, 0.25)",
              borderRadius: "0.25rem",
              width: "1.5rem",
              height: "1.5rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "0.6875rem",
                lineHeight: "1rem",
                color: "#333333",
              }}
            >
              {kFormatter(slab?.target_amount, 0)}
            </Typography>
          </Box>
        )}
      </Box>
    </Tooltip>
  );
});
