import styled from "@emotion/styled";

export const ProductsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 150px;
`;

export const Banner = styled.img`
  box-sizing: border-box;
  width: calc(100% - 2rem);
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  border-radius: 0.5rem;
`;
