import { Typography } from "@mui/material";

const WishlinkTypography = (props) => {
  const {
    fontStyle = "normal",
    fontWeight,
    fontSize,
    color,
    lineHeight,
    className,
    typographyClassName,
    letterSpacing,
    noWrap,
    style,
    textTransform,
    textAlign,
    onClick,
    typographystyle = {},
    textOverflow,
    maxHeight,
    m,
    mr,
    mt,
    ml,
    mb,
    p,
    pr,
    pt,
    pl,
    pb,
  } = props;
  return (
    <div
      className={`${className ? className : ""}`}
      style={style}
      onClick={onClick}
    >
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontWeight,
          fontSize,
          fontStyle,
          color,
          lineHeight,
          textTransform,
          textAlign,
          letterSpacing,
          textOverflow,
          maxHeight,
        }}
        noWrap={noWrap}
        className={typographyClassName}
        style={typographystyle}
        m={m}
        mb={mb}
        mt={mt}
        ml={ml}
        mr={mr}
        p={p}
        pb={pb}
        pt={pt}
        pl={pl}
        pr={pr}
      >
        {props.children}
      </Typography>
    </div>
  );
};

export default WishlinkTypography;
