import { Box, Divider, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { IMAGE_PREFIX, PAGE_URLS } from "../../../../utils/constants";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import { useNavigate } from "react-router-dom";
import WishlinkLinearProgress from "../../../../WishlinkComponents/WishlinkLinearProgress";
import "./referralRewards.styles.css";
import { useFetchResellerReferralEarningsData } from "../../../../apiLayer/Referral/Referral.services";
import ValueSkeleton from "../../../../WishlinkComponents/ValueSkeleton";
import WishlinkHelper from "../../../../WishlinkComponents/WishlinkHelper";
import { useState } from "react";

const ReferralBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <div className="flex flex-column">
      <div className="flex align-center">
        <WishlinkTypography
          color={colors.grey[500]}
          fontSize="0.6875rem"
          className="pb-1"
        >
          Friends referred : 29
        </WishlinkTypography>
      </div>
      <div className="flex align-center">
        <img src={`${IMAGE_PREFIX}bag.svg`} className="mr-1" alt="" />
        <WishlinkLinearProgress
          percentComplete={50}
          barColor={colors.grey[400]}
          filledBarColor={colors.red[700]}
          style={{
            width: "3rem",
            borderRadius: "0.25rem",
          }}
        />
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={500}
          color={colors.grey[600]}
          ml={1}
        >
          0/10
        </WishlinkTypography>
      </div>
    </div>
  );
};

const ReferralListItem = (props) => {
  const { referee } = props;
  const {
    days_left,
    product_links_shared,
    num_links_required,
    redirections,
    expected_extra_earning,
  } = referee;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isLinkSharingNotComplete = product_links_shared < num_links_required;
  const isTimelineIncomplete = days_left > 0;
  const showLessThan7DaysRemaining = days_left < 7 && isTimelineIncomplete;
  //   const { creatorReferalData } = useSelector((state) => state.referralReducer);
  const creatorReferalData = {
    referral_base_amount: 100,
    num_products_required: 10,
    num_redirects_unit: 10,
    referral_redirects_extra_amount: 10,
  };
  const { num_redirects_unit } = creatorReferalData;
  const showWinMessage =
    isLinkSharingNotComplete &&
    redirections > num_redirects_unit &&
    isTimelineIncomplete;

  return (
    <>
      <div className="flex align-start justify-between p-4">
        <div className="text-left" style={{ flex: 4 }}>
          <div
            className="flex justify-between align-center"
            style={{ marginBottom: "0.125rem" }}
          >
            <WishlinkTypography
              color={colors.grey[700]}
              fontSize="0.875rem"
              fontWeight={500}
            >
              Level 1
            </WishlinkTypography>
            <div className="flex align-center">
              <WishlinkTypography
                color={colors.grey[700]}
                fontSize="0.68rem"
                fontWeight={500}
                mr={0.5}
              >
                Bonus
              </WishlinkTypography>
              <WishlinkTypography
                color={colors.grey[700]}
                fontSize="1rem"
                fontWeight={500}
              >
                ₹ 500
              </WishlinkTypography>
            </div>
          </div>
          <div className="flex align-center justify-between">
            <ReferralBar />
            <ReferralBar />
          </div>
          {showLessThan7DaysRemaining ? (
            <div
              className="relative mt-2"
              style={{ height: "1.1875rem", width: "11.5rem" }}
            >
              <img
                src={`${IMAGE_PREFIX}gold-transition.gif`}
                style={{ height: "100%", width: "100%" }}
                alt=""
              />
              <WishlinkTypography
                fontSize="0.625rem"
                fontWeight={600}
                color={colors.grey[500]}
                className="absolute w-100 text-center"
                style={{
                  top: "0.12rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                LESS THAN 7 DAYS REMAINING
              </WishlinkTypography>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {showWinMessage ? (
        <WishlinkTypography
          fontSize="0.6875rem"
          fontWeight={400}
          color={colors.grey[500]}
          fontStyle="italic"
          className="mb-4 text-left ml-4"
        >
          * Win ₹{expected_extra_earning}+ once your bestie shares 5 product
          wishlinks
        </WishlinkTypography>
      ) : (
        <></>
      )}
    </>
  );
};

export const ReferralList = ({ creatorRefererLedger }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div className="white-gradient border-radius bs-1">
      {(creatorRefererLedger || [])?.map((referee, idx) => {
        return (
          <div key={idx}>
            <ReferralListItem referee={referee} />
            <div className="pl-4 pr-4">
              <Divider
                sx={{
                  borderStyle: "dashed",
                  borderColor: colors.grey[200],
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TotalReferrals = (props) => {
  const { total_referrals, isLoading } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div className="p-3 border-radius flex w-100 align-center justify-between white-gradient mr-2">
      <div className="text-left flex-2">
        <WishlinkTypography
          color={colors.indigo[300]}
          fontSize="1.5rem"
          fontWeight={600}
        >
          <ValueSkeleton isLoading={isLoading} value={total_referrals} />
        </WishlinkTypography>
        <WishlinkTypography
          color={colors.indigo[300]}
          fontSize="0.75rem"
          fontWeight={500}
          className="mt-2"
        >
          Total Referrals
        </WishlinkTypography>
      </div>
      <div className="flex-1 text-right">
        <img src={`${IMAGE_PREFIX}total-referrals.svg`} alt="" />
      </div>
    </div>
  );
};

const Spinner = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { size = "0.7rem", color = colors.red[200] } = props;
  return (
    <div className="bouncing-loader flex justify-center pt-3">
      <div style={{ background: color, width: size, height: size }}></div>
      <div style={{ background: color, width: size, height: size }}></div>
      <div style={{ background: color, width: size, height: size }}></div>
    </div>
  );
};

const TnCArray = [
  "You will receive the highest earned reward of each week and month!",
  "Only applicable to Wishlink Shop Creators (sales only on products from posts & collections)",
  "Only valid for May",
  "Week 1: 1st to 7th May; Week 2: 8th to 14th May; Week 3: 15th to 21st May; Week 4: 22nd to 28th May",
  "Winners to receive prizes by 7th Jun",
  "Wishlink will be sole decider of the winners",
];

const getRewardCategoryHeadingAndSubheading = (
  heading,
  subheading,
  subHeading2,
) => {
  return (
    <Box className="mt-4">
      <Box
        sx={{
          background:
            "linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%)",
          filter: "drop-shadow(0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.2))",
          boxShadow: "0rem 0.125rem 0.25rem rgba(255, 255, 255, 0.25)",
          backdropFilter: "blur(1.25rem)",
          padding: "0.5rem",
          borderRadius: "0.75rem",
        }}
      >
        <WishlinkTypography
          fontWeight={600}
          fontSize="1.125rem"
          lineHeight="1.6875rem"
          color="#FFFFFF"
        >
          {heading}
        </WishlinkTypography>
      </Box>
      <WishlinkTypography
        fontWeight={400}
        fontSize="0.875rem"
        lineHeight="1.3125rem"
        color="#FFFFFF"
        marginTop="0.75rem"
        padding="0rem 2rem"
      >
        {subheading}
      </WishlinkTypography>
      {!!subHeading2 && (
        <WishlinkTypography
          fontWeight={400}
          fontSize="0.875rem"
          lineHeight="1.3125rem"
          color="#FFFFFF"
        >
          {subHeading2}
        </WishlinkTypography>
      )}
    </Box>
  );
};

const TnCTypography = (props) => {
  return (
    <div className="flex align-start flex-gap-1">
      <div className="mt-1">
        <div
          style={{
            width: "0.5rem",
            height: "0.5rem",
            borderRadius: "0.25rem",
            background: "#FFFFFF",
            boxSizing: "border-box",
          }}
        />
      </div>
      <WishlinkTypography
        fontWeight={400}
        fontSize="0.825rem"
        lineHeight="1.2rem"
        color="#FFFFFF"
        typographyClassName="flex flex-gap-2"
        className="flex flex-gap-2"
      >
        {props.children}
      </WishlinkTypography>
    </div>
  );
};

const TotalEarnings = (props) => {
  const { total_referral_earnings, referral_bonus_amount, isLoading } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="p-3 border-radius flex w-100 align-center justify-between blue-gradient ml-2">
      <div className="text-left flex-2">
        <WishlinkTypography
          color={colors.grey[0]}
          fontSize="1.5rem"
          fontWeight={600}
        >
          <ValueSkeleton
            isLoading={isLoading}
            value={`₹ ${total_referral_earnings?.toFixed(2)}`}
          />
        </WishlinkTypography>
        <WishlinkTypography
          color={colors.grey[0]}
          fontSize="0.75rem"
          fontWeight={500}
          className="mt-2"
        >
          Total Earnings
        </WishlinkTypography>
      </div>
      <div className="flex-1 text-right">
        <WishlinkHelper
          placement="top"
          disableTouchListener={true}
          disableFocusListener={true}
          title={
            <div className="p-1">
              <>Referral Bonus: ₹{referral_bonus_amount}</>
              <br />
              <>
                Downline Earnings: ₹
                {(total_referral_earnings - referral_bonus_amount).toFixed(2)}
              </>
            </div>
          }
          open={showTooltip}
        >
          <img
            onMouseOver={() => {
              setShowTooltip(true);
            }}
            onMouseOut={() => setShowTooltip(false)}
            src={`${IMAGE_PREFIX}info-icon-white.svg`}
            alt=""
          />
        </WishlinkHelper>
      </div>
    </div>
  );
};

const Poweredby = (props) => {
  const { variant = "white" } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const redirectToConnectWishlinkInstagram = () => {
    window.open("https://www.instagram.com/connect.wishlink");
  };

  const redirectToFaqPage = () => {
    navigate(PAGE_URLS.FAQ);
  };

  return (
    <div
      className="flex justify-center align-center p-5"
      style={{
        background: variant === "white" ? colors.grey[0] : colors.red[200],
        marginTop: "auto",
      }}
    >
      <WishlinkTypography
        fontSize="0.75rem"
        fontWeight={400}
        color={variant === "white" ? colors.grey[700] : colors.grey[0]}
        onClick={() => redirectToConnectWishlinkInstagram()}
      >
        ⚡ Powered by
      </WishlinkTypography>
      <img
        className="ml-1"
        src={`${IMAGE_PREFIX}${
          variant === "white" ? `wishlink-orange.png` : `wishlink-white.png`
        }`}
        style={{ width: "4rem" }}
        alt=""
        onClick={() => redirectToConnectWishlinkInstagram()}
      />
      <div
        style={{
          background: variant === "white" ? colors.grey[500] : colors.grey[0],
          height: "1rem",
          width: "0.06rem",
        }}
        className="ml-3 mr-3"
      ></div>
      <img
        className="ml-1"
        src={`${IMAGE_PREFIX}${
          variant === "white" ? `support.svg` : `support-white.svg`
        }`}
        alt=""
        onClick={() => redirectToFaqPage()}
      />
      <WishlinkTypography
        className="ml-2"
        fontWeight={400}
        fontSize="0.75rem"
        color={variant === "white" ? colors.indigo[400] : colors.grey[0]}
        onClick={() => redirectToFaqPage()}
      >
        Help & Support
      </WishlinkTypography>
    </div>
  );
};

export const ReferralRewards = (props) => {
  const {
    slab1_num_referrals_required,
    slab1_referral_amount,
    slab2_num_referrals_required,
    slab2_referral_amount,
    slab3_num_referrals_required,
    slab3_referral_amount,
    referralConfigLoading,
  } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    data: resellerReferralEarnings,
    isLoading: referralEarningsDataLoading,
  } = useFetchResellerReferralEarningsData();
  const {
    total_referrals = 0,
    total_referral_earnings,
    referral_bonus_amount,
    referrals_count_with_threshold_earnings,
  } = resellerReferralEarnings?.data || {};

  const percentComplete =
    referrals_count_with_threshold_earnings > 40
      ? 100
      : (referrals_count_with_threshold_earnings / 40) * 100;

  let prizeWon = 0;
  if (referrals_count_with_threshold_earnings < slab1_num_referrals_required)
    prizeWon = 0;
  else if (
    referrals_count_with_threshold_earnings >= slab1_num_referrals_required &&
    referrals_count_with_threshold_earnings < slab2_num_referrals_required
  )
    prizeWon = slab1_referral_amount;
  else if (
    referrals_count_with_threshold_earnings >= slab2_num_referrals_required &&
    referrals_count_with_threshold_earnings < slab3_num_referrals_required
  )
    prizeWon = slab1_referral_amount + slab2_referral_amount;
  else if (
    referrals_count_with_threshold_earnings >= slab3_num_referrals_required
  )
    prizeWon =
      slab1_referral_amount + slab2_referral_amount + slab3_referral_amount;

  return (
    <div className="p-4" style={{ background: colors.red[200] }}>
      <div className="flex mb-4">
        <TotalReferrals
          total_referrals={total_referrals}
          isLoading={referralEarningsDataLoading}
        />
        <TotalEarnings
          total_referral_earnings={total_referral_earnings}
          referral_bonus_amount={referral_bonus_amount}
          isLoading={referralEarningsDataLoading}
        />
      </div>
      {referralConfigLoading ? (
        <Spinner color={colors.grey[0]} />
      ) : (
        <>
          <div
            className="border-radius mb-3 p-4"
            style={{
              background:
                "linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.80) 100%)",
            }}
          >
            <div className="flex justify-between align-center">
              <div>
                <WishlinkTypography
                  color="#333"
                  fontSize="0.75rem"
                  className="text-left"
                >
                  Friends Referred: {referrals_count_with_threshold_earnings}
                </WishlinkTypography>
                <div>
                  <WishlinkTypography
                    color="#333"
                    fontSize="0.675rem"
                    className="text-left"
                  >
                    with confirmed ₹2k sales
                  </WishlinkTypography>
                </div>
              </div>
              <div>
                <WishlinkTypography color="#333" fontSize="0.75rem">
                  Amount Earned: ₹ {prizeWon}
                </WishlinkTypography>
              </div>
            </div>
            <div className="relative" style={{ height: "2rem", width: "90%" }}>
              <WishlinkLinearProgress
                percentComplete={percentComplete}
                barColor="#F2F2F2"
                filledBarColor="#E79549"
                style={{
                  height: "0.5rem",
                  borderRadius: "7.5rem",
                  boxShadow: `inset 0 0.12rem 0.24rem rgba(0, 0, 0, 0.16)`,
                  marginTop: "1rem",
                }}
              />
              <div
                style={{
                  background: "#17336F",
                  width: "1.5rem",
                  fontSize: "0.75rem",
                  position: "absolute",
                  top: "-0.3rem",
                  left: "25%",
                  borderRadius: "0.25rem",
                }}
              >
                {slab1_num_referrals_required}
              </div>
              <div
                style={{
                  background: "#17336F",
                  width: "1.5rem",
                  fontSize: "0.75rem",
                  position: "absolute",
                  top: "-0.3rem",
                  left: "62.5%",
                  borderRadius: "0.25rem",
                }}
              >
                {slab2_num_referrals_required}
              </div>
              <div
                style={{
                  background: "#17336F",
                  width: "1.5rem",
                  fontSize: "0.75rem",
                  position: "absolute",
                  top: "-0.3rem",
                  left: "98%",
                  borderRadius: "0.25rem",
                }}
              >
                {slab3_num_referrals_required}
              </div>
              <div
                style={{
                  background: "#E79549",
                  width: "3rem",
                  fontSize: "0.75rem",
                  position: "absolute",
                  top: "1rem",
                  left: "22%",
                  borderRadius: "0.25rem",
                }}
              >
                ₹ {slab1_referral_amount}
              </div>
              <div
                style={{
                  background: "#E79549",
                  width: "3rem",
                  fontSize: "0.75rem",
                  position: "absolute",
                  top: "1rem",
                  left: "59%",
                  borderRadius: "0.25rem",
                }}
              >
                ₹ {slab2_referral_amount}
              </div>
              <div
                style={{
                  background: "#E79549",
                  width: "3rem",
                  fontSize: "0.75rem",
                  position: "absolute",
                  top: "1rem",
                  left: "94%",
                  borderRadius: "0.25rem",
                }}
              >
                ₹ {slab3_referral_amount}
              </div>
            </div>
          </div>
        </>
      )}
      <Divider sx={{ borderStyle: "dashed", borderColor: colors.grey[0] }} />
      {getRewardCategoryHeadingAndSubheading("Terms and conditions", "", "")}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "left",
          alignItems: "flex-start",
          gap: "0.6rem",
          marginTop: "1rem",
        }}
      >
        {TnCArray?.map((tnc) => (
          <TnCTypography>{tnc}</TnCTypography>
        ))}{" "}
      </Box>
      <Divider
        sx={{
          borderStyle: "dashed",
          borderColor: colors.grey[0],
          marginTop: "1rem",
        }}
      />
      <Poweredby variant="orange" />
    </div>
  );
};
