import { useLocation, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Hamburger from "../../components/Hamburger";
import Statistics from "../../components/Home/Statistics";
import RewardsSummary from "../../components/RewardsSummary";
import {
  useFetchCreatorData,
  useFetchCreatorHighlightsData,
  useUpdateCreatorStoryDetails,
} from "../../apiLayer/Home/index";
import { useEffect, useState } from "react";
import WishlinkStories from "../../WishlinkComponents/WishlinkStories";
import StoryIconList from "../../components/StoryIconList";
import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import { triggerEvent, triggerLoginEvent } from "../../utils/honasa-events";
import { Divider } from "@mui/material";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: creatorData, isLoading: creatorDataIsLoading } =
    useFetchCreatorData({ enabled: true });
  const { data: creatorHighlightsData } = useFetchCreatorHighlightsData({
    enabled: true,
  });
  const highlight = creatorHighlightsData?.highlight;
  const highlightsRead = [];
  const highlightsUnread = [];
  highlight?.map((story) => {
    story.mark_as_read
      ? highlightsRead.push(story)
      : highlightsUnread.push(story);
    return story;
  });

  const [isOpen, setIsOpen] = useState(false);
  const [modalStyles, setModalStyles] = useState({
    display: "none",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });
  const [highlights, setHighlights] = useState([]);
  const [storyX, setStoryX] = useState(0);
  const [storyY, setStoryY] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  let stories = [];

  highlights?.map((item, idx) => {
    stories.push({
      type: "video",
      url: item.mediaUrl,
      duration: idx === 0 ? 13000 : 10000,
      storyItemId: item.storyItemId,
    });
    return item;
  });

  const classNames = {
    main: "story-main",
  };

  const { mutate } = useUpdateCreatorStoryDetails({
    onSuccess: {},
  });

  useEffect(() => {
    if (
      localStorage.getItem("fireLoginEvent") === "true" &&
      creatorData?.data?.id
    ) {
      triggerLoginEvent({
        userUniqueId: creatorData?.data?.id,
        userName: creatorData?.data?.name,
        userContactNumber: localStorage.getItem("phoneNumber"),
        userEmailId: creatorData?.data?.email,
      });
      localStorage.removeItem("fireLoginEvent");
    }
  }, [creatorData]);

  useEffect(() => {
    if (location.hash === "#education") {
      // openModal({ clientX: 0, clientY: 0 });
    } else {
      if (isOpen) {
        closeModal();
      }
    }
  }, [location]);

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "home_pl",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "page_load",
          product_name: "",
          product_price: "",
          element: "",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "rewards_banner_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "rewards_banner",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "referral_banner_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "referral_banner",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "reseller_stats_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "reseller_stats",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  const openModal = (event) => {
    const { clientX, clientY } = event;
    setStoryX(clientX);
    setStoryY(clientY);
    setModalStyles((prevStyles) => ({
      ...prevStyles,
      display: "block",
      left: `${clientX}px`,
      top: `${clientY}px`,
      width: 0,
      height: 0,
      position: "fixed",
    }));
    setIsOpen(true);

    setTimeout(() => {
      setModalStyles((prevStyles) => ({
        ...prevStyles,
        display: "block",
        width: "inherit",
        height: "100vh",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        position: "fixed",
      }));
    }, 100);
  };

  const closeModal = () => {
    setModalStyles({
      ...modalStyles,
      left: `${storyX}px`,
      top: `${storyY}px`,
      width: 0,
      height: 0,
    });

    setTimeout(() => setIsOpen(false), 200);
  };

  const onStoriesStart = () => {
    const body = {
      storyItemId: stories?.[0]?.storyItemId,
    };
    mutate(body);
  };

  const onAllStoriesEnd = () => {
    setCurrentIndex(0);
    navigate(-1, { replace: true });
    closeModal();
    for (let i = 0; i < stories.length; i++) {
      mutate({
        storyItemId: stories[i].storyItemId,
      });
    }
    // dispatch(updateMarkAsRead(currentStoryOpen));
  };

  const onClickStoryIcon = (e, story) => {
    navigate("#education");
    openModal(e);
    setHighlights(story?.highlight_items);
    triggerEvent({
      eventName: "story_highlight_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Home",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: "",
        product_price: "",
        element: "story_highlight",
        gender: creatorData?.data?.gender,
      },
    });
  };

  const onClickCloseStory = () => {
    navigate(-1, { replace: true });
    closeModal();
    setIsOpen(false);
  };

  return (
    <div style={{ width: "inherit", minHeight: "100vh" }}>
      <Hamburger
        creatorData={creatorData}
        creatorDataIsLoading={creatorDataIsLoading}
      />
      <Header
        creatorDataIsLoading={creatorDataIsLoading}
        creatorName={creatorData?.data?.name}
        creatorImage={creatorData?.data?.profile_pic_url}
        creatorData={creatorData}
        icon={"hamburger_icon_home"}
      />
      <StoryIconList
        stories={stories}
        onClickStoryIcon={onClickStoryIcon}
        highlightsRead={highlightsRead}
        highlightsUnread={highlightsUnread}
        creatorData={creatorData}
      />
      <Divider
        sx={{
          marginBottom: "0.75rem",
          marginLeft: "1rem",
          marginRight: "1rem",
        }}
      />
      <Statistics creatorData={creatorData} />
      <RewardsSummary creatorData={creatorData} />
      <img
        src={`${IMAGE_PREFIX}honasa-referral-banner.jpeg`}
        alt="referral-banner"
        className="w-100 pt-3"
        style={{ marginBottom: "7rem" }}
        onClick={() => {
          navigate(PAGE_URLS.REFERRALS);
          triggerEvent({
            eventName: "referral_banner_click",
            eventAttributes: {
              page_url: window.location.href,
              page_name: "Home",
              user_id: creatorData?.data?.id,
              timestamp: new Date().toISOString(),
              event_type: "click",
              product_name: "",
              product_price: "",
              element: "referral_banner",
              gender: creatorData?.data?.gender,
            },
          });
        }}
      />
      {isOpen && (
        <div
          key={isOpen ? "open" : "closed"}
          className="modal display-block"
          style={modalStyles}
        >
          <WishlinkStories
            width="100%"
            height="100vh"
            stories={stories}
            currentIndex={currentIndex}
            onStoryChange={() => {}}
            onStoriesStart={() => onStoriesStart()}
            onAllStoriesEnd={() => onAllStoriesEnd()}
            classNames={classNames}
          />
        </div>
      )}
      {isOpen && (
        <div
          className="flex justify-center align-center"
          style={{
            color: "#fff",
            zIndex: 1002,
            margin: "1rem",
            position: "fixed",
            top: 0,
            right: 0,
          }}
          onClick={() => onClickCloseStory()}
        >
          <img src={`${IMAGE_PREFIX}close.png`} alt="" />
        </div>
      )}
    </div>
  );
};

export default Home;
