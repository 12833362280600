import { useNavigate } from "react-router-dom";

import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { triggerEvent } from "../../utils/honasa-events";

const OrderHistoryHeader = (props) => {
  const { creatorData } = props;
  const navigate = useNavigate();

  let current = new Date();
  current.setMonth(current.getMonth() - 1);

  current.setMonth(current.getMonth() - 1);

  return (
    <>
      <div
        className="flex align-center justify-start p-3 pl-4 pr-4 sticky"
        id="analytics-header"
        style={{
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.12)",
          top: 0,
          background: "#fff",
          zIndex: 1000,
        }}
      >
        <img
          src={`${IMAGE_PREFIX}arrow-left.svg`}
          alt="arrow-left"
          onClick={() => {
            navigate(-1);
            triggerEvent({
              eventName: "back_cta_click",
              eventAttributes: {
                page_url: window.location.href,
                page_name: "Order-History",
                user_id: creatorData?.data?.id,
                timestamp: new Date().toISOString(),
                event_type: "click",
                product_name: "",
                product_price: "",
                element: "back_button_click",
                gender: creatorData?.data?.gender,
                added_on: creatorData?.data?.added_on,
              },
            });
          }}
        />
        <WishlinkTypography fontWeight={500} fontSize="1rem" className="ml-5">
          Order History
        </WishlinkTypography>
      </div>
    </>
  );
};

export default OrderHistoryHeader;
