import { Divider } from "@mui/material";

import WishlinkAccordion from "../../WishlinkComponents/WishlinkAccordion";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkPagination from "../../WishlinkComponents/WishlinkPagination";

import { IMAGE_PREFIX } from "../../utils/constants";
import { getFormattedDate } from "../../utils/common";
import ValueSkeleton from "../../WishlinkComponents/ValueSkeleton";
import Spinner from "../../WishlinkComponents/Spinner";
import OrderEntry from "./OrderEntry";

const OrderLedgerItem = (props) => {
  const {
    order,
    idx,
    showReturnedOrders,
    handleChange,
    expanded,
    entryPage,
    orderHistoryForDate,
    orderHistoryForDateLoading,
    onChangePageNo,
  } = props;

  const { orders_list, successful_orders, returned_orders, num_pages } =
    orderHistoryForDate?.data || {};

  return (
    <WishlinkAccordion
      key={idx}
      onChange={handleChange(idx, order)}
      expanded={expanded === idx}
      isExpandedOnLoad={false}
      expandIcon={<img src={`${IMAGE_PREFIX}down-arrow.svg`} alt="" />}
      title={
        <div className="flex align-center justify-between w-100 pr-2">
          <div>
            <WishlinkTypography
              color={"#333333"}
              fontSize="1rem"
              fontWeight={500}
              className="text-left"
            >
              {getFormattedDate(order?.sale_date)}
            </WishlinkTypography>
            <WishlinkTypography
              color={"#666666"}
              fontSize="0.75rem"
              fontWeight={500}
              className="text-left"
            >
              Net sales amount: {order.is_sales_negative && "-"} ₹{" "}
              {order?.total_sales}
            </WishlinkTypography>
          </div>
          <WishlinkTypography
            color={order.is_commissions_negative ? "#E91E63" : "#333333"}
            fontSize="1.25rem"
            fontWeight={700}
            className="ml-1"
          >
            {order.is_commissions_negative && "-"} ₹ {order?.total_commissions}
          </WishlinkTypography>
        </div>
      }
    >
      <div
        className="p-4"
        style={{
          background: "#F1F4F9",
          fontSize: "0.75rem",
          fontWeight: 400,
          lineHeight: "1.125rem",
          textAlign: "left",
        }}
      >
        <div className="flex mb-3">
          {!showReturnedOrders && (
            <>
              <div className="flex align-center mr-3 pr-3">
                <img src={`${IMAGE_PREFIX}successfull-orders.svg`} alt="" />
                <WishlinkTypography
                  color={"#666666"}
                  fontSize="0.75rem"
                  fontWeight={500}
                  className="ml-2 mr-1"
                >
                  Orders Placed:
                </WishlinkTypography>
                <WishlinkTypography
                  color={"#333333"}
                  fontSize="0.75rem"
                  fontWeight={500}
                >
                  <ValueSkeleton
                    isLoading={orderHistoryForDateLoading && entryPage === 1}
                    value={successful_orders}
                    width="1.5rem"
                  />
                </WishlinkTypography>
              </div>
            </>
          )}
          <div className="flex align-center">
            <img src={`${IMAGE_PREFIX}orders-returned.svg`} alt="" />
            <WishlinkTypography
              color={"#666666"}
              fontSize="0.75rem"
              fontWeight={500}
              className="ml-2 mr-1"
            >
              Cancelled:
            </WishlinkTypography>
            <WishlinkTypography
              color={"#333333"}
              fontSize="0.75rem"
              fontWeight={500}
            >
              <ValueSkeleton
                isLoading={orderHistoryForDateLoading && entryPage === 1}
                value={returned_orders}
                width="1.5rem"
              />
            </WishlinkTypography>
          </div>
        </div>
        <Divider />
        {orderHistoryForDateLoading ? (
          <div className="flex justify-center mt-3">
            <Spinner />
          </div>
        ) : (
          orders_list?.map((item, idx) => {
            return <OrderEntry key={idx} item={item} />;
          })
        )}
        <div className="mt-5">
          <WishlinkPagination
            count={num_pages}
            size="small"
            onChange={(e, page) =>
              onChangePageNo(e, page, order, successful_orders, returned_orders)
            }
          />
        </div>
      </div>
    </WishlinkAccordion>
  );
};

export default OrderLedgerItem;
