import { useQuery } from "@tanstack/react-query";
import { makeAPICall } from "../../utils/common";
import { BASE_URL } from "../../utils/constants";

export const useFetchResellerReferralEarningsData = () => {
  return useQuery({
    queryKey: ["ResellerReferralEarnings"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerReferralEarnings`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
  });
};

export const useFetchResellerReferralConfig = () => {
  return useQuery({
    queryKey: ["ResellerReferralConfig"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerReferralConfig`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
  });
};

export const useFetchResellerReferrerDetails = ({
  enabled = false,
  referee,
}) => {
  return useQuery({
    queryKey: ["ResellerReferrerDetails"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/getResellerRefererDetails?referee=${referee}`,
        method: "GET",
        headers: {},
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
