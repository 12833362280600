import { useMutation, useQuery } from "@tanstack/react-query";
import { makeAPICall } from "../../utils/common";
import { BASE_URL } from "../../utils/constants";

export const useFetchCreatorAddressData = ({ enabled = true }) => {
  return useQuery({
    queryKey: ["creatorAddress"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/fetchResellerAddress`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

export const useFetchCreatorAddressByIdData = ({
  addressId,
  enabled = true,
}) => {
  return useQuery({
    queryKey: ["creatorAddressId"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/fetchResellerAddressById?address_id=${addressId}`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

export const useFetchCreatorBankDetailsData = ({ enabled = true }) => {
  return useQuery({
    queryKey: ["bankDetails"],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}r/fetchResellerBankDetails`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

export const useUpdateCreatorProfileData = ({ onSuccess, headers }) => {
  return useMutation({
    mutationFn: (body) => {
      return makeAPICall({
        url: `${BASE_URL}r/updateResellerProfile`,
        method: "POST",
        body,
        headers,
      });
    },
    onSuccess,
  });
};

export const useUpdateCreatorAddressData = ({ onSuccess }) => {
  return useMutation({
    mutationFn: (body) => {
      return makeAPICall({
        url: `${BASE_URL}r/updateResellerAddress`,
        method: "PATCH",
        body,
      });
    },
    onSuccess,
  });
};

export const useUpdateCreateCreatorAddressData = ({ onSuccess }) => {
  return useMutation({
    mutationFn: (body) => {
      return makeAPICall({
        url: `${BASE_URL}r/resellerNewAddress`,
        method: "POST",
        body,
      });
    },
    onSuccess,
  });
};

export const useUpdateSubmitCreatorBankDetailsData = ({ onSuccess }) => {
  return useMutation({
    mutationFn: (body) => {
      return makeAPICall({
        url: `${BASE_URL}r/submitResellerBankDetails`,
        method: "POST",
        body,
      });
    },
    onSuccess,
  });
};

export const useFetchCheckTaskStatusData = ({ celeryTaskId, enabled }) => {
  return useQuery({
    queryKey: ["taskStatus", celeryTaskId],
    queryFn: () =>
      makeAPICall({
        url: `${BASE_URL}c/checkTaskStatus?task_id=${celeryTaskId}`,
        method: "GET",
      }),
    retry: true,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
