import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useFetchCreatorData } from "../../apiLayer/Home";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { Banner, ProductsContainer } from "./Products.styles";
import WishlinkProductCard from "../../WishlinkComponents/WishlinkProductCard/WishlinkProductCard";
import { useFetchResellerProductsData } from "../../apiLayer/Analytics";
import Spinner from "../../WishlinkComponents/Spinner";
import { showToast } from "../../features/Notification";
import { useDispatch } from "react-redux";
import {
  copyToClipboard,
  copyToClipboardAndOpenShareDrawer,
} from "../../utils/common";
import { IMAGE_PREFIX } from "../../utils/constants";
import { triggerEvent, triggerShareEvent } from "../../utils/honasa-events";

const ProductsListing = () => {
  const { data: creatorData, isLoading: creatorDataIsLoading } =
    useFetchCreatorData({ enabled: true });
  const { name = "", profile_pic_url = "" } = creatorData?.data || {};
  const { data: productsData, isLoading: productsDataIsLoading } =
    useFetchResellerProductsData({ enabled: true });

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "products_pl",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Products",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "page_load",
          product_name: "",
          product_price: "",
          element: "",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "product_page_banner_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Products",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "product_page_banner",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "products_list_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Products",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "products_list",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  const dispatch = useDispatch();

  const handleProductCardClick = ({ productLink, product, openInNewTab }) => {
    copyToClipboard(productLink);
    dispatch(showToast({ variant: "success", message: "Product Link Copied" }));
    triggerEvent({
      eventName: "products_list_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Products",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: product.name,
        product_price: product.price,
        element: "products_list",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    window.open(productLink, "_blank");
  };

  const handleLinkCopyClick = ({ productLink, product, event }) => {
    copyToClipboardAndOpenShareDrawer(productLink);
    dispatch(showToast({ variant: "success", message: "Product Link Copied" }));
    triggerEvent({
      eventName: "products_list_click",
      eventAttributes: {
        page_url: window.location.href,
        page_name: "Products",
        user_id: creatorData?.data?.id,
        timestamp: new Date().toISOString(),
        event_type: "click",
        product_name: product.name,
        product_price: product.price,
        element: "products_list",
        gender: creatorData?.data?.gender,
        added_on: creatorData?.data?.added_on,
      },
    });
    triggerShareEvent({
      url: productLink,
    });
    event.stopPropagation();
  };

  return (
    <div style={{ minHeight: window.innerHeight }}>
      <Header
        creatorDataIsLoading={creatorDataIsLoading}
        creatorName={name}
        creatorImage={profile_pic_url}
        showBackButton={true}
        showHamburgerIcon={false}
      />
      <Banner src={`${IMAGE_PREFIX}honasa-top-products.jpeg`} />
      <div className="pl-4 pb-4">
        <WishlinkTypography
          fontWeight={600}
          fontSize="1rem"
          lineHight="2rem"
          className="text-left"
        >
          Pure Origin Products
        </WishlinkTypography>
        <WishlinkTypography
          fontWeight={300}
          fontSize="0.675rem"
          lineHight="1rem"
          className="text-left"
        >
          Copy & share these Wishlinks to start earnings
        </WishlinkTypography>
      </div>
      <div className="flex flex-column align-center p-4 pt-0">
        <div className="flex justify-center w-100">
          {productsDataIsLoading && <Spinner />}
        </div>
        <ProductsContainer className="mb-4">
          {productsData?.data?.map((product) => (
            <WishlinkProductCard
              onClickProduct={handleProductCardClick}
              onClickCopyLinkButton={handleLinkCopyClick}
              key={product.id}
              product={product}
            />
          ))}
        </ProductsContainer>
      </div>
    </div>
  );
};

export default ProductsListing;
