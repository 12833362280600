import React from "react";
import { Skeleton } from "@mui/material";

export const ImageSkeleton = React.memo(function ImageSkeleton(props) {
  const {
    className,
    isLoading,
    imageUrl,
    style,
    alt = "",
    variant = "circular",
  } = props;

  return (
    <>
      {isLoading ? (
        <Skeleton sx={style} className={className} variant={variant} />
      ) : (
        <img alt={alt} style={style} src={imageUrl} className={className} />
      )}
    </>
  );
});

export default ImageSkeleton;
