import CONFIG from "./config";
import { auth } from "./firebase";

export const PAGE_URLS = {
  HOME: "/home",
  ABOUT: "/about",
  DEFAULT: "/",
  WELCOME: "/welcome",
  PROFILE: "/profile",
  ADDRESS: "/address/:id",
  BANKDETAILS: "/bank-details",
  LOGIN: "/login",
  ANALYTICSHOME: "/analytics-home",
  REWARDS: "/rewards",
  ORDERHISTORY: "/order-history",
  SIGNUPFORM: "/signup-form",
  PRODUCTS: "/products",
  PAYOUTS: "/payouts",
  REFERRALS: "/referrals",
};

export const BASE_URL = CONFIG.API.url;

export const LOGIN_DRAWER_STAGES = {
  ENTER_NUMBER: "ENTER_NUMBER",
  ENTER_OTP: "ENTER_OTP",
};

export const IMAGE_PREFIX =
  "https://d3g01po1nkka75.cloudfront.net/creator-master/";

export const getOpacityForDisabledValue = (disabled) => {
  return disabled ? "0.4" : "1";
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DATE_PRESETS = {
  THIS_MONTH: "This Month",
  LAST_2_MONTHS: "Last 2 Months",
  LAST_3_MONTHS: "Last 3 Months",
  ALL_TIME: "All Time",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  LAST_7_DAYS: "Last 7 days",
  LAST_MONTH: "Last month",
  LAST_TO_LAST_MONTH: MONTHS[new Date().getMonth() - 2],
};

export const hamburgerItems = [
  {
    title: "Profile",
    description: "Build your Wishlink profile",
    redirectUrl: "/profile",
    showNewIcon: false,
  },
  {
    title: "Refer & Win",
    description: "Win upto ₹10,000 on each referral",
    redirectUrl: "/referrals",
    showNewIcon: true,
    showAfterFirstSale: true,
  },
  {
    title: "Payout",
    description: "Manage your Wishlink earnings",
    redirectUrl: "/payouts",
    showNewIcon: false,
  },
  {
    title: "Help & Support",
    description: "Get your doubts resolved",
    redirectUrl: "/faq",
    showNewIcon: false,
  },
];

export const copyToClipboard = async (textToCopy) => {
  await window.customCopy(textToCopy, navigator);
};

export const getResponsiveWidth = (widthFactor = 100) => {
  return window.innerWidth > 448
    ? `${(28 * widthFactor) / 100}rem`
    : window.innerWidth < 360
      ? `${(22.5 * widthFactor) / 100}rem`
      : `${(window.innerWidth * widthFactor) / 100 / 16}rem`;
};

export const signOutFirebaseAuth = async () => {
  localStorage.clear();
  await auth.signOut();
};

export function getCroppedImg(image, crop) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * 4;
  canvas.height = crop.height * 4;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * 4,
    crop.height * 4,
  );
  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        blob.name = "croppedImage";
        resolve(blob);
      },
      "image/jpeg",
      1,
    );
  });
}

export const cover_pic_crop = {
  height: 28.88888888889,
  unit: "px",
  width: 100,
  x: 50,
  y: 50,
};

export const profile_pic_crop = {
  height: 100,
  unit: "px",
  width: 100,
  x: 50,
  y: 50,
};

export const gender_values = [
  {
    id: 1,
    label: "Male",
    value: "MALE",
  },
  {
    id: 2,
    label: "Female",
    value: "FEMALE",
  },
  {
    id: 3,
    label: "Prefer not to say",
    value: "OTHERS",
  },
];

export const CONTACT_WISHLINK_NUMBER = "916377092692";

export const IMAGE_FILE_EXTENSIONS = [".jpg", ".jpeg", ".png", ".webp"];

export const IMAGE_PREFIX_SOURCING_ASSETS =
  "https://d3g01po1nkka75.cloudfront.net/sourcing_assets/";

export const LINK_REGEX =
  /^(https?:\/\/)?((([a-z\d]([a-z\d\-*]*[a-z\d])*)\.)+[a-z]{2,}|(\d{1,3}\.){3}\d{1,3})(:\d+)?(\/[-a-z\d%_.~+&*=#]*)*(\?[;&a-z\d%_.~+=\-&*=#]*)?(#[-a-z\d_&*=]*)?$/i;

export const NON_PRODUCT_LINK_IDENTIFIERS = [
  "instagram.com/",
  "youtube.com/",
  "youtu.be/",
  "telegram.me/",
  "linktr.ee/",
];

export const EMAIL_REGEX =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-z0-9-]*[a-zA-Z0-9])?.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

export const CITY_REGEX = /[`!@#$%^&*_+\-=[\]{};':"\\|,.<>/?~]/;

export const USERNAME_REGEX = /^[a-zA-Z' -]+$/;

export const PHONE_REGEX = /^\d{10}$/;

export const PINCODE_REGEX = /^\d{6}$/;

export const PERFORMANCE_METRIC_TYPES = {
  REWARDS: "rewards",
  PAYOUT: "payout",
};

export const PRO_TIP_TYPES = {
  BRANDS: "brands",
  DIY: "diy",
  LEADERBOARD: "leaderboard",
  REWARDS: "rewards",
};

export const CAMPAIGN_REWARDS_STATES = [
  "no-levels",
  "one-levels",
  "two-levels",
  "all-levels",
];

export const CAMPAIGN_PROGRESS_BAR_STATES = [
  "campaign-state-1.png",
  "campaign-state-2.png",
  "campaign-state-3.png",
];

export const REWARDS_CAMPAIGN_COMPLETION_STATUS = {
  ALL_LEVELS_COMPLETED: "all-levels",
  TWO_LEVELS_COMPLETED: "two-levels",
  ONE_LEVELS_COMPLETED: "one-levels",
  NO_LEVELS_COMPLETED: "no-levels",
};

export const REWARDS_CAMPAIGN_STATUSES = {
  ACTIVE: "Active",
  ACHIEVED: "Achieved",
  NOT_ACHIEVED: "Not Achieved",
  LOCKED: "Locked",
};

export const REWARDS_EXPLAINER_CARDS = {
  YOUR_TERMS: {
    imageUrl: "reward-helper-lock-main.svg",
    text: "You have 2 milestones for earnings in each week's tab",
  },
  LINK_PRODUCTS: {
    imageUrl: "reward-rupee.svg",
    text: "Click on each milestones to know corresponding reward AMOUNT!",
  },
};

export const REWARDS_EXPLAINER_CARDS_SALES = {
  LINK_PRODUCTS: {
    imageUrl: "reward-rupee.svg",
    text: "Unlock exclusive rewards with each sales milestone!",
  },
  YOUR_TERMS: {
    imageUrl: "reward-helper-lock-main.svg",
    text: "Check milestones and their valid durations in reward cards below",
  },
};
export const rewardParentStatuses = {
  PAST: "past",
  CURRENT: "current",
  UPCOMING: "upcoming",
};

export const SLAB_BACKGROUNDS = ["#D6FFDC", "#E4D6FF", "#FFE1C5"];
export const REWARDS_ACHIEVING_METRICS = "";

export const REWARDS_DETAILS_TYPES = {
  LEADERBOARD: "leaderboard",
  WEEKLY_DETAILS: "weekly-details",
};

export const STOREFRONT_HOST_NAME = "https://pureorigin.wishlink.com/refer/";

export const invitedCreatorCount =
  20 +
  5 *
    Math.floor((new Date().getTime() - 1695197731582) / (1000 * 60 * 60 * 24));

export const REFERRAL_NUDGES = [
  {
    icon_url: `${IMAGE_PREFIX}ref-topbrandsv2.png`,
    title:
      "Help your besties work with top brands like Myntra, Meesho, H&M & more",
  },
  {
    icon_url: `${IMAGE_PREFIX}timeflies.png`,
    title:
      "Simplify their life by automating replies to comments asking for links",
  },
  {
    icon_url: `${IMAGE_PREFIX}ref-earningsboost.png`,
    title: "Boost their earnings and celebrate together!",
  },
  {
    icon_url: `${IMAGE_PREFIX}ref-high-five.png`,
    title: `${invitedCreatorCount}+ creators have already invited their besties to Wishlink`,
  },
];

export const LEARN_HOW_DOCUMENT = "https://help.instagram.com/2358103564437429";
