import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { contactWishlinkOnWhatsapp } from "../../utils/common";
import { IMAGE_PREFIX, LEARN_HOW_DOCUMENT } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const ErrorPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { errorPage } = props;
  const [errorCode, setErrorCode] = useState(null);
  const [loginType, setLoginType] = useState("");
  const [errorCopies, setErrorCopies] = useState({});

  const CONTACT_SUPPORT = "Contact Support";

  useEffect(() => {
    setErrorCode(location.state?.errorCode);
    setLoginType(location.state?.loginType);
  }, []);

  function onClickGoback() {
    navigate(-1, { replace: true });
  }

  useEffect(() => {
    setErrorCopies(getErrorCopies());
    try {
      window.fbq("trackCustom", "WCSoialLoginFail", {
        loginType: loginType,
        errorCode: errorCode,
      });
    } catch {
      fetch("https://api.wishlink.com/api/frontEndLogger", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          log: "Wishlink Creator Social Login Error Fb Pixel Failed",
        }),
      })
        .then()
        .catch();
    }
  }, [errorCode, loginType]);

  const getErrorCopies = () => {
    if (errorPage) {
      return {
        title: `Sorry, something seems like an invalid path`,
        subTitle: `Please check the link in address bar and try again`,
        ctaText: `Go back`,
      };
    }
    return {
      title: `Sorry, something failed while trying to login`,
      subTitle: `Please try again in some time`,
      ctaText: `Login again`,
      footerText: CONTACT_SUPPORT,
      showImage: false,
      whatsappMessageText:
        "Hey there, I need help logging in to Wishlink Creator",
    };
  };

  const onClickContactSupport = () => {
    if (errorCopies.footerText === "Learn How")
      window.open(LEARN_HOW_DOCUMENT, "_blank");
    else
      window.open(
        contactWishlinkOnWhatsapp(errorCopies?.whatsappMessageText),
        "_blank",
      );
  };

  return (
    <div
      style={{
        minHeight: window.innerHeight,
        background: "#e38125",
      }}
      className="relative"
    >
      <img
        src={`${IMAGE_PREFIX}coin.svg`}
        className="absolute"
        style={{ top: "1.875rem", right: 0 }}
        alt=""
      />
      <img
        src={`${IMAGE_PREFIX}dollar-coin.svg`}
        className="absolute"
        style={{ bottom: "8rem", left: "-2rem" }}
        alt=""
      />
      <img
        src={`${IMAGE_PREFIX}error-graphic-sorry.png`}
        className="absolute w-100"
        style={{ top: "20%", left: "50%", transform: "translateX(-50%)" }}
        alt=""
      />
      <div className="absolute w-100" style={{ top: "50%" }}>
        <WishlinkTypography
          className="pb-5 text-center w-100"
          fontSize="1rem"
          color={"#fff"}
          fontWeight={700}
        >
          {errorCopies?.title}
        </WishlinkTypography>
        {errorCopies?.subTitle ? (
          <WishlinkTypography
            className="w-100"
            fontSize="1.25rem"
            fontWeight={800}
            color={"#fff"}
          >
            {errorCopies?.subTitle}
          </WishlinkTypography>
        ) : (
          <></>
        )}
        {errorCopies?.showImage ? (
          <img src={errorCopies.img_url} style={{ width: "90%" }} alt="" />
        ) : (
          <></>
        )}
      </div>
      <div className="absolute w-100" style={{ bottom: "15%" }}>
        <WishlinkButton
          borderRadius="2.5rem"
          height="2.5rem"
          background={"#fff"}
          style={{
            ":hover": {
              bgcolor: "#fff",
            },
            width: "80%",
          }}
          buttonClassName="p-4"
          onClick={() => onClickGoback()}
        >
          <WishlinkTypography
            fontWeight={600}
            fontSize="0.875rem"
            color={"#e79548"}
            className="text-left"
          >
            {errorCopies?.ctaText}
          </WishlinkTypography>
        </WishlinkButton>
        {errorCopies?.footerText ? (
          <div className="flex justify-center align-center pt-5">
            <img src={`${IMAGE_PREFIX}learn-how.svg`} alt="" />
            <WishlinkTypography
              fontWeight={600}
              fontSize="0.875rem"
              color={"#fff"}
              className="text-center pl-3"
              onClick={() => onClickContactSupport()}
            >
              {errorCopies?.footerText}
            </WishlinkTypography>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
