import { useMutation } from "@tanstack/react-query";
import { firebaseUserStateChange, makeAPICall } from "../../utils/common";
import { BASE_URL } from "../../utils/constants";

export const postFirebaseLogin = async (body) => {
  const response = await fetch(`${BASE_URL}r/handleFirebaseLogin`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  if (response.status === 403) firebaseUserStateChange();
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const useUpdateCreateResellerData = ({ onSuccess }) => {
  return useMutation({
    mutationFn: (body) => {
      return makeAPICall({
        url: `${BASE_URL}r/createReseller`,
        method: "POST",
        body,
      });
    },
    retry: true,
    onSuccess,
  });
};
