import ImageSkeleton from "../../WishlinkComponents/ImageSkeleton";
import { useFetchCreatorData } from "../../apiLayer/Home/index";

const AboutSkeletons = () => {
  const { data: creatorData, isLoading: creatorDataIsLoading } =
    useFetchCreatorData({ enabled: false });
  const { profile_pic_url = "", cover_pic_url = "" } = creatorData?.data || {};

  return (
    <>
      <ImageSkeleton
        isLoading={creatorDataIsLoading}
        imageUrl={cover_pic_url}
        style={{ width: "100%", height: "8.125rem" }}
        variant="rectangular"
        alt=""
      />
      <ImageSkeleton
        isLoading={creatorDataIsLoading}
        imageUrl={profile_pic_url}
        style={{
          width: "7.5rem",
          height: "7.5rem",
          borderRadius: "50%",
          border: `0.125rem solid "#ffffff`,
          boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.25)`,
          position: "absolute",
          top: "40%",
          left: "3%",
        }}
        alt=""
      />
    </>
  );
};

export default AboutSkeletons;
