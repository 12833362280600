import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

const OtpVerificationButton = (props) => {
  const {
    getOpacityForDisabledValue,
    proceedButtonDisabled,
    handleButtonClick,
    confirmingOtp,
  } = props;

  return (
    <>
      <div className="w-100">
        <WishlinkButton
          background={"#E91E63"}
          opacity={getOpacityForDisabledValue(proceedButtonDisabled)}
          disabled={proceedButtonDisabled}
          buttonClassName="w-100"
          className="mt-4 pl-4 pr-4 mb-4"
          height="2.5rem"
          style={{
            borderRadius: "2.5rem",
            ":hover": {
              bgcolor: "#E91E63",
            },
          }}
          onClick={handleButtonClick}
        >
          <div className="flex p-2">
            <WishlinkTypography
              fontWeight={600}
              fontSize="1rem"
              lineHeight="1.5rem"
              color={"#fff"}
              className="ml-3 mr-3"
            >
              {confirmingOtp ? "Verifying..." : "Verify OTP"}
            </WishlinkTypography>
            {!confirmingOtp && (
              <img src={`${IMAGE_PREFIX}arrow-right.svg`} alt="lock" />
            )}
          </div>
        </WishlinkButton>
      </div>
    </>
  );
};

export default OtpVerificationButton;
