export const fadeIn = () => {
  let elementsArray = document.querySelectorAll(".tile-animation");
  for (var i = 0; i < elementsArray.length; i++) {
    var elem = elementsArray[i];
    var distInView = elem.getBoundingClientRect().top - window.innerHeight + 20;
    if (distInView < 0) {
      elem.classList.add("inView");
    } else {
      elem.classList.remove("inView");
    }
  }
};

export const bubble = () => {
  let elementsArray = document.querySelectorAll(".bubble-animation");
  for (var i = 0; i < elementsArray.length; i++) {
    var elem = elementsArray[i];
    var distInView =
      elem.getBoundingClientRect().top - window.innerHeight + 100;
    if (distInView < 0) {
      elem.classList.add("bubble");
    } else {
      elem.classList.remove("bubble");
    }
  }
};
