import { useNavigate } from "react-router-dom";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { useFetchResellerAnalyticsProductsData } from "../../apiLayer/Analytics";
import { useFetchCreatorData } from "../../apiLayer/Home";
import AnalyticsCardContainer from "../../components/Analytics/AnalyticsCardContainer";
import AnalyticsChart from "../../components/Analytics/AnalyticsChart";
import AnalyticsProductsList from "../../components/Analytics/AnalyticsProductsList";
import PerformanceMetricCardContainer from "../../components/Analytics/PerformanceMetricCardContainer";
import Hamburger from "../../components/Hamburger";
import Header from "../../components/Header";
import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import { useEffect } from "react";
import { triggerEvent } from "../../utils/honasa-events";

const AnalyticsHome = () => {
  const today = new Date();
  const formattedEndDate = today.toLocaleDateString("en-CA");
  const navigate = useNavigate();
  const { data: creatorData, isLoading: creatorDataIsLoading } =
    useFetchCreatorData({ enabled: true });
  const { data: productsData, isLoading: productsDataIsLoading } =
    useFetchResellerAnalyticsProductsData({
      startDate: "2024-02-01",
      endDate: formattedEndDate,
      page: 1,
      enabled: true,
    });

  useEffect(() => {
    if (creatorData?.data?.id) {
      triggerEvent({
        eventName: "analytics_pl",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Analytics-Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "page_load",
          product_name: "",
          product_price: "0",
          element: "",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "sales_commission_header_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Analytics-Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "sales_commissions_header",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "analytics_chart_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Analytics-Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "analytics_chart",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "sales_commission_header_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Analytics-Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "analytics_products_list",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
      triggerEvent({
        eventName: "analytics_earnings_overview_impression",
        eventAttributes: {
          page_url: window.location.href,
          page_name: "Analytics-Home",
          user_id: creatorData?.data?.id,
          timestamp: new Date().toISOString(),
          event_type: "impression",
          product_name: "",
          product_price: "",
          element: "analytics_earnings_overview",
          gender: creatorData?.data?.gender,
          added_on: creatorData?.data?.added_on,
        },
      });
    }
  }, [creatorData]);

  return (
    <>
      <Header
        creatorDataIsLoading={creatorDataIsLoading}
        creatorName={creatorData?.data?.name}
        creatorImage={creatorData?.data?.profile_pic_url}
        icon={"hamburger_icon_analytics"}
      />
      <Hamburger
        creatorData={creatorData}
        creatorDataIsLoading={creatorDataIsLoading}
      />
      <AnalyticsCardContainer showRedirectIcon={true} />
      <AnalyticsChart creatorData={creatorData} />
      <div className="p-4">
        <WishlinkButton
          background={"#E91E63"}
          className="mt-5 mb-2"
          height="3rem"
          width="100%"
          borderRadius="0.5rem"
          style={{
            ":hover": {
              bgcolor: "#E91E63",
            },
          }}
          onClick={() => {
            navigate(PAGE_URLS.ORDERHISTORY);
            triggerEvent({
              eventName: "detail_order_history_btn_click",
              eventAttributes: {
                page_url: window.location.href,
                page_name: "Analytics-Home",
                user_id: creatorData?.data?.id,
                timestamp: new Date().toISOString(),
                event_type: "click",
                product_name: "",
                product_price: "",
                element: "detail_order_history_button",
                gender: creatorData?.data?.gender,
              },
            });
          }}
        >
          <WishlinkTypography color="#fff">
            Detailed Order History
          </WishlinkTypography>
        </WishlinkButton>
      </div>
      <div className="p-4">
        <WishlinkTypography className="text-left" fontWeight={600}>
          Pure Origin Products
        </WishlinkTypography>
        <AnalyticsProductsList
          list={productsData?.data || []}
          isLoading={productsDataIsLoading}
          isListLoading={false}
          onClickElement={(text) => {}}
          emptyStateExists={false}
          buttonOnClick={() => {}}
        />
      </div>
      <div
        style={{
          background: "#c2c2c2",
          borderTop: `0.5rem solid #F1F4F9`,
        }}
        className="flex flex-column p-4 overflow-hidden mt-4"
      >
        <div className="flex align-end justify-between relative">
          <WishlinkTypography
            fontWeight={500}
            fontSize="1rem"
            lineHeight="1.5rem"
            color={"fff"}
            className="mb-4 mt-2"
          >
            Earnings overview
          </WishlinkTypography>
          <img
            src={IMAGE_PREFIX + "performance-graphic.svg"}
            alt="graphic"
            className="absolute"
            style={{
              top: 0,
              right: 0,
              marginTop: "-3rem",
              marginRight: "-3rem",
              zIndex: 0,
            }}
          />
        </div>
        <PerformanceMetricCardContainer creatorData={creatorData} />
      </div>
    </>
  );
};

export default AnalyticsHome;
