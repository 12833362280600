import { useEffect, useState } from "react";
import { SwipeableDrawer } from "@mui/material";

import { getResponsiveWidth } from "../../utils/constants";

const WishlinkDrawer = (props) => {
  const {
    background,
    borderRadius = "1rem 1rem 0rem 0rem",
    boxShadow,
    drawerOpen,
    border,
    className,
    onClose,
    onOpen = () => {},
    showGrabber = true,
    margin = "auto",
    direction = "up",
    anchor = "bottom",
    widthFactor = 100,
    paperBackground = "#FFFFFF",
  } = props;

  const [responsiveWidth, setResponsiveWidth] = useState(getResponsiveWidth);

  useEffect(() => {
    setResponsiveWidth(getResponsiveWidth(widthFactor));
  }, []);

  return (
    <SwipeableDrawer
      SlideProps={{ direction }}
      PaperProps={{
        style: {
          margin,
          width: responsiveWidth,
          borderRadius,
          background,
          boxShadow,
          border,
        },
      }}
      sx={{
        "& .MuiDrawer-paper": { backgroundColor: paperBackground },
      }}
      anchor={anchor}
      open={drawerOpen}
      className={className}
      onClose={onClose}
      onOpen={onOpen}
      disableSwipeToOpen={true}
    >
      {showGrabber && (
        <div
          style={{
            background: "#E5E5E5",
            width: "2.5rem",
            position: "relative",
            top: "0.5rem",
            left: "50%",
            transform: "translate(-50%)",
          }}
          className="pt-1 border-radius"
        ></div>
      )}
      {props.children}
    </SwipeableDrawer>
  );
};

export default WishlinkDrawer;
